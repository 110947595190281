import { Button, Grid, Link, TableCell, TableRow, TextField, Typography } from '@mui/material';
import { getCustomers } from 'api';
import { Page, Table } from 'components';
import { usePersistentListParameters } from 'context';
import { CustomerDto, CustomersParameters } from 'dtos';
import { useEffect, useState } from 'react';
import { useNavigate, Link as ReactRouterLink } from 'react-router-dom';
import { useDebounce } from 'utils';

export default function Customers() {
  const [customers, setCustomers] = useState<CustomerDto[]>([]);
  const [isGettingCustomers, setIsGettingCustomers] = useState<boolean>(false);
  const [totalCustomers, setTotalCustomers] = useState<number>(0);
  const navigate = useNavigate();
  const persistentListParameters = usePersistentListParameters();

  const onFilter = useDebounce((parameters: CustomersParameters) => {
    setIsGettingCustomers(true);
    getCustomers(parameters)
      .then(({ totalCount, value }) => {
        setTotalCustomers(totalCount!);
        setCustomers(value);
      })
      .finally(() => {
        persistentListParameters.setState('customers', parameters);
        setIsGettingCustomers(false);
      });
  }, 100);

  useEffect(() => {
    onFilter(persistentListParameters.state['customers']);
  }, [
    onFilter,
    persistentListParameters.state['customers'].page,
    persistentListParameters.state['customers'].pageSize,
    persistentListParameters.state['customers'].sortBy,
    persistentListParameters.state['customers'].sortDirection
  ]);

  const onReset = () => {
    onFilter(persistentListParameters.initialState['customers']);
  };

  const onSortChange = (propertyName: string, sortDirection: 'asc' | 'desc') => {
    persistentListParameters.setState('customers', {
      ...persistentListParameters.state['customers'],
      sortBy: propertyName,
      sortDirection
    });
  };

  const onChangePage = (page: number) => {
    persistentListParameters.setState('customers', {
      ...persistentListParameters.state['customers'],
      page
    });
  };

  const onChangePageSize = (pageSize: number) => {
    persistentListParameters.setState('customers', {
      ...persistentListParameters.state['customers'],
      pageSize
    });
  };

  return (
    <Page title='Customers' isLoading={isGettingCustomers}>
      <Table
        actions={
          <Grid container justifyContent='space-between'>
            <Grid item xs={12} sm={6} container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label='Filter text'
                  onChange={e => {
                    persistentListParameters.setState('customers', {
                      ...persistentListParameters.state['customers'],
                      filterText: e.target.value
                    });
                  }}
                  size='small'
                  value={persistentListParameters.state['customers'].filterText}
                />
              </Grid>

              <Grid item>
                <Button
                  color='inherit'
                  onClick={() => {
                    onFilter(persistentListParameters.state['customers']);
                  }}
                  variant='contained'
                >
                  FILTER
                </Button>
              </Grid>

              <Grid item>
                <Button color='inherit' onClick={onReset} variant='contained'>
                  RESET
                </Button>
              </Grid>
            </Grid>

            <Grid item xs={12} sm='auto' container justifyContent='flex-end'>
              <Grid item>
                <Button
                  color='inherit'
                  onClick={() => {
                    navigate('/customers/new');
                  }}
                  variant='contained'
                >
                  ADD NEW
                </Button>
              </Grid>
            </Grid>
          </Grid>
        }
        columns={[
          { label: 'Company Name', propertyName: 'name' },
          { label: 'Company #', propertyName: 'companyNumber' },
          { label: 'Address', propertyName: 'addresses', canSort: false }
        ]}
        onChangePage={onChangePage}
        onChangePageSize={onChangePageSize}
        onSortChange={onSortChange}
        page={persistentListParameters.state['customers'].page!}
        pageSize={persistentListParameters.state['customers'].pageSize!}
        sortBy={persistentListParameters.state['customers'].sortBy}
        sortDirection={persistentListParameters.state['customers'].sortDirection}
        totalCount={totalCustomers}
      >
        {customers.map(customer => (
          <TableRow key={customer.id}>
            <TableCell>
              <Link component={ReactRouterLink} sx={{ textDecoration: 'none' }} to={'/customers/' + customer.id}>
                {customer.companyName}
              </Link>
            </TableCell>

            <TableCell>{customer.companyNumber}</TableCell>

            <TableCell>
              {customer.customerAddresses.map(customerAddress => (
                <Typography key={customerAddress.id}>
                  {customerAddress.address1}, {customerAddress.address2 ? customerAddress.address2 + ',' : ''}{' '}
                  {customerAddress.city}, {customerAddress.stateProvince} {customerAddress.postalCode} {customerAddress.country}
                </Typography>
              ))}
            </TableCell>
          </TableRow>
        ))}
      </Table>
    </Page>
  );
}
