import * as yup from 'yup';

export interface JobContractDto {
  id?: number;
  contractNumber: string;
}

export const jobContractInit: JobContractDto = {
  id: undefined,
  contractNumber: ''
};

export const jobContractValidationSchema = yup.object({
  contractNumber: yup
    .string()
    .max(200, 'Contract # cannot be more than 200 characters.')
    .required('Contract # is required.')
});
