import { Menu } from '@mui/icons-material';
import {
  AppBar as MuiAppBar,
  Grid,
  Hidden,
  IconButton,
  Slide,
  Toolbar,
  Tooltip,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import theme, { MCE_GREY } from './theme';
import { SignOutButton } from './SignOutButton';

const AppBar = styled(MuiAppBar)({
  height: '64px',
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: MCE_GREY
});

const Logo = styled('img')({
  display: 'block'
});

interface Props {
  onToggleMenu: () => void;
  trigger: boolean;
}

const Header: React.FC<Props> = ({ onToggleMenu, trigger }) => {
  return (
    <Slide appear={false} direction='down' in={!trigger}>
      <AppBar position='fixed'>
        <Toolbar>
          <Grid container alignItems='center' justifyContent='space-between'>
            <Grid item>
              <Grid container alignItems='center'>
                <Grid item>
                  <Tooltip title='Toggle menu'>
                    <IconButton
                      edge='start'
                      aria-label='Toggle menu'
                      onClick={onToggleMenu}
                      color='inherit'
                      size='large'
                    >
                      <Menu fontSize='large' sx={{ color: '#fff' }} />
                    </IconButton>
                  </Tooltip>
                </Grid>

                <Hidden mdDown>
                  <Grid item>
                    <Logo
                      src={process.env.PUBLIC_URL + '/logo.png'}
                      alt='Motor City Electric'
                      width='50'
                    />
                  </Grid>

                  <Grid item>
                    <Typography variant='h5'>Field Report</Typography>
                  </Grid>
                </Hidden>
              </Grid>
            </Grid>

            <Grid item>
              <Grid
                container
                spacing={2}
                alignItems='center'
                justifyContent='space-between'
              >
                <Grid item>
                  <SignOutButton />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Slide>
  );
};

export default Header;
