import {
  CustomersParameters,
  FormStatuses,
  FormsParameters,
  InvoicesParameters,
  JobsParameters,
  customersParametersInit,
  formsParametersInit,
  invoicesParametersInit,
  jobsParametersInit
} from 'dtos';
import { createContext, useContext, useEffect, useState } from 'react';
import { useAuth } from './AuthContext';

export const PersistentListParametersContext = createContext<{
  initialState: State;
  isInitialState: (key: PersistentListParametersStateKeys) => boolean;
  setState: (
    key: PersistentListParametersStateKeys,
    value: CustomersParameters | FormsParameters | InvoicesParameters | JobsParameters
  ) => void;
  state: State;
} | null>(null);

interface State {
  allForms: FormsParameters;
  customers: CustomersParameters;
  huronValleyElectric: FormsParameters;
  invoiced: FormsParameters;
  invoices: InvoicesParameters;
  jobs: JobsParameters;
  midMichiganElectricalSolutions: FormsParameters;
  midSouthContractors: FormsParameters;
  motorCityElectric: FormsParameters;
  motorCityElectricService: FormsParameters;
  motorCityElectricTechnologies: FormsParameters;
  motorCityElectricUtilities: FormsParameters;
  myDrafts: FormsParameters;
  myForms: FormsParameters;
  pendingProcessing: FormsParameters;
  processed: FormsParameters;
  rotorElectric: FormsParameters;
  stelkoElectric: FormsParameters;
  williamsElectrical: FormsParameters;
}

export type PersistentListParametersStateKeys =
  | 'allForms'
  | 'customers'
  | 'huronValleyElectric'
  | 'invoiced'
  | 'invoices'
  | 'jobs'
  | 'midMichiganElectricalSolutions'
  | 'midSouthContractors'
  | 'motorCityElectric'
  | 'motorCityElectricService'
  | 'motorCityElectricTechnologies'
  | 'motorCityElectricUtilities'
  | 'myDrafts'
  | 'myForms'
  | 'pendingProcessing'
  | 'processed'
  | 'rotorElectric'
  | 'stelkoElectric'
  | 'williamsElectrical';

export default function PersistentListParametersProvider({ children }: React.PropsWithChildren) {
  const { USER_ID } = useAuth();

  const initialState: State = {
    myDrafts: { ...formsParametersInit, statusId: FormStatuses.Draft },
    myForms: { ...formsParametersInit, createdById: USER_ID },
    pendingProcessing: { ...formsParametersInit, statusId: FormStatuses.Submitted },
    processed: { ...formsParametersInit, statusId: FormStatuses.Processed },
    invoiced: { ...formsParametersInit, statusId: FormStatuses.Invoiced },
    allForms: { ...formsParametersInit },
    motorCityElectric: { ...formsParametersInit, companyId: '1' },
    motorCityElectricService: { ...formsParametersInit, companyId: '100' },
    motorCityElectricTechnologies: { ...formsParametersInit, companyId: '9' },
    motorCityElectricUtilities: { ...formsParametersInit, companyId: '5' },
    midMichiganElectricalSolutions: { ...formsParametersInit, companyId: '14' },
    midSouthContractors: { ...formsParametersInit, companyId: '8' },
    huronValleyElectric: { ...formsParametersInit, companyId: '10' },
    rotorElectric: { ...formsParametersInit, companyId: '12' },
    stelkoElectric: { ...formsParametersInit, companyId: '2' },
    williamsElectrical: { ...formsParametersInit, companyId: '60' },
    customers: { ...customersParametersInit },
    jobs: { ...jobsParametersInit },
    invoices: { ...invoicesParametersInit }
  };

  // TODO: This might be better off with useReducer.
  // https://react.dev/reference/react/useReducer
  const [state, _setState] = useState<State>(initialState);

  useEffect(() => {
    if (USER_ID) {
      _setState({
        ...state,
        myForms: {
          ...initialState.myForms,
          createdById: USER_ID
        }
      });
    }
  }, [USER_ID]);

  const setState = (
    key: PersistentListParametersStateKeys,
    value: CustomersParameters | FormsParameters | InvoicesParameters | JobsParameters
  ) => {
    _setState({
      ...state,
      [key]: value
    });
  };

  /**
   * Returns true if the state form 'key' is the same as initial state (except page, pageSize, sortBy, sortDirection, and companyId.)
   */
  const isInitialState = (key: PersistentListParametersStateKeys): boolean => {
    switch (key) {
      case 'allForms':
      case 'huronValleyElectric':
      case 'invoiced':
      case 'midMichiganElectricalSolutions':
      case 'midSouthContractors':
      case 'motorCityElectric':
      case 'motorCityElectricService':
      case 'motorCityElectricTechnologies':
      case 'motorCityElectricUtilities':
      case 'myDrafts':
      case 'myForms':
      case 'pendingProcessing':
      case 'processed':
      case 'rotorElectric':
      case 'stelkoElectric':
      case 'williamsElectrical':
        return (
          state[key].formNumber === initialState[key].formNumber &&
          state[key].jobNumber === initialState[key].jobNumber &&
          state[key].customer === initialState[key].customer &&
          state[key].updatedDateTimeUtc === initialState[key].updatedDateTimeUtc &&
          state[key].date === initialState[key].date &&
          state[key].projectManager === initialState[key].projectManager &&
          state[key].customerOrderNumber === initialState[key].customerOrderNumber &&
          state[key].poNumber === initialState[key].poNumber
        );
      case 'customers':
        return (
          state[key].filterText === initialState[key].filterText &&
          state[key].page === initialState[key].page &&
          state[key].pageSize === initialState[key].pageSize &&
          state[key].sortBy === initialState[key].sortBy &&
          state[key].sortDirection === initialState[key].sortDirection
        );
      case 'invoices':
        return (
          state[key].filterText === initialState[key].filterText &&
          state[key].page === initialState[key].page &&
          state[key].pageSize === initialState[key].pageSize &&
          state[key].sortBy === initialState[key].sortBy &&
          state[key].sortDirection === initialState[key].sortDirection
        );
      case 'jobs':
        return (
          state[key].filterText === initialState[key].filterText &&
          state[key].page === initialState[key].page &&
          state[key].pageSize === initialState[key].pageSize &&
          state[key].sortBy === initialState[key].sortBy &&
          state[key].sortDirection === initialState[key].sortDirection
        );
    }
  };

  return (
    <PersistentListParametersContext.Provider value={{ initialState, isInitialState, setState, state }}>
      {children}
    </PersistentListParametersContext.Provider>
  );
}

export const usePersistentListParameters = () => useContext(PersistentListParametersContext!)!;
