import * as yup from 'yup';
import { JobContractDetailDto, jobContractDetailInit, jobContractDetailValidationSchema } from './jobContractDetail';
import { JobEquipmentDetailDto, jobEquipmentDetailValidationSchema } from './jobEquipmentDetail';
import { JobLaborDetailDto, jobLaborDetailValidationSchema } from './jobLaborDetail';

export interface JobsParameters {
  filterText?: string;
  page?: number;
  pageSize?: number;
  sortBy?: string;
  sortDirection?: 'asc' | 'desc';
}

export const jobsParametersInit: JobsParameters = {
  filterText: '',
  page: 0,
  pageSize: 10,
  sortBy: 'jobNumber',
  sortDirection: 'desc'
};

export interface JobDto {
  id?: number;
  jobNumber: string;
  laborMarkupPercentage: number;
  laborOverheadProfitPercentage: number;
  materialMarkupPercentage: number;
  materialOverheadProfitPercentage: number;
  isTaxable: boolean;
  materialTaxPercentage?: number;
  equipmentMarkupPercentage: number;
  equipmentOverheadProfitPercentage: number;
  subcontractsMarkupPercentage: number;
  subcontractsOverheadProfitPercentage: number;
  jobContractDetail: JobContractDetailDto[];
  jobLaborDetail: JobLaborDetailDto[];
  jobEquipmentDetail: JobEquipmentDetailDto[];
}

export const jobInit: JobDto = {
  id: undefined,
  jobNumber: '',
  laborMarkupPercentage: 0.0,
  laborOverheadProfitPercentage: 0.0,
  materialMarkupPercentage: 0.0,
  materialOverheadProfitPercentage: 0.0,
  isTaxable: false,
  materialTaxPercentage: 0.0,
  equipmentMarkupPercentage: 0.0,
  equipmentOverheadProfitPercentage: 0.0,
  subcontractsMarkupPercentage: 0.0,
  subcontractsOverheadProfitPercentage: 0.0,
  jobContractDetail: [jobContractDetailInit],
  jobLaborDetail: [],
  jobEquipmentDetail: []
};

export const jobValidationSchema = yup.object<JobDto>({
  jobNumber: yup.string().min(6, 'Job # must be 6 digits.').max(6, 'Job # must be 6 digits.').required('Job # is required.'),
  laborMarkupPercentage: yup
    .number()
    .min(0.0, 'Labor Markup must be between 0.00% and 100.00%.')
    .max(100.0, 'Labor Markup must be between 0.00% and 100.00%.')
    .required('Labor Markup is required.'),
  laborOverheadProfitPercentage: yup
    .number()
    .min(0.0, 'Labor Overhead & Profit must be between 0.00% and 100.00%.')
    .max(100.0, 'Labor Overhead & Profit must be between 0.00% and 100.00%.')
    .required('Labor Overhead & Profit is required'),
  materialMarkupPercentage: yup
    .number()
    .min(0.0, 'Material Markup must be between 0.00% and 100.00%.')
    .max(100.0, 'Material Markup must be between 0.00% and 100.00%.')
    .required('Material Markup is required.'),
  materialOverheadProfitPercentage: yup
    .number()
    .min(0.0, 'Material Overhead & Profit must be between 0.00% and 100.00%.')
    .max(100.0, 'Material Overhead & Profit must be between 0.00% and 100.00%.')
    .required('Material Overhead & Profit is required.'),
  isTaxable: yup.bool(),
  materialTaxPercentage: yup
    .number()
    .min(0.0, 'Material Tax must be between 0.00% and 100.00%.')
    .max(100.0, 'Material Tax must be between 0.00% and 100.00%.')
    .nullable(),
  equipmentMarkupPercentage: yup
    .number()
    .min(0.0, 'Equipment Markup must be between 0.00% and 100.00%.')
    .max(100.0, 'Equipment Markup must be between 0.00% and 100.00%.')
    .required('Equipment Markup is required.'),
  equipmentOverheadProfitPercentage: yup
    .number()
    .min(0.0, 'Equipment Overhead & Profit must be between 0.00% and 100.00%.')
    .max(100.0, 'Equipment Overhead & Profit must be between 0.00% and 100.00%.')
    .required('Equipment Overhead & Profit is required.'),
  subcontractsMarkupPercentage: yup
    .number()
    .min(0.0, 'Subcontracts Markup must be between 0.00% and 100.00%.')
    .max(100.0, 'Subcontracts Markup must be between 0.00% and 100.00%.')
    .required('Subcontracts Markup is required.'),
  subcontractsOverheadProfitPercentage: yup
    .number()
    .min(0.0, 'Subcontracts Overhead & Profit must be between 0.00% and 100.00%.')
    .max(100.0, 'Subcontracts Overhead & Profit must be between 0.00% and 100.00%.')
    .required('Subcontracts Overhead & Profit is required.'),
  jobContractDetail: yup.array().of(jobContractDetailValidationSchema),
  jobLaborDetail: yup.array().of(jobLaborDetailValidationSchema),
  jobEquipmentDetail: yup.array().of(jobEquipmentDetailValidationSchema)
});
