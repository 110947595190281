import { api } from 'app/api';
import { ApiResponse, CustomerAddressDto, CustomerDto, CustomersParameters } from 'dtos';
import { URIQuery } from 'utils';

export const getCustomers = async (parameters: CustomersParameters): Promise<ApiResponse<CustomerDto[]>> =>
  await api.get<ApiResponse<CustomerDto[]>>('customers' + URIQuery({ ...parameters })).then(res => res.data);

export const getCustomerById = async (customerId: number): Promise<ApiResponse<CustomerDto>> =>
  await api.get<ApiResponse<CustomerDto>>('customers/' + encodeURIComponent(customerId)).then(res => res.data);

export const createCustomer = async (values: CustomerDto) =>
  await api.post<ApiResponse<CustomerDto>>('customers', values).then(res => res.data);

export const updateCustomer = async (values: CustomerDto) =>
  await api.put<ApiResponse<CustomerDto>>('customers', values).then(res => res.data);

export const createCustomerAddress = async (customerId: number, values: CustomerAddressDto) =>
  await api
    .post<ApiResponse<CustomerAddressDto>>(`customers/${encodeURIComponent(customerId)}/addresses`, values)
    .then(res => res.data);
