import * as yup from 'yup';

export interface JobEquipmentDetailDto {
  id?: number;
  description: string;
  um: string;
  rate: number;
}

export const jobEquipmentDetailInit: JobEquipmentDetailDto = {
  description: '',
  um: '',
  rate: 0.0
};

export const jobEquipmentDetailValidationSchema = yup.object({
  description: yup.string().max(200, 'Description cannot be more than 200 characters.').required('Description is required.'),
  um: yup.string().max(50, 'U/M cannot be more than 50 characters.').required('U/M is required.'),
  rate: yup
    .number()
    .min(0.0, 'Rate must be between $0.00 and $999,999,999,999.99.')
    .max(999_999_999_999.99, 'Rate must be between $0.00 and $999,999,999,999.99.')
    .required('Rate is required.')
});
