import * as yup from 'yup';

export interface FormsParameters {
  companyId?: string;
  createdById?: string;
  customer?: string;
  customerOrderNumber?: string;
  date?: string;
  foreman?: string;
  formNumber?: string;
  invoiceable?: boolean;
  invoiceId?: number;
  jobId?: number;
  jobNumber?: string;
  page?: number;
  pageSize?: number;
  poNumber?: string;
  projectManager?: string;
  sortBy?: string;
  sortDirection?: 'asc' | 'desc';
  statusId?: number;
  updatedDateTimeUtc?: string;
}

export const formsParametersInit: FormsParameters = {
  page: 0,
  pageSize: 10,
  sortBy: 'formNumber',
  sortDirection: 'desc'
};

export enum FormStatuses {
  Draft = 1,
  Submitted = 2,
  Processed = 3,
  Invoiced = 4
}

export interface FormAttachmentDto {
  id?: number;
  fileName?: string;
  uploadedById?: string;
  uploadedDateTimeUtc?: string;
  attachment?: File;
}

// #region FormLabor
export interface FormLaborDto {
  id?: number;
  name: string;
  truckNumber?: string;
  laborClassificationId: number;
  straightTime?: number | null;
  timeAndAHalf?: number | null;
  doubleTime?: number | null;
  shift: string;
  comments?: string;
}

export const formLaborInit: FormLaborDto = {
  id: undefined,
  name: '',
  truckNumber: undefined,
  laborClassificationId: -1,
  straightTime: undefined,
  timeAndAHalf: undefined,
  doubleTime: undefined,
  shift: '',
  comments: undefined
};

export const formLaborValidationSchema = yup.object<FormLaborDto>({
  name: yup.string().max(200, 'Name cannot be more than 200 characters.').required('Name is required.'),
  truckNumber: yup.string().max(50, 'Truck # cannot be more than 50 characters.').nullable(),
  laborClassificationId: yup.number().min(1, 'Class is required.').required('Class is required.'),
  straightTime: yup.lazy(() =>
    yup
      .number()
      .min(0, 'Straight Time cannot be less than 0.')
      .when(['timeAndAHalf', 'doubleTime'], {
        is: (timeAndAHalf: any, doubleTime: any) =>
          (timeAndAHalf === null || timeAndAHalf === undefined || timeAndAHalf === '') &&
          (doubleTime === null || doubleTime === undefined || doubleTime === ''),
        then: schema => schema.required('Please enter a Straight Time, Time ½, or Double Time.'),
        otherwise: schema => schema.nullable()
      })
  ),
  timeAndAHalf: yup.lazy(() =>
    yup
      .number()
      .min(0, 'Time ½ cannot be less than 0.')
      .when(['straightTime', 'doubleTime'], {
        is: (straightTime: any, doubleTime: any) =>
          (straightTime === null || straightTime === undefined || straightTime === '') &&
          (doubleTime === null || doubleTime === undefined || doubleTime === ''),
        then: schema => schema.required('Please enter a Straight Time, Time ½, or Double Time.'),
        otherwise: schema => schema.nullable()
      })
  ),
  doubleTime: yup.lazy(() =>
    yup
      .number()
      .min(0, 'Double Time cannot be less than 0.')
      .when(['straightTime', 'timeAndAHalf'], {
        is: (straightTime: any, timeAndAHalf: any) =>
          (straightTime === null || straightTime === undefined || straightTime === '') &&
          (timeAndAHalf === null || timeAndAHalf === undefined || timeAndAHalf === ''),
        then: schema => schema.required('Please enter a Straight Time, Time ½, or Double Time.'),
        otherwise: schema => schema.nullable()
      })
  ),
  shift: yup.string().required('Shift is required.'),
  comments: yup.string().max(200, 'Comments cannot be more than 200 characters.').nullable()
});
// #endregion

// #region FormEquipment
export interface FormEquipmentDto {
  id?: number;
  description: string;
  quantity?: number;
}

export const formEquipmentInit: FormEquipmentDto = {
  id: undefined,
  description: '',
  quantity: undefined
};

export const formEquipmentsInit: FormEquipmentDto[] = [
  { description: 'Service Truck', quantity: 0 },
  { description: "Manlift Scissors 25'", quantity: 0 },
  { description: 'Pick Up Truck', quantity: 0 },
  { description: 'Rolling Set Up', quantity: 0 },
  { description: "Manlift Boom 40'", quantity: 0 }
];

export const formEquipmentValidationSchema = yup.object<FormEquipmentDto>({
  description: yup.string().max(200, 'Description cannot be more than 200 characters.').required('Description is required.'),
  quantity: yup
    .number()
    .integer('Quantity must be an integer.')
    .min(0, 'Quantity cannot be less than 0.')
    .max(999_999, 'Quantity cannot be more than 999,999.')
    .required('Quantity is required.')
});
// #endregion

// #region FormMaterial
export interface FormMaterialDto {
  id?: number;
  description: string;
  quantity?: number;
  price?: number;
  per?: string;
  extension?: number;
}

export const formMaterialInit: FormMaterialDto = {
  id: undefined,
  description: '',
  quantity: undefined,
  price: undefined,
  per: '',
  extension: undefined
};

export const formMaterialValidationSchema = yup.object<FormMaterialDto>({
  description: yup.string().max(200, 'Description cannot be more than 200 characters.').nullable(),
  // .required('Description is required.'),
  quantity: yup
    .number()
    .integer('Quantity must be an integer.')
    .min(0, 'Quantity cannot be less than 0.')
    .max(999_999, 'Quantity cannot be more than 999,999.')
    .nullable(),
  // .required('Quantity is required.'),
  price: yup
    .number()
    .min(0, 'Price cannot be less than $0.00.')
    .max(999_999_999.99, 'Price cannot be more than $999,999,999.99.')
    .nullable(),
  per: yup.string().nullable() // TODO:: Why can't per be a number represented by labeled <Select />?
});
// #endregion

// #region FormSubcontract
export interface FormSubcontractDto {
  id?: number;
  poNumber: string;
  subcontractor: string;
  descriptionOfWork: string;
  invoiceNumber: string;
  totalCost?: number;
}

export const formSubcontractInit: FormSubcontractDto = {
  id: undefined,
  poNumber: '',
  subcontractor: '',
  descriptionOfWork: '',
  invoiceNumber: '',
  totalCost: undefined
};

export const formSubcontractValidationSchema = yup.object<FormSubcontractDto>({
  poNumber: yup.string().max(200, 'PO # cannot be more than 200 characters.').required('PO # is required.'),
  subcontractor: yup
    .string()
    .max(200, 'Subcontractor cannot be more than 200 characters.')
    .required('Subcontractor is required.'),
  descriptionOfWork: yup
    .string()
    .max(2_000, 'Description of Work cannot be more than 2,000 characters.')
    .required('Description of Work is required.'),
  invoiceNumber: yup.string().max(50, 'Invoice # cannot be more than 50 characters.').required('Invoice # is required.'),
  totalCost: yup
    .string()
    .min(0.0, 'Total Cost cannot be less than $0.00.')
    .max(999_999_999_999.99, 'Total Cost cannot be more than $999,999,999,999.99.')
    .required('Total Cost is required')
});
// #endregion

// #region FormMiscellaneous
export interface FormMiscellaneousDto {
  id?: number;
  description: string;
  quantity?: number;
  markupPercentage?: number;
  overheadProfitPercentage?: number;
  unitCost?: number;
  per: string;
  totalCost?: number;
}

export const formMiscellaneousInit: FormMiscellaneousDto = {
  id: undefined,
  description: '',
  quantity: undefined,
  markupPercentage: undefined,
  overheadProfitPercentage: undefined,
  unitCost: undefined,
  per: '',
  totalCost: undefined
};

export const formMiscellaneousValidationSchema = yup.object<FormMiscellaneousDto>({
  description: yup.string().max(200, 'Description cannot be more than 200 characters.').required('Description is required.'),
  quantity: yup
    .number()
    .min(0, 'Quantity cannot be less than 0.')
    .max(999_999_999, 'Quantity cannot be more than 999,999,999.')
    .integer('Quantity must be a whole number.')
    .required('Quantity is required.'),
  markupPercentage: yup
    .number()
    .min(0.0, 'MU % cannot be less than 0.00%.')
    .max(100.0, 'MU % cannot be more than 100.00%.')
    .required('MU % is required'),
  overheadProfitPercentage: yup
    .number()
    .min(0.0, 'O&P % cannot be less than 0.00%.')
    .max(100.0, 'O&P % cannot be more than 100.00%.')
    .required('O&P % is required.'),
  unitCost: yup
    .number()
    .min(0.0, 'Unit Cost cannot be less than $0.00.')
    .max(999_999_999.99, 'Unit Cost cannot be more than 999,999,999.99.')
    .required('Unit Cost is required.'),
  per: yup.string().oneOf(['M', 'C', 'E'], 'U/M must be "M", "C", or "E".').required('U/M is required.')
  // totalCost is calculated.
});
// #endregion

// #region Form
export interface FormDto {
  id?: number;
  formNumber?: string;
  formStatusId?: number;
  companyId: number;
  customer: string;
  date: string;
  jobId?: number;
  jobNumber: string;
  invoiceable?: boolean;
  locationOfWork: string;
  customerOrderNumber?: string;
  poNumber?: string;
  projectManagerId: string;
  workPerformed: string;
  invoiceNumber?: string;
  supervisorSignatureBase64?: string;
  customerSignatureBase64?: string;
  supervisorPrintName?: string;
  customerPrintName?: string;
  explanationForAbsenceOfCustomerSignature?: string;
  formAttachments: FormAttachmentDto[];
  formLabor: FormLaborDto[];
  formEquipment: FormEquipmentDto[];
  formMaterials: FormMaterialDto[];
  formSubcontracts: FormSubcontractDto[];
  formMiscellaneous: FormMiscellaneousDto[];
  createdById?: string;
  createdDateTimeUtc?: Date;
  updatedById?: string;
  updatedDateTimeUtc?: Date;
}

export const formInit: FormDto = {
  formNumber: undefined,
  formStatusId: FormStatuses.Draft,
  companyId: -1,
  customer: '',
  date: '',
  jobNumber: '',
  locationOfWork: '',
  customerOrderNumber: undefined,
  poNumber: undefined,
  projectManagerId: '',
  workPerformed: '',
  invoiceNumber: '',
  supervisorSignatureBase64: undefined,
  customerSignatureBase64: undefined,
  supervisorPrintName: undefined,
  customerPrintName: undefined,
  explanationForAbsenceOfCustomerSignature: undefined,
  formAttachments: [],
  formLabor: [formLaborInit],
  formEquipment: formEquipmentsInit,
  formMaterials: [formMaterialInit],
  formSubcontracts: [],
  formMiscellaneous: [],
  updatedDateTimeUtc: undefined
};

export const formValidationSchema = yup.object<FormDto>({
  companyId: yup.number().min(0, 'Company is required.').required('Company is required.'),
  customer: yup.string().max(200, 'Customer cannot be more than 200 characters.').required('Customer is required.'),
  date: yup.date().required('Date is required.'),
  jobNumber: yup
    .string()
    .matches(/^\d+$/, 'Job # cannot contain non-numeric characters.')
    .typeError('Job # must be 6 whole digits.')
    .min(6, ' Job # cannot be less than 6 digits.')
    .max(6, 'Job # cannot be more than 6 digits.')
    .required('Job # is required.'),
  locationOfWork: yup
    .string()
    .max(200, 'Location of Work cannot be more than 200 characters.')
    .required('Location of Work is required.'),
  customerOrderNumber: yup.string().max(50, 'Customer Order # cannot be more than 50 characters.').nullable(),
  poNumber: yup.string().max(50, 'PO # cannot be more than 50 characters.').nullable(),
  projectManagerId: yup.string().required('Project Manager is required.'),
  workPerformed: yup
    .string()
    .max(2_000, 'Work Performed cannot be more than 2,000 characters.')
    .required('Work Performed is required.'),
  invoiceNumber: yup.string().max(50, 'Invoice # cannot be more than 50 characters.').nullable(),
  supervisorSignatureBase64: yup.string().nullable(),
  customerSignatureBase64: yup.string().nullable(),
  supervisorPrintName: yup
    .string()
    .max(200, 'Supervisor Print Name cannot be more than 200 characters.')
    .required('Supervisor Print Name is required.'),
  // .nullable(),
  customerPrintName: yup
    .string()
    .max(200, 'Customer Print Name cannot be more than 200 characters.')
    // .nullable(),
    .required('Customer Print Name is required.'),
  explanationForAbsenceOfCustomerSignature: yup
    .string()
    .max(2_000, 'Explanation For Absence Of Customer Signature cannot be more than 2,000 characters.')
    .nullable(),
  formLabor: yup.array().of(formLaborValidationSchema),
  formEquipment: yup.array().of(formEquipmentValidationSchema),
  formMaterials: yup.array().of(formMaterialValidationSchema),
  formSubcontracts: yup.array().of(formSubcontractValidationSchema),
  formMiscellaneous: yup.array().of(formMiscellaneousValidationSchema)
  // formMiscellaneous
});

export const formDraftValidationSchema = yup.object<FormDto>({
  companyId: yup.number().min(0, 'Company is required.').required('Company is required.'),
  customer: yup.string().max(200, 'Customer cannot be more than 200 characters.').required('Customer is required.'),
  date: yup.date().required('Date is required.'),
  jobNumber: yup
    .string()
    .matches(/^\d+$/, 'Job # cannot contain non-numeric characters.')
    .typeError('Job # must be 6 whole digits.')
    .min(6, ' Job # cannot be less than 6 digits.')
    .max(6, 'Job # cannot be more than 6 digits.')
    .required('Job # is required.')
});
// #endregion
