export function formatDateTime(date: Date | string): string {
  return new Date(date.toString() + 'Z')
    .toLocaleString('en-US', {
      month: 'numeric',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    })
    .replace(',', '');
}
