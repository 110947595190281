import { LoadingButton } from '@mui/lab';
import { Autocomplete, Button, Divider, Grid, TextField } from '@mui/material';
import { ResponsiveDialog, ResponsiveDialogActions, ResponsiveDialogContent, ResponsiveDialogTitle } from 'components';
import { countries } from 'constants/countries';
import { stateProvinceAbbreviations } from 'constants/stateProvinces';
import { CustomerAddressDto, customerAddressInit, customerAddressValidationSchema } from 'dtos';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';

interface AddCustomerAddressDialogProps {
  initialValues: CustomerAddressDto;
  isLoading: boolean;
  onClose: () => void;
  onSave: (values: CustomerAddressDto) => void;
  open: boolean;
}

export default function AddCustomerAddressDialog({
  initialValues,
  isLoading,
  onClose,
  onSave,
  open
}: AddCustomerAddressDialogProps) {
  const [_initialValues, _setInitialValues] = useState<CustomerAddressDto>(customerAddressInit);

  useEffect(() => {
    // Parse address1.
    // This supports two variants for address1:
    // {address1}, {address2,?} {city}, {stateProvince}, {postalCode}, {country}
    // {address1}, {address2,?} {city}, {stateProvince} {postalCode} {country}
    if (initialValues.address1) {
      let address = customerAddressInit;
      let addressParts = initialValues.address1.split(',');

      console.log(addressParts);

      if (addressParts.length < 6) {
        if (addressParts.length >= 0) {
          address.address1 = addressParts[0] ?? initialValues.address1;
        }

        if (addressParts.length > 1 && addressParts.length < 5) {
          address.city = addressParts[1];
        }

        if (addressParts.length > 2) {
          address.stateProvince = addressParts[2];
        }

        if (addressParts.length > 3) {
          address.postalCode = addressParts[3];
        }

        if (addressParts.length > 4) {
          address.country = addressParts[4];
        }
      } else {
        address.address1 = addressParts[0];
        address.address2 = addressParts[1];
        address.city = addressParts[2];
        address.stateProvince = addressParts[3];
        address.postalCode = addressParts[4];
        address.country = addressParts[5];
      }

      _setInitialValues(address);
    }
  }, [initialValues.address1]);

  return (
    <ResponsiveDialog open={open}>
      <Formik
        enableReinitialize
        initialValues={_initialValues}
        onSubmit={(values, formikHelpers) => {
          onSave(values);
          formikHelpers.setSubmitting(false);
        }}
        validateOnBlur
        validateOnChange
        validationSchema={customerAddressValidationSchema}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, setFieldValue, setTouched, touched, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <ResponsiveDialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <ResponsiveDialogTitle>Add Customer Address</ResponsiveDialogTitle>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      disabled={isLoading || isSubmitting}
                      error={Boolean(touched.billingContact && errors.billingContact)}
                      fullWidth
                      helperText={touched.billingContact && errors.billingContact}
                      label='Billing Contact'
                      name='billingContact'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      size='small'
                      value={values.billingContact}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      disabled={isLoading || isSubmitting}
                      error={Boolean(touched.address1 && errors.address1)}
                      fullWidth
                      helperText={touched.address1 && errors.address1}
                      label='Address 1'
                      name='address1'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      size='small'
                      value={values.address1}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      disabled={isLoading || isSubmitting}
                      error={Boolean(touched.address2 && errors.address2)}
                      fullWidth
                      helperText={touched.address2 && errors.address2}
                      label='Address 2'
                      name='address2'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      size='small'
                      value={values.address2}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      disabled={isLoading || isSubmitting}
                      error={Boolean(touched.city && errors.city)}
                      fullWidth
                      helperText={touched.city && errors.city}
                      label='City'
                      name='city'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      size='small'
                      value={values.city || ''}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <Autocomplete
                      autoHighlight
                      autoSelect
                      disablePortal
                      // disableClearable
                      fullWidth
                      options={stateProvinceAbbreviations}
                      onBlur={() => {
                        setTouched({ ...touched, stateProvince: true });
                      }}
                      onChange={(_e, value) => {
                        setFieldValue('stateProvince', value);
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          error={Boolean(touched.stateProvince && errors.stateProvince)}
                          helperText={touched.stateProvince && errors.stateProvince}
                          InputLabelProps={{
                            shrink: true
                          }}
                          label='State/Province'
                        />
                      )}
                      size='small'
                      value={values.stateProvince || ''}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      disabled={isLoading || isSubmitting}
                      error={Boolean(touched.postalCode && errors.postalCode)}
                      fullWidth
                      helperText={touched.postalCode && errors.postalCode}
                      label='Postal Code'
                      name='postalCode'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      size='small'
                      value={values.postalCode}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Autocomplete
                      autoHighlight
                      autoSelect
                      disablePortal
                      // disableClearable
                      fullWidth
                      options={countries}
                      onBlur={() => {
                        setTouched({ ...touched, country: true });
                      }}
                      onChange={(_e, value) => {
                        setFieldValue('country', value);
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          error={Boolean(touched.country && errors.country)}
                          helperText={touched.country && errors.country}
                          label='Country'
                        />
                      )}
                      size='small'
                      value={values.country || ''}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Divider />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      disabled={isLoading || isSubmitting}
                      error={Boolean(touched.businessPhoneNumber && errors.businessPhoneNumber)}
                      fullWidth
                      helperText={touched.businessPhoneNumber && errors.businessPhoneNumber}
                      label='Business Phone Number'
                      name='businessPhoneNumber'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      size='small'
                      value={values.businessPhoneNumber}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      disabled={isLoading || isSubmitting}
                      error={Boolean(touched.businessFax && errors.businessFax)}
                      fullWidth
                      helperText={touched.businessFax && errors.businessFax}
                      label='Business Fax'
                      name='businessFax'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      size='small'
                      value={values.businessFax}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      disabled={isLoading || isSubmitting}
                      error={Boolean(touched.emailAddress && errors.emailAddress)}
                      fullWidth
                      helperText={touched.emailAddress && errors.emailAddress}
                      label='Email Address'
                      name='emailAddress'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      size='small'
                      value={values.emailAddress}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      disabled={isLoading || isSubmitting}
                      error={Boolean(touched.website && errors.website)}
                      fullWidth
                      helperText={touched.website && errors.website}
                      label='Website'
                      name='website'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      size='small'
                      value={values.website}
                    />
                  </Grid>
                </Grid>
              </ResponsiveDialogContent>

              <ResponsiveDialogActions>
                <Button color='error' disabled={isLoading || isSubmitting} onClick={onClose}>
                  CANCEL
                </Button>

                <LoadingButton color='primary' loading={isLoading || isSubmitting} type='submit'>
                  SAVE
                </LoadingButton>
              </ResponsiveDialogActions>
            </form>
          );
        }}
      </Formik>
    </ResponsiveDialog>
  );
}
