import { Button, Grid, TextField } from '@mui/material';
import {
  ResponsiveDialog,
  ResponsiveDialogActions,
  ResponsiveDialogContent,
  ResponsiveDialogTitle
} from 'components';
import { EquipmentDto } from 'dtos';
import { Formik } from 'formik';
import React from 'react';
import * as yup from 'yup';

const validationSchema = yup.object({
  name: yup
    .string()
    .max(200, 'Name cannot be more than 200 characters.')
    .required('Name is required.')
});

interface Props {
  equipment: EquipmentDto;
  onClose: () => void;
  onSave: (values: EquipmentDto) => void;
  open: boolean;
}

const AddEditEquipmentDialog = ({
  equipment,
  onClose,
  onSave,
  open
}: React.PropsWithChildren<Props>) => {
  return (
    <ResponsiveDialog open={open}>
      <Formik
        enableReinitialize
        initialValues={equipment}
        validateOnChange
        validateOnBlur
        validationSchema={validationSchema}
        onSubmit={values => {
          onSave(values);
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <ResponsiveDialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <ResponsiveDialogTitle>
                      {equipment.id ? 'Edit' : 'Add'} Equipment
                    </ResponsiveDialogTitle>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      autoFocus
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && errors.name}
                      label='Name'
                      name='name'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      size='small'
                      value={values.name}
                    />
                  </Grid>
                </Grid>
              </ResponsiveDialogContent>

              <ResponsiveDialogActions>
                <Button color='error' onClick={onClose}>
                  CANCEL
                </Button>

                <Button color='primary' type='submit'>
                  SAVE
                </Button>
              </ResponsiveDialogActions>
            </form>
          );
        }}
      </Formik>
    </ResponsiveDialog>
  );
};

export default AddEditEquipmentDialog;
