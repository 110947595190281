import React from 'react';
import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  Typography
} from '@mui/material';
import { ContentCopy, Forward } from '@mui/icons-material';

export interface FormCardProps {}

const FormCard = () => {
  return (
    <Card variant='outlined' sx={{ mt: 2 }}>
      <CardContent>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={12}>
            <Typography variant='body2'>Form #</Typography>

            <Typography variant='body2'>
              <Link
                sx={{
                  cursor: 'pointer',
                  textDecoration: 'none'
                }}
              >
                123456-1
              </Link>
            </Typography>
          </Grid>

          <Grid item xs={12} sm>
            <Typography variant='body2'>Customer</Typography>

            <Typography variant='body2'>Spud</Typography>
          </Grid>

          <Grid item xs={12} sm>
            <Typography variant='body2'>Last Modified</Typography>

            <Typography variant='body2'>1/23/2023 7:05 PM </Typography>
          </Grid>

          <Grid item xs={12} sm>
            <Typography variant='body2'>Job Date</Typography>

            <Typography variant='body2'>1/9/2023</Typography>
          </Grid>

          <Grid item xs={12} sm>
            <Typography variant='body2'>Status</Typography>

            <Typography variant='body2'>Draft</Typography>
          </Grid>
        </Grid>
      </CardContent>

      <CardActions>
        <Grid container spacing={4} justifyContent='flex-end' alignItems='center'>
          <Grid item>
            <FormControlLabel control={<Checkbox defaultChecked />} label='Export' />
          </Grid>

          <Grid item>
            <IconButton onClick={() => {}}>
              <ContentCopy />
            </IconButton>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};

export default FormCard;
