import { AccountInfo } from '@azure/msal-browser';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { publicClientApplication, scopes } from './App';

class ApiError {
  statusCode?: number = undefined;
  message?: string = undefined;

  constructor(error: any) {
    this.statusCode = error?.response?.data?.statusCode ?? error?.response?.status ?? undefined;
    this.message = error?.response?.data?.message ?? undefined;
  }
}

const baseURL = '/api/';

export const api = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json'
  }
});

api.interceptors.request.use(
  async config => {
    let accountInfo: AccountInfo | null = null;
    let tokenResponse = await publicClientApplication.handleRedirectPromise();

    if (tokenResponse) {
      accountInfo = tokenResponse.account;
    } else {
      accountInfo = publicClientApplication.getAllAccounts()[0];
    }

    if (accountInfo && tokenResponse) {
      // Intentionally empty
    } else if (accountInfo) {
      try {
        tokenResponse = await publicClientApplication.acquireTokenSilent({
          account: accountInfo ?? undefined,
          scopes
        });
      } catch {
        await publicClientApplication.acquireTokenRedirect({ scopes });
      }
    } else {
      await publicClientApplication.loginRedirect({ scopes });
    }

    config.headers.Authorization = `Bearer ${tokenResponse?.idToken /* accessToken */}`;
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  response => response,
  error => {
    const apiError = new ApiError(error);

    if (apiError.message) {
      enqueueSnackbar(apiError.message, { variant: 'error' });
    }

    if (apiError.statusCode === 401) {
      publicClientApplication.loginRedirect({ scopes });
    }

    throw apiError;
  }
);
