import { useAuth } from 'context';
import { Outlet, useNavigate } from 'react-router-dom';

interface ProtectedRouteProps {
  IS_ADMIN?: boolean;
  IS_INVOICE_ADMIN?: boolean;
  IS_USER?: boolean;
  redirect?: string;
}

export default function ProtectedRoute(props: ProtectedRouteProps) {
  const auth = useAuth();
  const navigate = useNavigate();

  if (
    (props.IS_ADMIN && !auth.IS_ADMIN) ||
    (props.IS_INVOICE_ADMIN && !auth.IS_INVOICE_ADMIN) ||
    (props.IS_USER && !auth.IS_USER)
  ) {
    navigate(-1);
  }

  return (props.IS_ADMIN && !auth.IS_ADMIN) ||
    (props.IS_INVOICE_ADMIN && !auth.IS_INVOICE_ADMIN) ||
    (props.IS_USER && !auth.IS_USER) ? (
    <></>
  ) : (
    <Outlet />
  );
}
