import { getCompany } from 'api/companies';
import { FormsTable, Page } from 'components';
import { PersistentListParametersStateKeys } from 'context';
import { CompanyDto } from 'dtos';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export default function Company() {
  const { companyId } = useParams();
  const [company, setCompany] = useState<CompanyDto>();

  const [persistentListParametersStateKey, setPersistentListParametersStateKey] = useState<
    | 'huronValleyElectric'
    | 'midMichiganElectricalSolutions'
    | 'midSouthContractors'
    | 'motorCityElectric'
    | 'motorCityElectricService'
    | 'motorCityElectricTechnologies'
    | 'motorCityElectricUtilities'
    | 'rotorElectric'
    | 'stelkoElectric'
    | 'williamsElectrical'
    | null
  >(null);

  useEffect(() => {
    if (companyId) {
      getCompany(companyId)
        .then(({ value }) => {
          setCompany(value);
        })
        .catch(e => {
          enqueueSnackbar(e?.data.response.message ?? 'An error occurred while getting forms.');
        });
    }

    return () => {};
  }, [companyId]);

  useEffect(() => {
    if (company && company.id) {
      switch (company.id) {
        case 1:
          setPersistentListParametersStateKey('motorCityElectric');
          break;
        case 100:
          setPersistentListParametersStateKey('motorCityElectricService');
          break;
        case 9:
          setPersistentListParametersStateKey('motorCityElectricTechnologies');
          break;
        case 5:
          setPersistentListParametersStateKey('motorCityElectricUtilities');
          break;
        case 8:
          setPersistentListParametersStateKey('midSouthContractors');
          break;
        case 10:
          setPersistentListParametersStateKey('huronValleyElectric');
          break;
        case 12:
          setPersistentListParametersStateKey('rotorElectric');
          break;
        case 2:
          setPersistentListParametersStateKey('stelkoElectric');
          break;
        case 60:
          setPersistentListParametersStateKey('williamsElectrical');
          break;
        case 14:
          setPersistentListParametersStateKey('midMichiganElectricalSolutions');
          break;
      }
    }
  }, [company]);

  return company && persistentListParametersStateKey ? (
    <FormsTable
      key={persistentListParametersStateKey}
      persistentListParametersStateKey={persistentListParametersStateKey}
      title={company.name!}
    />
  ) : (
    <Page title='Loading...' isLoading />
  );
}
