import { ContentCopy } from '@mui/icons-material';
import {
  Button,
  Checkbox,
  Chip,
  Grid,
  IconButton,
  Link,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
  Tooltip
} from '@mui/material';
import { getForms } from 'api';
import { Page, Table } from 'components';
import { useAuth, usePersistentListParameters } from 'context';
import { FormDto, FormStatuses, FormsParameters } from 'dtos';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { formatDate, formatDateTime, useDebounce } from 'utils';
import BatchExportDialog from './BatchExportDialog';

export type FormsTableProps = {
  invoiceable?: boolean;
  persistentListParametersStateKey:
    | 'allForms'
    | 'huronValleyElectric'
    | 'invoiced'
    | 'midMichiganElectricalSolutions'
    | 'midSouthContractors'
    | 'motorCityElectric'
    | 'motorCityElectricService'
    | 'motorCityElectricTechnologies'
    | 'motorCityElectricUtilities'
    | 'myDrafts'
    | 'myForms'
    | 'pendingProcessing'
    | 'processed'
    | 'rotorElectric'
    | 'stelkoElectric'
    | 'williamsElectrical';
  title: string;
};

export default function FormsTable({ invoiceable = false, persistentListParametersStateKey, title }: FormsTableProps) {
  const navigate = useNavigate();
  const { USER_ID } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const persistentListParameters = usePersistentListParameters();
  const [batchExportDialogOpen, setBatchExportDialogOpen] = useState<boolean>(false);
  const [forms, setForms] = useState<FormDto[]>([]);
  const [isGettingForms, setIsGettingForms] = useState<boolean>(true);
  const [exportForms, setExportForms] = useState<FormDto[]>([]);
  const [invoiceForms, setInvoiceForms] = useState<FormDto[]>([]);
  const [filterField, setFilterField] = useState<string>('');
  const [filterText, setFilterText] = useState<string>('');
  const [totalForms, setTotalForms] = useState<number>(0);

  useEffect(() => {
    if (USER_ID) {
      onFilter(persistentListParameters.state[persistentListParametersStateKey]);
    }
  }, [
    USER_ID,
    persistentListParameters.state[persistentListParametersStateKey].createdById,
    persistentListParameters.state[persistentListParametersStateKey].companyId,
    persistentListParameters.state[persistentListParametersStateKey].page,
    persistentListParameters.state[persistentListParametersStateKey].pageSize,
    persistentListParameters.state[persistentListParametersStateKey].sortBy,
    persistentListParameters.state[persistentListParametersStateKey].sortDirection,
    persistentListParameters.state[persistentListParametersStateKey].statusId
  ]);

  // key and value are passed here to prevent issues with state race conditions.
  const onFilter = useDebounce((parameters: FormsParameters, key?: string, value?: any) => {
    let _parameters = parameters;

    if (key) {
      _parameters = { ..._parameters, page: 0, [key]: value };
    }

    setIsGettingForms(true);
    getForms(_parameters)
      .then(({ totalCount, value }) => {
        setTotalForms(totalCount!);
        setForms(value);
      })
      .catch(e => {
        enqueueSnackbar(e?.data.response.message ?? 'An error occurred while getting forms.');
      })
      .finally(() => {
        persistentListParameters.setState(persistentListParametersStateKey, _parameters);
        setIsGettingForms(false);
        setExportForms([]);
      });
  }, 100);

  const onReset = () => {
    onFilter(persistentListParameters.initialState[persistentListParametersStateKey]);
    setFilterField('');
    setFilterText('');
  };

  const onSortChange = (propertyName: string, sortDirection: 'asc' | 'desc') => {
    persistentListParameters.setState(persistentListParametersStateKey, {
      ...persistentListParameters.state[persistentListParametersStateKey],
      sortBy: propertyName,
      sortDirection
    });
  };

  const onChangePage = (page: number) => {
    persistentListParameters.setState(persistentListParametersStateKey, {
      ...persistentListParameters.state[persistentListParametersStateKey],
      page
    });
  };

  const onChangePageSize = (pageSize: number) => {
    persistentListParameters.setState(persistentListParametersStateKey, {
      ...persistentListParameters.state[persistentListParametersStateKey],
      pageSize
    });
  };

  return (
    <Page title={title} isLoading={isGettingForms}>
      <BatchExportDialog open={batchExportDialogOpen} onClose={() => setBatchExportDialogOpen(false)} forms={exportForms} />

      <Table
        actions={
          <Grid container alignItems='center' justifyContent='space-between' sx={{}}>
            <Grid item xs={12} sm={9} container spacing={2} alignItems='center' justifyContent='flex-start'>
              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  label='Filter Field'
                  onChange={e => {
                    setFilterField(e.target.value);
                  }}
                  select
                  size='small'
                  value={filterField || ''}
                >
                  <MenuItem value=''></MenuItem>
                  <MenuItem value='formNumber'>Form #</MenuItem>
                  <MenuItem value='jobNumber'>Job #</MenuItem>
                  <MenuItem value='customer'>Customer</MenuItem>
                  <MenuItem value='updatedDateTimeUtc'>Modified Date</MenuItem>
                  <MenuItem value='date'>Job Date</MenuItem>
                  <MenuItem value='projectManager'>Project Manager</MenuItem>
                  <MenuItem value='customerOrderNumber'>Customer Order #</MenuItem>
                  <MenuItem value='poNumber'>PO #</MenuItem>
                  <MenuItem value='foreman'>Foreman</MenuItem>
                </TextField>
              </Grid>

              <Grid item xs={12} sm={3}>
                <Tooltip arrow title={!filterField ? 'Please select a Filter Field.' : ''}>
                  <TextField
                    disabled={!filterField}
                    fullWidth
                    InputLabelProps={{
                      shrink: filterField === 'date' || filterField === 'updatedDateTimeUtc' || undefined
                    }}
                    label='Filter Text'
                    onChange={e => {
                      setFilterText(e.target.value);
                    }}
                    size='small'
                    type={filterField === 'date' || filterField === 'updatedDateTimeUtc' ? 'date' : 'text'}
                    value={filterText || ''}
                  />
                </Tooltip>
              </Grid>

              <Grid item>
                <Tooltip arrow title={!filterField || !filterText ? 'Please select a Filter Field and enter Filter Text.' : ''}>
                  <span>
                    {/* BUtton is wrapped in span otherwise tooltip doesn't appear. */}
                    <Button
                      disabled={!filterField || !filterText}
                      variant='contained'
                      color='inherit'
                      onClick={() => {
                        onFilter(persistentListParameters.state[persistentListParametersStateKey], filterField, filterText);
                        setFilterField('');
                        setFilterText('');
                      }}
                    >
                      FILTER
                    </Button>
                  </span>
                </Tooltip>
              </Grid>

              <Grid item>
                <Button
                  disabled={persistentListParameters.isInitialState(persistentListParametersStateKey)}
                  variant='text'
                  color='error'
                  onClick={onReset}
                >
                  RESET FILTERS
                </Button>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={3} container spacing={2} alignItems='center' justifyContent='flex-end'>
              <Grid item>
                <Button
                  variant='contained'
                  color='inherit'
                  disabled={exportForms.length === 0}
                  onClick={() => setBatchExportDialogOpen(true)}
                >
                  EXPORT {exportForms.length > 0 && `(${exportForms.length})`}
                </Button>
              </Grid>

              {invoiceable && (
                <Grid item>
                  <Button
                    color='inherit'
                    disabled={invoiceForms.length === 0}
                    onClick={() => {
                      navigate('/invoicing/new', {
                        state: { invoiceForms, job: { id: invoiceForms[0].jobId, jobNumber: invoiceForms[0].jobNumber } }
                      });
                    }}
                    variant='contained'
                  >
                    INVOICE {invoiceForms.length > 0 && `(${invoiceForms.length})`}
                  </Button>
                </Grid>
              )}

              <Grid item>
                <Button variant='contained' color='inherit' onClick={() => navigate('/forms/new')}>
                  ADD NEW
                </Button>
              </Grid>
            </Grid>

            <Grid item container spacing={1} xs={12} sx={{ mt: 1 }}>
              {persistentListParameters.state[persistentListParametersStateKey].formNumber &&
                persistentListParameters.state[persistentListParametersStateKey].formNumber !==
                  persistentListParameters.initialState[persistentListParametersStateKey].formNumber && (
                  <Grid item>
                    <Chip
                      color='error'
                      variant='outlined'
                      label={`Form # contains "${persistentListParameters.state[persistentListParametersStateKey].formNumber}"`}
                      onDelete={() => {
                        onFilter(
                          persistentListParameters.state[persistentListParametersStateKey],
                          'formNumber',
                          persistentListParameters.initialState[persistentListParametersStateKey].formNumber
                        );
                      }}
                    />
                  </Grid>
                )}

              {persistentListParameters.state[persistentListParametersStateKey].jobNumber &&
                persistentListParameters.state[persistentListParametersStateKey].jobNumber !==
                  persistentListParameters.initialState[persistentListParametersStateKey].jobNumber && (
                  <Grid item>
                    <Chip
                      color='error'
                      variant='outlined'
                      label={`Job # contains "${persistentListParameters.state[persistentListParametersStateKey].jobNumber}"`}
                      onDelete={() => {
                        onFilter(
                          persistentListParameters.state[persistentListParametersStateKey],
                          'jobNumber',
                          persistentListParameters.initialState[persistentListParametersStateKey].jobNumber
                        );
                      }}
                    />
                  </Grid>
                )}

              {persistentListParameters.state[persistentListParametersStateKey].customer &&
                persistentListParameters.state[persistentListParametersStateKey].customer !==
                  persistentListParameters.initialState[persistentListParametersStateKey].customer && (
                  <Grid item>
                    <Chip
                      color='error'
                      variant='outlined'
                      label={`Customer contains "${persistentListParameters.state[persistentListParametersStateKey].customer}"`}
                      onDelete={() => {
                        onFilter(
                          persistentListParameters.state[persistentListParametersStateKey],
                          'customer',
                          persistentListParameters.initialState[persistentListParametersStateKey].customer
                        );
                      }}
                    />
                  </Grid>
                )}

              {persistentListParameters.state[persistentListParametersStateKey].updatedDateTimeUtc &&
                persistentListParameters.state[persistentListParametersStateKey].updatedDateTimeUtc !==
                  persistentListParameters.initialState[persistentListParametersStateKey].updatedDateTimeUtc && (
                  <Grid item>
                    <Chip
                      color='error'
                      variant='outlined'
                      label={`Modified Date = ${persistentListParameters.state[persistentListParametersStateKey].updatedDateTimeUtc}`}
                      onDelete={() => {
                        onFilter(
                          persistentListParameters.state[persistentListParametersStateKey],
                          'updatedDateTimeUtc',
                          persistentListParameters.initialState[persistentListParametersStateKey].updatedDateTimeUtc
                        );
                      }}
                    />
                  </Grid>
                )}

              {persistentListParameters.state[persistentListParametersStateKey].date &&
                persistentListParameters.state[persistentListParametersStateKey].date !==
                  persistentListParameters.initialState[persistentListParametersStateKey].date && (
                  <Grid item>
                    <Chip
                      color='error'
                      variant='outlined'
                      label={`Job Date = ${persistentListParameters.state[persistentListParametersStateKey].date}`}
                      onDelete={() => {
                        onFilter(
                          persistentListParameters.state[persistentListParametersStateKey],
                          'date',
                          persistentListParameters.initialState[persistentListParametersStateKey].date
                        );
                      }}
                    />
                  </Grid>
                )}

              {persistentListParameters.state[persistentListParametersStateKey].projectManager &&
                persistentListParameters.state[persistentListParametersStateKey].projectManager !==
                  persistentListParameters.initialState[persistentListParametersStateKey].projectManager && (
                  <Grid item>
                    <Chip
                      color='error'
                      variant='outlined'
                      label={`Project Manager contains "${persistentListParameters.state[persistentListParametersStateKey].projectManager}"`}
                      onDelete={() => {
                        onFilter(
                          persistentListParameters.state[persistentListParametersStateKey],
                          'projectManager',
                          persistentListParameters.initialState[persistentListParametersStateKey].projectManager
                        );
                      }}
                    />
                  </Grid>
                )}

              {persistentListParameters.state[persistentListParametersStateKey].customerOrderNumber &&
                persistentListParameters.state[persistentListParametersStateKey].customerOrderNumber !==
                  persistentListParameters.initialState[persistentListParametersStateKey].customerOrderNumber && (
                  <Grid item>
                    <Chip
                      color='error'
                      variant='outlined'
                      label={`Customer Order # contains "${persistentListParameters.state[persistentListParametersStateKey].customerOrderNumber}"`}
                      onDelete={() => {
                        onFilter(
                          persistentListParameters.state[persistentListParametersStateKey],
                          'customerOrderNumber',
                          persistentListParameters.initialState[persistentListParametersStateKey].customerOrderNumber
                        );
                      }}
                    />
                  </Grid>
                )}

              {persistentListParameters.state[persistentListParametersStateKey].poNumber &&
                persistentListParameters.state[persistentListParametersStateKey].poNumber !==
                  persistentListParameters.initialState[persistentListParametersStateKey].poNumber && (
                  <Grid item>
                    <Chip
                      color='error'
                      variant='outlined'
                      label={`PO # contains "${persistentListParameters.state[persistentListParametersStateKey].poNumber}"`}
                      onDelete={() => {
                        onFilter(
                          persistentListParameters.state[persistentListParametersStateKey],
                          'poNumber',
                          persistentListParameters.initialState[persistentListParametersStateKey].poNumber
                        );
                      }}
                    />
                  </Grid>
                )}

              {persistentListParameters.state[persistentListParametersStateKey].foreman &&
                persistentListParameters.state[persistentListParametersStateKey].foreman !==
                  persistentListParameters.initialState[persistentListParametersStateKey].foreman && (
                  <Grid item>
                    <Chip
                      color='error'
                      variant='outlined'
                      label={`Foreman contains "${persistentListParameters.state[persistentListParametersStateKey].foreman}"`}
                      onDelete={() => {
                        onFilter(
                          persistentListParameters.state[persistentListParametersStateKey],
                          'foreman',
                          persistentListParameters.initialState[persistentListParametersStateKey].foreman
                        );
                      }}
                    />
                  </Grid>
                )}
            </Grid>
          </Grid>
        }
        columns={[
          // { label: '', propertyName: 'id' },
          { label: 'Form #', propertyName: 'formNumber' },
          { label: 'Customer', propertyName: 'customer' },
          { label: 'Last Modified', propertyName: 'updatedDateTimeUtc' },
          { label: 'Job Date', propertyName: 'date' },
          { label: 'Status', propertyName: 'formStatus.name' },
          { label: 'Export', propertyName: '', canSort: false },
          ...(invoiceable ? [{ label: 'Invoice', propertyName: '', canSort: false }] : []),
          { label: '', propertyName: '', canSort: false }
        ]}
        onChangePage={onChangePage}
        onChangePageSize={onChangePageSize}
        onSortChange={onSortChange}
        page={persistentListParameters.state[persistentListParametersStateKey].page!}
        pageSize={persistentListParameters.state[persistentListParametersStateKey].pageSize!}
        sortBy={persistentListParameters.state[persistentListParametersStateKey].sortBy}
        sortDirection={persistentListParameters.state[persistentListParametersStateKey].sortDirection}
        totalCount={totalForms}
      >
        {forms?.map(form => (
          <TableRow key={form.id}>
            {/* <TableCell>{form.id}</TableCell> */}

            <TableCell>
              <Link component={NavLink} sx={{ cursor: 'pointer', textDecoration: 'none' }} to={`/forms/${form.formNumber}`}>
                {form.formNumber}
              </Link>
            </TableCell>

            <TableCell>{form.customer?.length > 100 ? form.customer.substring(0, 100) : form.customer}</TableCell>

            <TableCell>{formatDateTime(form.updatedDateTimeUtc!)}</TableCell>

            <TableCell>{formatDate(form.date)}</TableCell>

            <TableCell>{FormStatuses[form.formStatusId!]}</TableCell>

            <TableCell>
              <Checkbox
                checked={exportForms.some(exportForm => exportForm.formNumber === form.formNumber)}
                onClick={() => {
                  setExportForms(
                    exportForms.some(exportForm => exportForm.formNumber === form.formNumber)
                      ? exportForms.filter(exportForm => exportForm.formNumber !== form.formNumber)
                      : [...exportForms, form]
                  );
                }}
              />
            </TableCell>

            {invoiceable && (
              <TableCell>
                <Checkbox
                  disabled={
                    !form.invoiceable ||
                    (invoiceForms.length > 0 &&
                      !invoiceForms.some(
                        invoiceForm => invoiceForm.jobNumber === form.jobNumber && invoiceForm.customer === form.customer
                      ))
                  }
                  indeterminate={
                    !form.invoiceable ||
                    (invoiceForms.length > 0 &&
                      !invoiceForms.some(
                        invoiceForm => invoiceForm.jobNumber === form.jobNumber && invoiceForm.customer === form.customer
                      ))
                  }
                  checked={invoiceForms.some(invoiceForm => invoiceForm.formNumber === form.formNumber)}
                  onClick={() => {
                    setInvoiceForms(
                      invoiceForms.some(invoiceForm => invoiceForm.formNumber === form.formNumber)
                        ? invoiceForms.filter(invoiceForm => invoiceForm.formNumber !== form.formNumber)
                        : [...invoiceForms, form]
                    );
                  }}
                />
              </TableCell>
            )}

            <TableCell>
              <Tooltip title={`Copy Form #${form.formNumber}`}>
                <IconButton
                  onClick={() => {
                    navigate('/forms/new', {
                      state: {
                        form: {
                          companyId: form.companyId,
                          customer: form.customer,
                          date: form.date,
                          jobNumber: form.jobNumber,
                          locationOfWork: form.locationOfWork,
                          poNumber: form.poNumber,
                          projectManagerId: form.projectManagerId,
                          workPerformed: form.workPerformed
                        }
                      }
                    });
                  }}
                >
                  <ContentCopy />
                </IconButton>
              </Tooltip>
            </TableCell>
          </TableRow>
        ))}
      </Table>
    </Page>
  );
}
