import { Collapse, Drawer as MuiDrawer, List as MuiList, Toolbar } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import MenuItem from './MenuItem';
import { MCE_GREY } from './theme';

interface DrawerProps {
  collapse: boolean;
}

const Drawer = styled(MuiDrawer, {
  // The DOM does not recognize the boolean attribute 'collapse' so it should not be forwarded to avoid a console error.
  shouldForwardProp: prop => prop !== 'collapse'
})<DrawerProps>(props => ({
  flexShrink: 0,
  overflow: 'hidden',
  transition: 'width 225ms',
  width: props.collapse ? '80px' : '256px',

  '.MuiDrawer-paper': {
    transition: 'width 225ms',
    width: props.collapse ? '80px' : '256px'
  }
}));

const DrawerContainer = styled('div')({
  overflow: 'auto',
  backgroundColor: MCE_GREY,
  height: '100%',
  color: '#fff',
  paddingTop: '5px'
});

const List = styled(MuiList)({
  overflow: 'hidden',
  padding: '0px'
});

interface Props {
  isDrawerOpen: boolean;
  isInvoiceAdmin: boolean;
  isMobile: boolean;
  onCloseDrawer: () => void;
  trigger: boolean;
}

const MenuDrawer: React.FC<Props> = ({ isDrawerOpen, isInvoiceAdmin, isMobile, onCloseDrawer, trigger }) => {
  return (
    <Drawer
      anchor='left'
      open={isDrawerOpen}
      onClose={onCloseDrawer}
      variant={isMobile ? 'temporary' : 'permanent'}
      ModalProps={{
        keepMounted: true // Better open performance on mobile.
      }}
      collapse={!isDrawerOpen}
    >
      {!isMobile && (
        <Collapse in={!trigger} timeout={225}>
          <Toolbar />
        </Collapse>
      )}

      <DrawerContainer>
        <List>
          <MenuItem icon='Inbox' to='/my-forms'>
            My Forms
          </MenuItem>

          <MenuItem icon='Drafts' to='/my-drafts'>
            My Drafts
          </MenuItem>

          <MenuItem icon='PendingActions' to='/submitted'>
            Submitted
          </MenuItem>

          <MenuItem icon='Task' to='/processed'>
            Processed
          </MenuItem>

          <MenuItem icon='Receipt' to='/invoiced'>
            Invoiced
          </MenuItem>

          <MenuItem icon='AllInbox' to='/all-forms'>
            All Forms
          </MenuItem>

          <MenuItem icon='Business' to='/company/1'>
            MCE Co.
          </MenuItem>

          <MenuItem icon='Business' to='/company/100'>
            MCE Service
          </MenuItem>

          <MenuItem icon='Business' to='/company/9'>
            MCE Technologies
          </MenuItem>

          <MenuItem icon='Business' to='/company/5'>
            MCE Utilities
          </MenuItem>

          <MenuItem icon='Business' to='/company/14'>
            Mid Michigan
          </MenuItem>

          <MenuItem icon='Business' to='/company/8'>
            Mid-South Contractors
          </MenuItem>

          <MenuItem icon='Business' to='/company/10'>
            Huron Valley Electric
          </MenuItem>

          <MenuItem icon='Business' to='/company/12'>
            Rotor Electric
          </MenuItem>

          <MenuItem icon='Business' to='/company/2'>
            Stelko Electric
          </MenuItem>

          <MenuItem icon='Business' to='/company/60'>
            Williams Electrical
          </MenuItem>

          {isInvoiceAdmin && (
            <MenuItem icon='Receipt' to='/invoicing'>
              Invoicing
            </MenuItem>
          )}

          {isInvoiceAdmin && (
            <MenuItem icon='Bolt' to='/jobs'>
              Jobs
            </MenuItem>
          )}

          <MenuItem icon='Construction' to='/equipment'>
            Equipment
          </MenuItem>

          {isInvoiceAdmin && (
            <MenuItem icon='People' to='/customers'>
              Customers
            </MenuItem>
          )}
        </List>
      </DrawerContainer>
    </Drawer>
  );
};

export default MenuDrawer;
