import { api } from 'app/api';
import { BatchExportTypes } from '../constants';
import { FormDto } from 'dtos';
import { base64ToArrayBuffer, pad2 } from 'utils';

export const batchExport = (forms: FormDto[], batchExportType: BatchExportTypes) => {
  const today = new Date();
  // yyyymmddhhmmss timestamp
  const timestamp =
    today.getFullYear() +
    pad2(today.getMonth() + 1) +
    pad2(today.getDate()) +
    pad2(today.getHours()) +
    pad2(today.getMinutes()) +
    pad2(today.getSeconds());

  if (batchExportType === BatchExportTypes.SingleFileExport) {
    const requestUrl = `telerik-reports/export?formIds=${forms
      .map(form => form.id?.toString())
      .join(',')}`;

    api.get(requestUrl).then(({ data }) => {
      const buffer = base64ToArrayBuffer(data);
      const file = new Blob([buffer], { type: 'application/pdf' });

      const anchor = document.createElement('a');
      anchor.href = window.URL.createObjectURL(file);
      anchor.download = `BatchExport.${forms
        .map(form => form.formNumber?.toString())
        .join('.')}.${timestamp}.pdf`;
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    });
  } else {
    forms.forEach(form => {
      const requestUrl = `telerik-reports/export?formIds=${form.id}`;

      api.get(requestUrl).then(({ data }) => {
        const buffer = base64ToArrayBuffer(data);
        const file = new Blob([buffer], { type: 'application/pdf' });

        const anchor = document.createElement('a');
        anchor.href = window.URL.createObjectURL(file);
        anchor.download = `BatchExport.${form.formNumber}.${timestamp}.pdf`;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
      });
    });
  }
};

export const batchExport2 = async (batchExportType: string, formNumbers: string[]) =>
  await api
    .get(
      'batch-export?batchExportType=' +
        batchExportType +
        formNumbers.map(formNumber => `&formNumber=${formNumber}`)
    )
    .then(res => res.data);
