export interface EquipmentDto {
  id?: number;
  name: string;
}

export const equipmentInit: EquipmentDto = {
  name: ''
};

export interface EquipmentParameters {
  page?: number;
  pageSize?: number;
  sortBy?: string;
  sortDirection?: 'asc' | 'desc';
  filterText: string;
}

export const equipmentParametersInit: EquipmentParameters = {
  page: 0,
  pageSize: 10,
  sortBy: 'name',
  sortDirection: 'asc',
  filterText: ''
};
