import * as yup from 'yup';
import { CustomerDto, customerInit, customerValidatonSchema } from './customer';
import { CustomerAddressDto, customerAddressInit, customerAddressValidationSchema } from './customerAddress';

export interface JobContractDetailDto {
  id?: number;
  contractNumber: number;
  customer: CustomerDto;
  customerAddress: CustomerAddressDto;
  description: string;
  poNumber: string;
}

export const jobContractDetailInit: JobContractDetailDto = {
  contractNumber: 0,
  customer: customerInit,
  customerAddress: customerAddressInit,
  description: '',
  poNumber: ''
};

export const jobContractDetailValidationSchema = yup.object({
  customer: yup.object({ id: yup.number().required('Customer is required.') }).required('Customer is required.'),
  customerAddress: yup
    .object({ id: yup.number().required('Customer Address is required.') })
    .required('Customer Address is required.'),
  description: yup.string().max(200, 'Description cannot be more than 200 characters.').required('Description is required.'),
  poNumber: yup.string().max(200, 'PO # cannot be more than 200 characters.').required('PO # is required.')
});
