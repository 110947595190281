import { Add, Clear, CloudUpload, Delete, Download, PrintOutlined, Upload } from '@mui/icons-material';
import {
  Autocomplete,
  Button,
  ButtonBase,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  MenuItem,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import {
  createForm as createFormApi,
  getCompanies,
  getEquipment,
  getEquipmentByJobNumber as getEquipmentByJobNumberApi,
  getFormByFormNumber,
  getLaborClassifications,
  getUsers,
  printForm,
  processForm as processFormApi,
  submitForm as submitFormApi,
  updateForm as updateFormApi
} from 'api';
import { api } from 'app/api';
import { BottomFormBar, Page, UnsavedChangesPrompt } from 'components';
import { useAuth } from 'context';
import {
  CompanyDto,
  EquipmentDto,
  FormDto,
  FormMaterialDto,
  FormStatuses,
  LaborClassificationDto,
  UserDto,
  formEquipmentInit,
  formInit,
  formLaborInit,
  formMaterialInit,
  formValidationSchema
} from 'dtos';
import { FormEquipmentDto, FormMiscellaneousDto, formMiscellaneousInit, formSubcontractInit } from 'dtos/form';
import { Formik, getIn, setNestedObjectValues } from 'formik';
import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import Dropzone from 'react-dropzone';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { default as ReactSignatureCanvas, default as SignatureCanvas } from 'react-signature-canvas';
import { base64ToArrayBuffer, emptyIfNullOrUndefined, useDebounce, useDebouncedEffect } from 'utils';
import { Companies } from '../constants';

export default function AddEditViewForm() {
  // #region hooks
  const { formNumber } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  const { IS_ADMIN } = useAuth();
  // #endregion

  // #region useState
  const [isFormEditable, setIsFormEditable] = useState<boolean>(false);

  const [companies, setCompanies] = useState<CompanyDto[]>([]);
  const [initialValues, setInitialValues] = useState<FormDto>(formInit);
  const [isCreatingForm, setIsCreatingForm] = useState<boolean>(false);
  const [isGettingCompanies, setIsGettingCompanies] = useState<boolean>(false);
  const [isGettingForm, setIsGettingForm] = useState<boolean>(false);
  const [isGettingLaborClassifications, setIsGettingLaborClassifications] = useState<boolean>(false);
  const [isGettingUsers, setIsGettingUsers] = useState<boolean>(false);
  const [isUpdatingForm, setIsUpdatingForm] = useState<boolean>(false);
  const [laborClassifications, setLaborClassifications] = useState<LaborClassificationDto[]>([]);
  const [users, setUsers] = useState<UserDto[]>([]);

  const [equipment, setEquipment] = useState<EquipmentDto[]>([]);
  const [jobEquipment, setJobEquipment] = useState<EquipmentDto[]>([]);

  // 'generalEquipment' is all equipment from the [Equipment] table, whereas 'equipment' is generalEquipment + the job's equipment, and is the options
  // for the equipment Description autocomplete.
  // Maxwell Sommer 7/30/2024
  const [generalEquipment, setGeneralEquipment] = useState<EquipmentDto[]>([]);
  const [isGettingEquipment, setIsGettingEquipment] = useState<boolean>(false);

  const [isPrintingForm, setIsPrintingForm] = useState<boolean>(false);
  const [isSubmittingForm, setIsSubmittingForm] = useState<boolean>(false);
  const [isProcessingForm, setIsProcessingForm] = useState<boolean>(false);
  // #endregion

  useEffect(() => {
    setEquipment([...(generalEquipment ?? []), ...(jobEquipment ?? [])]);
  }, [generalEquipment, jobEquipment]);

  // #region useRef
  const supervisorSignatureCanvas = useRef() as React.MutableRefObject<ReactSignatureCanvas>;
  const customerSignatureCanvas = useRef() as React.MutableRefObject<ReactSignatureCanvas>;
  // #endregion

  // #region useEffect
  useDebouncedEffect(
    () => {
      setIsGettingCompanies(true);
      getCompanies()
        .then(({ value }) => {
          setCompanies(value);
        })
        .finally(() => setIsGettingCompanies(false));

      setIsGettingUsers(true);
      getUsers()
        .then(({ value }) => {
          setUsers(value);
        })
        .finally(() => setIsGettingUsers(false));

      setIsGettingLaborClassifications(true);
      getLaborClassifications()
        .then(({ value }) => {
          setLaborClassifications(value);
        })
        .finally(() => setIsGettingLaborClassifications(false));

      setIsGettingEquipment(true);
      getEquipment({ filterText: '', page: 0, pageSize: 1000 })
        .then(({ value }) => {
          setGeneralEquipment(value);
        })
        .finally(() => setIsGettingEquipment(false));
    },
    300,
    []
  );

  const getFormNumber = useDebounce((formNumber: string) => {
    setIsGettingForm(true);
    getFormByFormNumber(formNumber)
      .then(({ value }) => {
        let formEquipment: FormEquipmentDto[] = [];

        if (
          !value.formEquipment.some(formEquipment => formEquipment.description.toLowerCase() === 'Service Truck'.toLowerCase())
        ) {
          formEquipment.push({ description: 'Service Truck', quantity: 0 });
        }
        if (
          !value.formEquipment.some(
            formEquipment => formEquipment.description.toLowerCase() === "Manlift Scissors 25'".toLowerCase()
          )
        ) {
          formEquipment.push({ description: "Manlift Scissors 25'", quantity: 0 });
        }
        if (
          !value.formEquipment.some(formEquipment => formEquipment.description.toLowerCase() === 'Pick Up Truck'.toLowerCase())
        ) {
          formEquipment.push({ description: 'Pick Up Truck', quantity: 0 });
        }
        if (
          !value.formEquipment.some(formEquipment => formEquipment.description.toLowerCase() === 'Rolling Set Up'.toLowerCase())
        ) {
          formEquipment.push({ description: 'Rolling Set Up', quantity: 0 });
        }
        if (
          !value.formEquipment.some(
            formEquipment => formEquipment.description.toLowerCase() === "Manlift Boom 40'".toLowerCase()
          )
        ) {
          formEquipment.push({ description: "Manlift Boom 40'", quantity: 0 });
        }

        formEquipment = [...formEquipment, ...value.formEquipment];

        setInitialValues({
          ...value,
          formEquipment
        });
      })
      .finally(() => setIsGettingForm(false));
  }, 300);

  useEffect(() => {
    if (formNumber && formNumber.toLowerCase() !== 'new' && formNumber.toLowerCase() !== 'undefined') {
      getFormNumber(formNumber);
    } else {
      if (location?.state?.form) {
        setInitialValues({
          ...formInit,
          ...location.state.form
          // companyId: location.state.form.companyId,
          // customer: location.state.form.customer,
          // date: location.state.form.date,
          // jobNumber: location.state.form.jobNumber,
          // locationOfWork: location.state.form.locationOfWork,
          // customerOrderNumber: location.state.form.customerOrderNumber,
          // poNumber: location.state.form.poNumber,
          // projectManagerId: location.state.form.projectManagerId,
          // workPerformed: location.state.form.workPerformed
        });
      } else {
        setInitialValues(formInit);
      }
    }
  }, [formNumber]);

  // Set signature canvas values (if exists) and get Job's equipment
  useEffect(() => {
    if (initialValues.formNumber && initialValues.supervisorSignatureBase64) {
      supervisorSignatureCanvas.current.fromDataURL(initialValues.supervisorSignatureBase64);
    }

    if (initialValues.formNumber && initialValues.customerSignatureBase64) {
      customerSignatureCanvas.current.fromDataURL(initialValues.customerSignatureBase64);
    }

    if (initialValues.jobNumber) {
      getEquipmentByJobNumber(initialValues.jobNumber);
    }
  }, [initialValues]);

  const getEquipmentByJobNumber = useDebounce((jobNumber: string) => {
    setIsGettingEquipment(true);
    getEquipmentByJobNumberApi(jobNumber)
      .then(res => {
        setJobEquipment(res.value);
      })
      .finally(() => {
        setIsGettingEquipment(false);
      });
  }, 300);

  // Set if the form is editable
  useEffect(() => {
    // Form's can only be edited/updated by non-admins if the form is of status Draft or Submitted.
    setIsFormEditable(
      IS_ADMIN || initialValues.formStatusId === FormStatuses.Draft || initialValues.formStatusId === FormStatuses.Submitted
    );
  }, [initialValues, IS_ADMIN]);

  // Set signature canvas on/off state based on isFormEditable
  useEffect(() => {
    if (isFormEditable) {
      supervisorSignatureCanvas.current.on();
      customerSignatureCanvas.current.on();
    } else {
      supervisorSignatureCanvas.current.off();
      customerSignatureCanvas.current.off();
    }
  }, [isFormEditable]);
  // #endregion

  const calculateMiscellaneousTotal = (formMiscellaneous: FormMiscellaneousDto): number => {
    const unitCost = formMiscellaneous.unitCost ?? 0;
    const quantity = formMiscellaneous.quantity ?? 0;
    const markupPercentage = formMiscellaneous.markupPercentage ?? 0;
    const overheadProfitPercentage = formMiscellaneous.overheadProfitPercentage ?? 0;

    let per: number;

    switch (formMiscellaneous.per?.toUpperCase()) {
      case 'M':
        per = 1_000;
        break;
      case 'C':
        per = 100;
        break;
      default:
        per = 1;
        break;
    }

    return (
      (unitCost * quantity) / per +
      ((unitCost * quantity) / per) * (markupPercentage / 100) +
      ((unitCost * quantity) / per) * (overheadProfitPercentage / 100)
    );
  };

  const calculateExtension = (formMaterial: FormMaterialDto): number => {
    let product = (formMaterial.quantity ?? 0) * (formMaterial.price ?? 0);
    let per: number;

    switch (formMaterial.per?.toUpperCase()) {
      case 'M':
        per = 1_000;
        break;
      case 'C':
        per = 100;
        break;
      default:
        per = 1;
        break;
    }

    return parseFloat((product / per).toFixed(2));
  };

  const getCompanyIdByJobNumber = (jobNumber: string): number => {
    if (!jobNumber || jobNumber.length < 6) {
      return -1;
    }

    let firstPart = jobNumber[0];
    let secondPart = jobNumber[1];
    let thirdPart = jobNumber[2];

    if (
      ['0', '1', '2'].includes(firstPart) &&
      ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(secondPart) &&
      thirdPart == '7'
    ) {
      return Companies.MotorCityElectricService;
    } /*else if (firstPart === '1' || thirdPart === '1') {
      return Companies.MotorCityElectric;
    } */ else if (firstPart === '3') {
      return Companies.StelkoElectric;
    } else if (firstPart === '4') {
      return Companies.HuronValleyElectric;
    } else if (firstPart === '5') {
      return Companies.MotorCityElectricUtilities;
    } else if (firstPart === '6') {
      return Companies.WilliamsElectricalAndTelecommunications;
    } else if (firstPart === '7') {
      return Companies.RotorElectric;
    } else if (firstPart === '8') {
      return Companies.MidSouthContractors;
    } else if (firstPart === '9') {
      return Companies.MotorCityElectricTechnologies;
    } else if (firstPart === '1' && secondPart === '4') {
      return Companies.MidMichiganElectricalSolutions;
    }

    return Companies.MotorCityElectric;
    // return -1;
  };

  const createForm = (values: FormDto) => {
    const payload = new FormData();

    payload.append('form', JSON.stringify(values));

    values.formAttachments?.forEach((formAttachment, index) => {
      if (formAttachment.attachment) {
        payload.append(`formAttachments[${index}].attachment`, formAttachment.attachment);
      }
    });

    setIsCreatingForm(true);
    createFormApi(payload)
      .then(({ value }) => {
        enqueueSnackbar(`Form #${value.formNumber} created successfully!`, {
          variant: 'success'
        });
        setInitialValues(value);

        // Timeout is required to prevent the unsaved changes prompt from displaying
        setTimeout(() => {
          navigate('/forms/' + value.formNumber, { replace: true });
        }, 100);
      })
      .finally(() => {
        setIsCreatingForm(false);
      });
  };

  const updateForm = (values: FormDto) => {
    const payload = new FormData();

    payload.append('form', JSON.stringify(values));

    values.formAttachments?.forEach((formAttachment, index) => {
      if (formAttachment.attachment) {
        payload.append(`formAttachments[${index}].attachment`, formAttachment.attachment);
      }
    });

    setIsUpdatingForm(true);
    updateFormApi(payload)
      .then(({ value }) => {
        enqueueSnackbar(`Form #${value.formNumber} updated successfully!`, {
          variant: 'success'
        });
        setInitialValues(value);
        // Timeout is required to prevent the unsaved changes prompt from displaying
        // setTimeout(() => {
        //   navigate(-1);
        // }, 100);
      })
      .finally(() => {
        setIsUpdatingForm(false);
      });
  };

  const submitForm = (values: FormDto) => {
    const payload = new FormData();

    payload.append('form', JSON.stringify(values));

    values.formAttachments?.forEach((formAttachment, index) => {
      if (formAttachment.attachment) {
        payload.append(`formAttachments[${index}].attachment`, formAttachment.attachment);
      }
    });

    setIsSubmittingForm(true);
    submitFormApi(payload)
      .then(({ value }) => {
        enqueueSnackbar(`Form #${value.formNumber} submitted successfully!`, {
          variant: 'success'
        });

        setInitialValues(value);

        // Timeout is required to prevent the unsaved changes prompt from displaying
        setTimeout(() => {
          navigate(-1);
        }, 100);
      })
      .finally(() => {
        setIsSubmittingForm(false);
      });
  };

  const processForm = (values: FormDto) => {
    const payload = new FormData();

    payload.append('form', JSON.stringify(values));

    values.formAttachments?.forEach((formAttachment, index) => {
      if (formAttachment.attachment) {
        payload.append(`formAttachments[${index}].attachment`, formAttachment.attachment);
      }
    });

    setIsProcessingForm(true);
    processFormApi(payload)
      .then(({ value }) => {
        enqueueSnackbar(`Form #${value.formNumber} processed successfully!`, {
          variant: 'success'
        });
        setInitialValues(value);
        // Timeout is required to prevent the unsaved changes prompt from displaying
        setTimeout(() => {
          navigate(-1);
        }, 100);
      })
      .finally(() => {
        setIsProcessingForm(false);
      });
  };

  return (
    <Page
      title={
        initialValues.formNumber
          ? isFormEditable
            ? `Edit Form #${initialValues.formNumber}`
            : `View Form #${initialValues.formNumber}`
          : 'Add Form'
      }
      isLoading={isCreatingForm || isGettingForm || isProcessingForm || isPrintingForm || isSubmittingForm || isUpdatingForm}
    >
      <Card>
        <CardContent>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validateOnChange
            validateOnBlur
            validationSchema={formValidationSchema}
            onSubmit={() => {
              // Do nothing
            }}
          >
            {({
              dirty,
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              touched,
              setTouched,
              validateForm,
              values
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={2} alignItems='center'>
                    <Grid item container spacing={2} alignItems='center' justifyContent='space-between'>
                      <Grid item xs={12} sm>
                        <Typography sx={{ fontWeight: '600' }}>
                          {!!values.companyId && companies?.filter(company => company.id === values.companyId)[0]?.address}
                        </Typography>
                      </Grid>

                      <Grid spacing={2} item container xs={12} sm justifyContent='flex-end' alignItems='center'>
                        <Grid item>
                          <Typography>
                            STATUS: <b>{FormStatuses[values.formStatusId!].toUpperCase()}</b>
                          </Typography>
                        </Grid>

                        <Grid item>
                          <Tooltip title={`Print Form #${values.formNumber}`}>
                            <IconButton
                              onClick={() => {
                                setIsPrintingForm(true);
                                printForm(values).finally(() => {
                                  setIsPrintingForm(false);
                                });
                              }}
                              disabled={!values.formNumber}
                            >
                              <PrintOutlined />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Tooltip arrow title={values.companyId === -1 ? 'Please enter a Job # to enable this field.' : ''}>
                        <TextField
                          disabled={!isFormEditable || values.companyId === -1}
                          error={Boolean(touched.companyId && errors.companyId)}
                          fullWidth
                          helperText={touched.companyId && errors.companyId}
                          label='Company'
                          name='companyId'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          select
                          size='small'
                          value={values.companyId || ''}
                        >
                          {companies
                            .sort((a, b) => a.name!.localeCompare(b.name!))
                            .map(company => (
                              <MenuItem key={company.id} value={company.id}>
                                {company.displayName ?? company.name} {company.contact && ` - ${company.contact}`}
                              </MenuItem>
                            ))}
                        </TextField>
                      </Tooltip>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Tooltip arrow title={Boolean(initialValues.id) ? 'Job # cannot be changed after saving.' : ''}>
                        <TextField
                          disabled={!isFormEditable || Boolean(initialValues.id)}
                          error={Boolean(touched.jobNumber && errors.jobNumber)}
                          fullWidth
                          helperText={touched.jobNumber && errors.jobNumber}
                          inputProps={{ maxLength: 6 }}
                          label='Job #'
                          name='jobNumber'
                          onBlur={handleBlur}
                          onChange={e => {
                            setFieldValue('jobNumber', e.target.value);

                            if (e.target.value && e.target.value.length === 6) {
                              setFieldValue('companyId', getCompanyIdByJobNumber(e.target.value));

                              // Get the Job #'s equipment
                              getEquipmentByJobNumber(e.target.value);
                            }
                          }}
                          size='small'
                          // type='number'
                          value={values.jobNumber || ''}
                        />
                      </Tooltip>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <TextField
                        disabled={!isFormEditable}
                        error={Boolean(touched.customer && errors.customer)}
                        fullWidth
                        helperText={touched.customer && errors.customer}
                        label='Customer'
                        name='customer'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        size='small'
                        value={values.customer || ''}
                      />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <TextField
                        disabled={!isFormEditable}
                        error={Boolean(touched.date && errors.date)}
                        fullWidth
                        helperText={touched.date && errors.date}
                        InputLabelProps={{ shrink: true }}
                        label='Date'
                        name='date'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        size='small'
                        type='date'
                        value={values.date || ''}
                      />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <TextField
                        disabled={!isFormEditable}
                        error={Boolean(touched.locationOfWork && errors.locationOfWork)}
                        fullWidth
                        helperText={touched.locationOfWork && errors.locationOfWork}
                        label='Location of Work'
                        name='locationOfWork'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        size='small'
                        value={values.locationOfWork || ''}
                      />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <TextField
                        disabled={!isFormEditable}
                        error={Boolean(touched.customerOrderNumber && errors.customerOrderNumber)}
                        fullWidth
                        helperText={touched.customerOrderNumber && errors.customerOrderNumber}
                        label='Customer Order #'
                        name='customerOrderNumber'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        size='small'
                        value={values.customerOrderNumber || ''}
                      />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <TextField
                        disabled={!isFormEditable}
                        error={Boolean(touched.poNumber && errors.poNumber)}
                        fullWidth
                        helperText={touched.poNumber && errors.poNumber}
                        label='PO #'
                        name='poNumber'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        size='small'
                        value={values.poNumber || ''}
                      />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Autocomplete
                        disabled={!isFormEditable}
                        autoHighlight
                        autoSelect
                        // disableClearable
                        getOptionLabel={user => (user ? user.firstName + ' ' + user.lastName : '')}
                        onBlur={handleBlur}
                        onChange={(_e, value) => {
                          setFieldValue('projectManagerId', value?.id ?? '');
                        }}
                        options={users
                          .filter(user => values.projectManagerId === user.id || !user.inactivatedDateTime)
                          .sort((a, b) => `${a.firstName} ${b.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`))}
                        value={users.filter(user => user.id === values.projectManagerId)[0] || ''}
                        renderInput={params => (
                          <TextField
                            {...params}
                            error={Boolean(touched.projectManagerId && errors.projectManagerId)}
                            helperText={touched.projectManagerId && errors.projectManagerId}
                            label='Project Manager'
                            size='small'
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        disabled={!isFormEditable}
                        error={Boolean(touched.workPerformed && errors.workPerformed)}
                        fullWidth
                        helperText={touched.workPerformed && errors.workPerformed}
                        label='Work Performed'
                        maxRows={16}
                        minRows={4}
                        multiline
                        name='workPerformed'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        size='small'
                        value={values.workPerformed || ''}
                      />
                    </Grid>

                    {
                      // #region Labor
                    }
                    <Grid item xs={12} container spacing={1} alignItems='center'>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>

                      <Grid item xs={12}>
                        <Typography fontWeight='bold'>LABOR</Typography>
                      </Grid>

                      {values.formLabor.map((_, index) => (
                        <>
                          <Grid item xs={12} sm={3}>
                            <TextField
                              disabled={!isFormEditable}
                              error={Boolean(
                                getIn(touched, `formLabor[${index}].name`) && getIn(errors, `formLabor[${index}].name`)
                              )}
                              fullWidth
                              helperText={
                                getIn(touched, `formLabor[${index}].name`) && getIn(errors, `formLabor[${index}].name`)
                              }
                              label='Name'
                              name={`formLabor[${index}].name`}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              size='small'
                              value={values.formLabor[index].name || ''}
                            />
                          </Grid>

                          <Grid item xs={12} sm={1}>
                            <TextField
                              disabled={!isFormEditable}
                              error={Boolean(
                                getIn(touched, `formLabor[${index}].truckNumber`) &&
                                  getIn(errors, `formLabor[${index}].truckNumber`)
                              )}
                              fullWidth
                              helperText={
                                getIn(touched, `formLabor[${index}].truckNumber`) &&
                                getIn(errors, `formLabor[${index}].truckNumber`)
                              }
                              label='Truck #'
                              name={`formLabor[${index}].truckNumber`}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              size='small'
                              value={values.formLabor[index].truckNumber || ''}
                            />
                          </Grid>

                          <Grid item xs={12} sm={1}>
                            <TextField
                              disabled={!isFormEditable}
                              error={Boolean(
                                getIn(touched, `formLabor[${index}].laborClassificationId`) &&
                                  getIn(errors, `formLabor[${index}].laborClassificationId`)
                              )}
                              fullWidth
                              helperText={
                                getIn(touched, `formLabor[${index}].laborClassificationId`) &&
                                getIn(errors, `formLabor[${index}].laborClassificationId`)
                              }
                              label='Class'
                              name={`formLabor[${index}].laborClassificationId`}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              select
                              size='small'
                              value={values.formLabor[index].laborClassificationId || ''}
                            >
                              {laborClassifications
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map(laborClassification => (
                                  <MenuItem key={laborClassification.id} value={laborClassification.id}>
                                    {laborClassification.name}
                                  </MenuItem>
                                ))}
                            </TextField>
                          </Grid>

                          <Grid item xs={12} sm={1}>
                            <TextField
                              disabled={!isFormEditable}
                              error={Boolean(
                                getIn(touched, `formLabor[${index}].straightTime`) &&
                                  getIn(errors, `formLabor[${index}].straightTime`)
                              )}
                              fullWidth
                              helperText={
                                getIn(touched, `formLabor[${index}].straightTime`) &&
                                getIn(errors, `formLabor[${index}].straightTime`)
                              }
                              label='Straight Time'
                              name={`formLabor[${index}].straightTime`}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              onWheel={e => {
                                (e.target as HTMLElement).blur();
                              }}
                              size='small'
                              type='number'
                              value={emptyIfNullOrUndefined(values.formLabor[index].straightTime)}
                            />
                          </Grid>

                          <Grid item xs={12} sm={1}>
                            <TextField
                              disabled={!isFormEditable}
                              error={Boolean(
                                getIn(touched, `formLabor[${index}].timeAndAHalf`) &&
                                  getIn(errors, `formLabor[${index}].timeAndAHalf`)
                              )}
                              fullWidth
                              helperText={
                                getIn(touched, `formLabor[${index}].timeAndAHalf`) &&
                                getIn(errors, `formLabor[${index}].timeAndAHalf`)
                              }
                              label='Time ½'
                              name={`formLabor[${index}].timeAndAHalf`}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              onWheel={e => {
                                (e.target as HTMLElement).blur();
                              }}
                              size='small'
                              type='number'
                              value={emptyIfNullOrUndefined(values.formLabor[index].timeAndAHalf)}
                            />
                          </Grid>

                          <Grid item xs={12} sm={1}>
                            <TextField
                              disabled={!isFormEditable}
                              error={Boolean(
                                getIn(touched, `formLabor[${index}].doubleTime`) &&
                                  getIn(errors, `formLabor[${index}].doubleTime`)
                              )}
                              fullWidth
                              helperText={
                                getIn(touched, `formLabor[${index}].doubleTime`) &&
                                getIn(errors, `formLabor[${index}].doubleTime`)
                              }
                              label='Double Time'
                              name={`formLabor[${index}].doubleTime`}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              onWheel={e => {
                                (e.target as HTMLElement).blur();
                              }}
                              size='small'
                              type='number'
                              value={emptyIfNullOrUndefined(values.formLabor[index].doubleTime)}
                            />
                          </Grid>

                          <Grid item xs={12} sm={1}>
                            <TextField
                              disabled={!isFormEditable}
                              error={Boolean(
                                getIn(touched, `formLabor[${index}].shift`) && getIn(errors, `formLabor[${index}].shift`)
                              )}
                              fullWidth
                              helperText={
                                getIn(touched, `formLabor[${index}].shift`) && getIn(errors, `formLabor[${index}].shift`)
                              }
                              label='Shift'
                              name={`formLabor[${index}].shift`}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              select
                              size='small'
                              value={values.formLabor[index].shift || ''}
                            >
                              <MenuItem value=''></MenuItem>
                              <MenuItem value='1st'>1st</MenuItem>
                              <MenuItem value='2nd'>2nd</MenuItem>
                              <MenuItem value='3rd'>3rd</MenuItem>
                            </TextField>
                          </Grid>

                          <Grid item xs={12} sm={index > 0 ? 2 : 3}>
                            <TextField
                              disabled={!isFormEditable}
                              error={Boolean(
                                getIn(touched, `formLabor[${index}].comments`) && getIn(errors, `formLabor[${index}].comments`)
                              )}
                              fullWidth
                              helperText={
                                getIn(touched, `formLabor[${index}].comments`) && getIn(errors, `formLabor[${index}].comments`)
                              }
                              label='Comments'
                              name={`formLabor[${index}].comments`}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              size='small'
                              value={values.formLabor[index].comments || ''}
                            />
                          </Grid>

                          {index > 0 && (
                            <Grid item xs={12} sm={1}>
                              <Button
                                disabled={!isFormEditable}
                                variant='contained'
                                color='inherit'
                                onClick={() =>
                                  setFieldValue(
                                    'formLabor',
                                    values.formLabor.filter((_, i) => index !== i)
                                  )
                                }
                              >
                                <Clear fontSize='small' /> DELETE
                              </Button>
                            </Grid>
                          )}
                        </>
                      ))}

                      <Grid item xs={12} container spacing={2} alignItems='center'>
                        <Grid item>
                          <Button
                            disabled={!isFormEditable}
                            variant='contained'
                            color='inherit'
                            onClick={() => setFieldValue('formLabor', [...values.formLabor, formLaborInit])}
                          >
                            <Add fontSize='small' /> ADD MORE LABOR
                          </Button>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} container spacing={2} alignItems='center' justifyContent='flex-end'>
                        <Grid item>
                          <Typography fontWeight='bold' variant='body2'>
                            TOTAL HOURS
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={1}>
                          <TextField
                            fullWidth
                            size='small'
                            disabled
                            value={
                              (
                                values.formLabor
                                  ?.map(formLabor => formLabor.straightTime || 0)
                                  ?.reduce((prev, next) => (prev ?? 0) + (next ?? 0), 0) ?? 0
                              )?.toFixed(2) ?? 0
                            }
                          />
                        </Grid>

                        <Grid item xs={12} sm={1}>
                          <TextField
                            fullWidth
                            size='small'
                            disabled
                            value={
                              (
                                values.formLabor
                                  ?.map(formLabor => formLabor.timeAndAHalf || 0)
                                  ?.reduce((prev, next) => (prev ?? 0) + (next ?? 0), 0) ?? 0
                              )?.toFixed(2) ?? 0
                            }
                          />
                        </Grid>

                        <Grid item xs={12} sm={1}>
                          <TextField
                            fullWidth
                            size='small'
                            disabled
                            value={
                              (
                                values.formLabor
                                  ?.map(formLabor => formLabor.doubleTime || 0)
                                  ?.reduce((prev, next) => (prev ?? 0) + (next ?? 0), 0) ?? 0
                              )?.toFixed(2) ?? 0
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    {
                      // #endregion
                    }

                    {
                      // #region Equipment
                    }
                    <Grid item xs={12} container spacing={1} alignItems='center'>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>

                      <Grid item xs={12} spacing={1} container alignItems='center' justifyContent='space-between'>
                        <Grid item>
                          <Typography fontWeight='bold'>EQUIPMENT</Typography>
                        </Grid>
                      </Grid>

                      {values.formEquipment.map((_, index) => (
                        <Grid item xs={12} container spacing={2} alignItems='center'>
                          <Grid item xs={12} sm={2}>
                            {index < 5 ? (
                              <TextField
                                disabled
                                error={Boolean(
                                  getIn(touched, `formEquipment[${index}].description`) &&
                                    getIn(errors, `formEquipment[${index}].description`)
                                )}
                                fullWidth
                                helperText={
                                  getIn(touched, `formEquipment[${index}].description`) &&
                                  getIn(errors, `formEquipment[${index}].description`)
                                }
                                label='Description'
                                name={`formEquipment[${index}].description`}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                size='small'
                                InputProps={{
                                  disableUnderline: true,
                                  style: { color: 'black', paddingLeft: 12 }
                                }}
                                InputLabelProps={{
                                  shrink: true,
                                  style: { color: 'rgba(0, 0, 0, 0.6)', paddingLeft: 16 }
                                }}
                                value={values.formEquipment[index].description || ''}
                                variant='standard'
                              />
                            ) : (
                              <Autocomplete
                                autoHighlight
                                autoSelect
                                disableClearable
                                disabled={index < 5 || !isFormEditable}
                                freeSolo
                                inputValue={values.formEquipment[index].description || ''}
                                onChange={(_: any, newValue: string | null) => {
                                  setFieldValue(`formEquipment[${index}].description`, newValue);
                                }}
                                options={equipment
                                  .sort((a, b) => a.name.localeCompare(b.name))
                                  .map(equipment => equipment.name)}
                                value={values.formEquipment[index].description || ''}
                                renderInput={props => (
                                  <TextField
                                    {...props}
                                    disabled={index < 5 || !isFormEditable}
                                    error={Boolean(
                                      getIn(touched, `formEquipment[${index}].description`) &&
                                        getIn(errors, `formEquipment[${index}].description`)
                                    )}
                                    fullWidth
                                    helperText={
                                      getIn(touched, `formEquipment[${index}].description`) &&
                                      getIn(errors, `formEquipment[${index}].description`)
                                    }
                                    label='Description'
                                    name={`formEquipment[${index}].description`}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    size='small'
                                    InputProps={{
                                      ...props.InputProps,
                                      endAdornment: (
                                        <>
                                          {index > 4 &&
                                          index === values.formEquipment.length - 1 &&
                                          isFormEditable &&
                                          isGettingEquipment ? (
                                            <InputAdornment position='end'>
                                              <CircularProgress size={16} />
                                            </InputAdornment>
                                          ) : null}

                                          {props.InputProps.endAdornment}
                                        </>
                                      )
                                    }}
                                  />
                                )}
                              />
                            )}
                          </Grid>

                          <Grid item xs={12} sm={1}>
                            <TextField
                              disabled={!isFormEditable}
                              error={Boolean(
                                getIn(touched, `formEquipment[${index}].quantity`) &&
                                  getIn(errors, `formEquipment[${index}].quantity`)
                              )}
                              fullWidth
                              helperText={
                                getIn(touched, `formEquipment[${index}].quantity`) &&
                                getIn(errors, `formEquipment[${index}].quantity`)
                              }
                              label='Quantity'
                              name={`formEquipment[${index}].quantity`}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              onWheel={e => {
                                (e.target as HTMLElement).blur();
                              }}
                              size='small'
                              type='number'
                              value={values.formEquipment[index].quantity || ''}
                            />
                          </Grid>

                          {index >= 5 && (
                            <Grid item>
                              <Button
                                color='inherit'
                                disabled={!isFormEditable}
                                onClick={() =>
                                  setFieldValue(
                                    'formEquipment',
                                    values.formEquipment.filter((_, i) => i !== index)
                                  )
                                }
                                variant='contained'
                              >
                                <Clear fontSize='small' /> DELETE
                              </Button>
                            </Grid>
                          )}
                        </Grid>
                      ))}

                      <Grid item xs={12} container spacing={2} alignItems='center'>
                        <Grid item>
                          <Button
                            color='inherit'
                            disabled={!isFormEditable}
                            onClick={() => setFieldValue('formEquipment', [...values.formEquipment, formEquipmentInit])}
                            variant='contained'
                          >
                            <Add fontSize='small' /> ADD MORE EQUIPMENT
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    {
                      // #endregion
                    }

                    {
                      // #region Materials
                    }
                    <Grid item xs={12} container spacing={1} alignItems='center'>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>

                      <Grid item xs={12}>
                        <Typography fontWeight='bold'>MATERIALS</Typography>
                      </Grid>

                      {values.formMaterials.map((formMaterial, index) => (
                        <Grid item xs={12} container spacing={2} alignItems='center'>
                          <Grid item xs={12} sm={3}>
                            <TextField
                              disabled={!isFormEditable}
                              error={Boolean(
                                getIn(touched, `formMaterials[${index}].description`) &&
                                  getIn(errors, `formMaterials[${index}].description`)
                              )}
                              fullWidth
                              helperText={
                                getIn(touched, `formMaterials[${index}].description`) &&
                                getIn(errors, `formMaterials[${index}].description`)
                              }
                              label='Description'
                              name={`formMaterials[${index}].description`}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              size='small'
                              value={values.formMaterials[index].description || ''}
                            />
                          </Grid>

                          <Grid item xs={12} sm={1}>
                            <TextField
                              disabled={!isFormEditable}
                              error={Boolean(
                                getIn(touched, `formMaterials[${index}].quantity`) &&
                                  getIn(errors, `formMaterials[${index}].quantity`)
                              )}
                              fullWidth
                              helperText={
                                getIn(touched, `formMaterials[${index}].quantity`) &&
                                getIn(errors, `formMaterials[${index}].quantity`)
                              }
                              label='Quantity'
                              name={`formMaterials[${index}].quantity`}
                              onBlur={handleBlur}
                              onChange={e => {
                                setFieldValue(`formMaterials[${index}].quantity`, e.target.value);
                                setFieldValue(
                                  `formMaterials[${index}].extension`,
                                  calculateExtension({
                                    ...formMaterial,
                                    quantity: +e.target.value // This is passed because state won't update by time this calls
                                  })
                                );
                              }}
                              onWheel={e => {
                                (e.target as HTMLElement).blur();
                              }}
                              size='small'
                              type='number'
                              value={values.formMaterials[index].quantity || ''}
                            />
                          </Grid>

                          {values.formStatusId && values.formStatusId > FormStatuses.Draft && (
                            <>
                              <Grid item xs={12} sm={2}>
                                <TextField
                                  disabled={!isFormEditable}
                                  error={Boolean(
                                    getIn(touched, `formMaterials[${index}].price`) &&
                                      getIn(errors, `formMaterials[${index}].price`)
                                  )}
                                  fullWidth
                                  helperText={
                                    getIn(touched, `formMaterials[${index}].price`) &&
                                    getIn(errors, `formMaterials[${index}].price`)
                                  }
                                  InputProps={{
                                    startAdornment: <InputAdornment position='start'>$</InputAdornment>
                                  }}
                                  label='Price'
                                  name={`formMaterials[${index}].price`}
                                  onBlur={handleBlur}
                                  onChange={e => {
                                    setFieldValue(`formMaterials[${index}].price`, e.target.value);
                                    setFieldValue(
                                      `formMaterials[${index}].extension`,
                                      calculateExtension({
                                        ...formMaterial,
                                        price: +e.target.value // This is passed because state won't update by time this calls
                                      })
                                    );
                                  }}
                                  onWheel={e => {
                                    (e.target as HTMLElement).blur();
                                  }}
                                  size='small'
                                  type='number'
                                  value={values.formMaterials[index].price || ''}
                                />
                              </Grid>

                              <Grid item xs={12} sm={1}>
                                <TextField
                                  disabled={!isFormEditable}
                                  error={Boolean(
                                    getIn(touched, `formMaterials[${index}].per`) &&
                                      getIn(errors, `formMaterials[${index}].per`)
                                  )}
                                  fullWidth
                                  helperText={
                                    getIn(touched, `formMaterials[${index}].per`) &&
                                    getIn(errors, `formMaterials[${index}].per`)
                                  }
                                  label='Per'
                                  name={`formMaterials[${index}].per`}
                                  onBlur={handleBlur}
                                  onChange={e => {
                                    setFieldValue(`formMaterials[${index}].per`, e.target.value);
                                    setFieldValue(
                                      `formMaterials[${index}].extension`,
                                      calculateExtension({
                                        ...formMaterial,
                                        per: e.target.value
                                      })
                                    );
                                  }}
                                  select
                                  size='small'
                                  value={values.formMaterials[index].per || ''}
                                >
                                  <MenuItem value={undefined} />

                                  <MenuItem value='M'>M</MenuItem>

                                  <MenuItem value='C'>C</MenuItem>

                                  <MenuItem value='E'>E</MenuItem>
                                </TextField>
                              </Grid>

                              <Grid item xs={12} sm={2}>
                                <TextField
                                  disabled
                                  fullWidth
                                  InputLabelProps={{ shrink: true }}
                                  InputProps={{
                                    startAdornment: <InputAdornment position='start'>$</InputAdornment>
                                  }}
                                  label='Extension'
                                  size='small'
                                  value={values.formMaterials[index].extension?.toFixed(2) ?? ''}
                                />
                              </Grid>
                            </>
                          )}

                          {index > 0 && (
                            <Grid item>
                              <Button
                                disabled={!isFormEditable}
                                variant='contained'
                                color='inherit'
                                onClick={() =>
                                  setFieldValue(
                                    'formMaterials',
                                    values.formMaterials.filter((_, i) => index !== i)
                                  )
                                }
                              >
                                <Clear fontSize='small' /> DELETE
                              </Button>
                            </Grid>
                          )}
                        </Grid>
                      ))}
                    </Grid>

                    <Grid item xs={12} container spacing={2} alignItems='center'>
                      <Grid item>
                        <Button
                          disabled={!isFormEditable}
                          variant='contained'
                          color='inherit'
                          onClick={() => setFieldValue('formMaterials', [...values.formMaterials, formMaterialInit])}
                        >
                          <Add fontSize='small' /> ADD MORE MATERIAL
                        </Button>
                      </Grid>

                      {values.formStatusId && values.formStatusId > FormStatuses.Draft && (
                        <Grid item xs={12} container spacing={2} alignItems='center'>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              disabled
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                              InputProps={{
                                startAdornment: <InputAdornment position='start'>$</InputAdornment>
                              }}
                              label='Total'
                              size='small'
                              value={
                                values.formMaterials
                                  ?.map(material => material.extension ?? 0)
                                  ?.reduce((prev, next) => (prev ?? 0) + (next ?? 0), 0)
                                  ?.toFixed(2) ?? '0'
                              }
                            />
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <TextField
                              disabled={!isFormEditable}
                              error={Boolean(touched.invoiceNumber && errors.invoiceNumber)}
                              fullWidth
                              helperText={touched.invoiceNumber && errors.invoiceNumber}
                              label='Invoice #'
                              name='invoiceNumber'
                              onBlur={handleBlur}
                              onChange={handleChange}
                              size='small'
                              value={values.invoiceNumber || ''}
                            />
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                    {
                      // #endregion
                    }

                    {
                      // #region Subcontracts
                    }
                    {values.formStatusId && values.formStatusId > FormStatuses.Draft && (
                      <Grid item xs={12} container spacing={2} alignItems='center'>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>

                        <Grid item xs={12}>
                          <Typography fontWeight='bold'>SUBCONTRACTS</Typography>
                        </Grid>

                        {values.formSubcontracts?.map((_formSubcontract, index) => (
                          <>
                            <Grid item xs={12} sm={2}>
                              <TextField
                                disabled={!isFormEditable}
                                error={Boolean(
                                  getIn(touched, `formSubcontracts[${index}].poNumber`) &&
                                    getIn(errors, `formSubcontracts[${index}].poNumber`)
                                )}
                                fullWidth
                                helperText={
                                  getIn(touched, `formSubcontracts[${index}].poNumber`) &&
                                  getIn(errors, `formSubcontracts[${index}].poNumber`)
                                }
                                label='PO #'
                                name={`formSubcontracts[${index}].poNumber`}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                size='small'
                                value={values.formSubcontracts[index].poNumber || ''}
                              />
                            </Grid>

                            <Grid item xs={12} sm={2}>
                              <TextField
                                disabled={!isFormEditable}
                                error={Boolean(
                                  getIn(touched, `formSubcontracts[${index}].subcontractor`) &&
                                    getIn(errors, `formSubcontracts[${index}].subcontractor`)
                                )}
                                fullWidth
                                helperText={
                                  getIn(touched, `formSubcontracts[${index}].subcontractor`) &&
                                  getIn(errors, `formSubcontracts[${index}].subcontractor`)
                                }
                                label='Subcontractor'
                                name={`formSubcontracts[${index}].subcontractor`}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                size='small'
                                value={values.formSubcontracts[index].subcontractor}
                              />
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <TextField
                                disabled={!isFormEditable}
                                error={Boolean(
                                  getIn(touched, `formSubcontracts[${index}].descriptionOfWork`) &&
                                    getIn(errors, `formSubcontracts[${index}].descriptionOfWork`)
                                )}
                                fullWidth
                                helperText={
                                  getIn(touched, `formSubcontracts[${index}].descriptionOfWork`) &&
                                  getIn(errors, `formSubcontracts[${index}].descriptionOfWork`)
                                }
                                label='Description of Work'
                                name={`formSubcontracts[${index}].descriptionOfWork`}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                size='small'
                                value={values.formSubcontracts[index].descriptionOfWork}
                              />
                            </Grid>

                            <Grid item xs={12} sm={2}>
                              <TextField
                                disabled={!isFormEditable}
                                error={Boolean(
                                  getIn(touched, `formSubcontracts[${index}].invoiceNumber`) &&
                                    getIn(errors, `formSubcontracts[${index}].invoiceNumber`)
                                )}
                                fullWidth
                                helperText={
                                  getIn(touched, `formSubcontracts[${index}].invoiceNumber`) &&
                                  getIn(errors, `formSubcontracts[${index}].invoiceNumber`)
                                }
                                label='Invoice #'
                                name={`formSubcontracts[${index}].invoiceNumber`}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                size='small'
                                value={values.formSubcontracts[index].invoiceNumber}
                              />
                            </Grid>

                            <Grid item xs={12} sm={2}>
                              <TextField
                                disabled={!isFormEditable}
                                error={Boolean(
                                  getIn(touched, `formSubcontracts[${index}].totalCost`) &&
                                    getIn(errors, `formSubcontracts[${index}].totalCost`)
                                )}
                                fullWidth
                                helperText={
                                  getIn(touched, `formSubcontracts[${index}].totalCost`) &&
                                  getIn(errors, `formSubcontracts[${index}].totalCost`)
                                }
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ startAdornment: <InputAdornment position='start'>$</InputAdornment> }}
                                label='Total Cost'
                                name={`formSubcontracts[${index}].totalCost`}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                size='small'
                                value={values.formSubcontracts[index].totalCost}
                              />
                            </Grid>

                            <Grid item xs={12} sm={1}>
                              <Button
                                color='inherit'
                                disabled={!isFormEditable}
                                onClick={() => {
                                  setFieldValue(
                                    'formSubcontracts',
                                    values.formSubcontracts.filter((_, i) => i !== index)
                                  );
                                }}
                                startIcon={<Clear />}
                                variant='contained'
                              >
                                DELETE
                              </Button>
                            </Grid>
                          </>
                        ))}

                        <Grid item xs={12} container spacing={2} alignItems='center'>
                          <Grid item>
                            <Button
                              color='inherit'
                              disabled={!isFormEditable}
                              onClick={() =>
                                setFieldValue('formSubcontracts', [...(values.formSubcontracts ?? []), formSubcontractInit])
                              }
                              variant='contained'
                            >
                              <Add fontSize='small' /> ADD ADDITIONAL SUBCONTRACT
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    {
                      // #endregion
                    }

                    {
                      // #region Miscellaneous
                    }
                    {values.formStatusId && values.formStatusId > FormStatuses.Draft && (
                      <Grid item xs={12} container spacing={2} alignItems='center'>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>

                        <Grid item xs={12}>
                          <Typography fontWeight='bold'>MISCELLANEOUS</Typography>
                        </Grid>

                        {values.formMiscellaneous?.map((_formMiscellaneous, index) => (
                          <>
                            <Grid item xs={12} sm={3}>
                              <TextField
                                disabled={!isFormEditable}
                                error={Boolean(
                                  getIn(touched, `formMiscellaneous[${index}].description`) &&
                                    getIn(errors, `formMiscellaneous[${index}].description`)
                                )}
                                fullWidth
                                helperText={
                                  getIn(touched, `formMiscellaneous[${index}].description`) &&
                                  getIn(errors, `formMiscellaneous[${index}].description`)
                                }
                                label='Description'
                                name={`formMiscellaneous[${index}].description`}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                size='small'
                                value={values.formMiscellaneous[index].description || ''}
                              />
                            </Grid>

                            <Grid item xs={12} sm={1}>
                              <TextField
                                disabled={!isFormEditable}
                                error={Boolean(
                                  getIn(touched, `formMiscellaneous[${index}].quantity`) &&
                                    getIn(errors, `formMiscellaneous[${index}].quantity`)
                                )}
                                fullWidth
                                helperText={
                                  getIn(touched, `formMiscellaneous[${index}].quantity`) &&
                                  getIn(errors, `formMiscellaneous[${index}].quantity`)
                                }
                                label='Quantity'
                                name={`formMiscellaneous[${index}].quantity`}
                                onBlur={handleBlur}
                                onChange={e => {
                                  handleChange(e);
                                  setFieldValue(
                                    `formMiscellaneous[${index}].totalCost`,
                                    calculateMiscellaneousTotal({
                                      ...values.formMiscellaneous[index],
                                      quantity: +e.target.value ?? 0
                                    })
                                  );
                                }}
                                size='small'
                                type='number'
                                value={values.formMiscellaneous[index].quantity || ''}
                              />
                            </Grid>

                            <Grid item xs={12} sm={1}>
                              <TextField
                                disabled={!isFormEditable}
                                error={Boolean(
                                  getIn(touched, `formMiscellaneous[${index}].markupPercentage`) &&
                                    getIn(errors, `formMiscellaneous[${index}].markupPercentage`)
                                )}
                                fullWidth
                                helperText={
                                  getIn(touched, `formMiscellaneous[${index}].markupPercentage`) &&
                                  getIn(errors, `formMiscellaneous[${index}].markupPercentage`)
                                }
                                InputProps={{ endAdornment: <InputAdornment position='end'>%</InputAdornment> }}
                                label='MU'
                                name={`formMiscellaneous[${index}].markupPercentage`}
                                onBlur={handleBlur}
                                onChange={e => {
                                  handleChange(e);
                                  setFieldValue(
                                    `formMiscellaneous[${index}].totalCost`,
                                    calculateMiscellaneousTotal({
                                      ...values.formMiscellaneous[index],
                                      markupPercentage: +e.target.value ?? 0
                                    })
                                  );
                                }}
                                size='small'
                                type='number'
                                value={values.formMiscellaneous[index].markupPercentage}
                              />
                            </Grid>

                            <Grid item xs={12} sm={1}>
                              <TextField
                                disabled={!isFormEditable}
                                error={Boolean(
                                  getIn(touched, `formMiscellaneous[${index}].overheadProfitPercentage`) &&
                                    getIn(errors, `formMiscellaneous[${index}].overheadProfitPercentage`)
                                )}
                                fullWidth
                                helperText={
                                  getIn(touched, `formMiscellaneous[${index}].overheadProfitPercentage`) &&
                                  getIn(errors, `formMiscellaneous[${index}].overheadProfitPercentage`)
                                }
                                InputProps={{ endAdornment: <InputAdornment position='end'>%</InputAdornment> }}
                                label='O&P'
                                name={`formMiscellaneous[${index}].overheadProfitPercentage`}
                                onBlur={handleBlur}
                                onChange={e => {
                                  handleChange(e);
                                  setFieldValue(
                                    `formMiscellaneous[${index}].totalCost`,
                                    calculateMiscellaneousTotal({
                                      ...values.formMiscellaneous[index],
                                      overheadProfitPercentage: +e.target.value ?? 0
                                    })
                                  );
                                }}
                                size='small'
                                value={values.formMiscellaneous[index].overheadProfitPercentage}
                              />
                            </Grid>

                            <Grid item xs={12} sm={1}>
                              <TextField
                                disabled={!isFormEditable}
                                error={Boolean(
                                  getIn(touched, `formMiscellaneous[${index}].unitCost`) &&
                                    getIn(errors, `formMiscellaneous[${index}].unitCost`)
                                )}
                                fullWidth
                                helperText={
                                  getIn(touched, `formMiscellaneous[${index}].unitCost`) &&
                                  getIn(errors, `formMiscellaneous[${index}].unitCost`)
                                }
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ startAdornment: <InputAdornment position='start'>$</InputAdornment> }}
                                label='Unit Cost'
                                name={`formMiscellaneous[${index}].unitCost`}
                                onBlur={handleBlur}
                                onChange={e => {
                                  handleChange(e);
                                  setFieldValue(
                                    `formMiscellaneous[${index}].totalCost`,
                                    calculateMiscellaneousTotal({
                                      ...values.formMiscellaneous[index],
                                      unitCost: +e.target.value ?? 0
                                    })
                                  );
                                }}
                                size='small'
                                value={values.formMiscellaneous[index].unitCost}
                              />
                            </Grid>

                            <Grid item xs={12} sm={1}>
                              <TextField
                                disabled={!isFormEditable}
                                error={Boolean(
                                  getIn(touched, `formMiscellaneous[${index}].per`) &&
                                    getIn(errors, `formMiscellaneous[${index}].per`)
                                )}
                                fullWidth
                                helperText={
                                  getIn(touched, `formMiscellaneous[${index}].per`) &&
                                  getIn(errors, `formMiscellaneous[${index}].per`)
                                }
                                InputLabelProps={{ shrink: true }}
                                label='U/M'
                                name={`formMiscellaneous[${index}].per`}
                                onBlur={handleBlur}
                                onChange={e => {
                                  handleChange(e);
                                  setFieldValue(
                                    `formMiscellaneous[${index}].totalCost`,
                                    calculateMiscellaneousTotal({ ...values.formMiscellaneous[index], per: e.target.value })
                                  );
                                }}
                                select
                                size='small'
                                value={values.formMiscellaneous[index].per}
                              >
                                <MenuItem value={undefined} />
                                <MenuItem value='M'>M</MenuItem>
                                <MenuItem value='C'>C</MenuItem>
                                <MenuItem value='E'>E</MenuItem>s
                              </TextField>
                            </Grid>

                            <Grid item xs={12} sm={2}>
                              <TextField
                                disabled
                                fullWidth
                                size='small'
                                label='Total Cost'
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                  startAdornment: <InputAdornment position='start'>$</InputAdornment>
                                }}
                                value={values.formMiscellaneous[index].totalCost}
                              />
                            </Grid>

                            <Grid item xs={12} sm={1}>
                              <Button
                                color='inherit'
                                disabled={!isFormEditable}
                                onClick={() => {
                                  setFieldValue(
                                    'formMiscellaneous',
                                    values.formMiscellaneous.filter((_, i) => i !== index)
                                  );
                                }}
                                startIcon={<Clear />}
                                variant='contained'
                              >
                                DELETE
                              </Button>
                            </Grid>
                          </>
                        ))}

                        <Grid item xs={12} container spacing={2} alignItems='center'>
                          <Grid item>
                            <Button
                              color='inherit'
                              disabled={!isFormEditable}
                              onClick={() =>
                                setFieldValue('formMiscellaneous', [...(values.formMiscellaneous ?? []), formMiscellaneousInit])
                              }
                              variant='contained'
                            >
                              <Add fontSize='small' /> ADD MORE MISCELLANEOUS
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    {
                      // #endregion
                    }

                    <Grid item xs={12} container spacing={2} alignItems='center' justifyContent='space-between'>
                      <Grid item xs={12} sm={6} container spacing={2} alignItems='center' justifyContent='flex-start'>
                        <Grid item xs={12} sm>
                          <Typography variant='body2'>SUPERVISOR:</Typography>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                          <SignatureCanvas
                            ref={supervisorSignatureCanvas}
                            onEnd={_ => {
                              setFieldValue('supervisorSignatureBase64', supervisorSignatureCanvas.current.toDataURL());
                            }}
                            penColor='black'
                            canvasProps={{
                              // className: 'sigCanvas1',
                              style: {
                                backgroundColor: '#fff',
                                border: '1px solid #444549',
                                cursor: !isFormEditable ? 'not-allowed' : 'default',
                                height: '175px',
                                width: '100%'
                              }
                            }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Button
                            variant='text'
                            fullWidth
                            color='inherit'
                            disabled={!values.supervisorSignatureBase64 || !isFormEditable}
                            onClick={() => {
                              if (window.confirm("Are you sure you want to clear the supervisor's signature?")) {
                                supervisorSignatureCanvas.current.clear();
                                setFieldValue('supervisorSignatureBase64', undefined);
                              }
                            }}
                          >
                            <Clear /> CLEAR SUPERVISOR SIGNATURE
                          </Button>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                          <TextField
                            disabled={!isFormEditable}
                            error={Boolean(touched.supervisorPrintName && errors.supervisorPrintName)}
                            fullWidth
                            helperText={touched.supervisorPrintName && errors.supervisorPrintName}
                            label='Print Name'
                            name='supervisorPrintName'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            size='small'
                            value={values.supervisorPrintName || ''}
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={12} sm={6} container spacing={2} alignItems='center' justifyContent='flex-start'>
                        <Grid item xs={12}>
                          <Typography variant='body2'>CUSTOMER:</Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <SignatureCanvas
                            ref={customerSignatureCanvas}
                            onEnd={() => {
                              setFieldValue('customerSignatureBase64', customerSignatureCanvas.current.toDataURL());
                            }}
                            penColor='black'
                            canvasProps={{
                              // className: 'sigCanvas2',
                              style: {
                                backgroundColor: '#fff',
                                border: '1px solid #444549',
                                cursor: !isFormEditable ? 'not-allowed' : 'default',
                                height: '175px',
                                width: '100%'
                              }
                            }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Button
                            variant='text'
                            fullWidth
                            color='inherit'
                            disabled={!values.customerSignatureBase64 || !isFormEditable}
                            onClick={() => {
                              if (window.confirm("Are you sure you want to clear the customer's signature?")) {
                                customerSignatureCanvas.current.clear();
                                setFieldValue('customerSignatureBase64', undefined);
                              }
                            }}
                          >
                            <Clear /> CLEAR CUSTOMER SIGNATURE
                          </Button>
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            disabled={!isFormEditable}
                            error={Boolean(touched.customerPrintName && errors.customerPrintName)}
                            fullWidth
                            helperText={touched.customerPrintName && errors.customerPrintName}
                            label='Print Name'
                            name='customerPrintName'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            size='small'
                            value={values.customerPrintName || ''}
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          disabled={!isFormEditable}
                          error={Boolean(
                            touched.explanationForAbsenceOfCustomerSignature && errors.explanationForAbsenceOfCustomerSignature
                          )}
                          fullWidth
                          helperText={
                            touched.explanationForAbsenceOfCustomerSignature && errors.explanationForAbsenceOfCustomerSignature
                          }
                          label='Explanation For Absence Of Customer Signature'
                          minRows={4}
                          multiline
                          name='explanationForAbsenceOfCustomerSignature'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          size='small'
                          value={values.explanationForAbsenceOfCustomerSignature || ''}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Typography>
                          <b>ATTACHMENTS</b>
                        </Typography>

                        <Typography variant='body1'>Use the dropzone below to upload any necessary files.</Typography>

                        <ul>
                          <li>
                            <Typography variant='body1'>
                              <b>Accepted File Types:</b> .csv, .docx, .gif, .jpeg, .pdf, .png, .pptx, .xlsx
                            </Typography>
                          </li>

                          <li>
                            <Typography variant='body1'>
                              <b>Maximum File Size:</b> 20MB per file
                            </Typography>
                          </li>
                        </ul>
                      </Grid>

                      <Grid item xs={12}>
                        <Dropzone
                          disabled={!isFormEditable}
                          // https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
                          accept={{
                            'text/csv': ['.csv'],
                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
                            'image/gif': ['.gif'],
                            'image/jpeg': ['.jpeg', '.jpg'],
                            'application/pdf': ['.pdf'],
                            'image/png': ['.png'],
                            'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
                          }}
                          onDrop={files => {
                            const newFiles = files
                              .filter(
                                file => !values.formAttachments?.some(formAttachment => formAttachment.fileName === file.name)
                              )
                              .map(file => ({
                                fileName: file.name,
                                attachment: file
                              }));

                            setFieldValue('formAttachments', [...(values.formAttachments ?? []), ...newFiles]);
                          }}
                          multiple
                        >
                          {({ getRootProps, getInputProps }) => (
                            <ButtonBase
                              {...getRootProps()}
                              disabled={!isFormEditable}
                              sx={{
                                border: '2px dashed #cecece;',
                                color: 'inherit;',
                                display: 'flex;',
                                flexDirection: 'column;',
                                padding: '16px;',
                                width: '100%;'
                              }}
                            >
                              <CloudUpload color='disabled' fontSize='large' />

                              <input {...getInputProps()} />

                              <Typography variant='body1'>
                                Drag and drop or{' '}
                                <Typography
                                  variant='body1'
                                  display='inline'
                                  sx={{ color: '#1976d2;', textDecoration: 'underline;' }}
                                >
                                  browse
                                </Typography>
                              </Typography>
                            </ButtonBase>
                          )}
                        </Dropzone>
                      </Grid>

                      {values.formAttachments?.map((formAttachment, index) => (
                        <Grid item xs={12} container alignItems='center' justifyContent='space-between'>
                          <Grid item>
                            {formAttachment.id ? (
                              <Link
                                onClick={() => {
                                  api
                                    .get('/forms/attachments/' + formAttachment.fileName)
                                    .then(({ data }) => {
                                      const buffer = base64ToArrayBuffer(data);
                                      const file = new Blob([buffer]);
                                      const anchor = document.createElement('a');
                                      anchor.href = window.URL.createObjectURL(file);
                                      anchor.download = formAttachment.fileName!;
                                      document.body.appendChild(anchor);
                                      anchor.click();
                                      document.body.removeChild(anchor);
                                    })
                                    .finally(() => {});
                                }}
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  flexWrap: 'wrap',
                                  cursor: 'pointer'
                                }}
                              >
                                <Download />

                                <Typography variant='body2' display='inline'>
                                  {formAttachment.fileName}
                                </Typography>
                              </Link>
                            ) : (
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  flexWrap: 'wrap'
                                }}
                              >
                                <Upload />

                                <Typography variant='body2' display='inline'>
                                  {formAttachment.fileName}
                                </Typography>
                              </div>
                            )}
                          </Grid>

                          <Grid item>
                            <IconButton
                              size='small'
                              onClick={() => {
                                if (window.confirm('Are you sure you want to delete ' + formAttachment.fileName + '?'))
                                  setFieldValue(
                                    'formAttachments',
                                    values.formAttachments.filter((_, i) => i !== index)
                                  );
                              }}
                            >
                              <Delete fontSize='small' />
                            </IconButton>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>

                  <BottomFormBar
                    isEditable={isFormEditable}
                    isSubmitting={
                      isCreatingForm ||
                      isGettingForm ||
                      isProcessingForm ||
                      isPrintingForm ||
                      isSubmittingForm ||
                      isUpdatingForm
                    }
                    onBack={() => {
                      navigate(-1);
                    }}
                    onCancel={() => {
                      navigate(-1);
                    }}
                    onProcess={() => {
                      validateForm().then(formikErrors => {
                        const keys = Object.keys(formikErrors);

                        if (keys.length === 0) {
                          processForm(values);
                        } else {
                          setTouched(setNestedObjectValues(formikErrors, true));
                        }
                      });
                    }}
                    onSave={() => {
                      validateForm().then(formikErrors => {
                        const method = values.formNumber ? updateForm : createForm;

                        if (
                          // When saving a Draft, there will only be a companyId, jobNumber, customer, and date.
                          (values.formStatusId === FormStatuses.Draft &&
                            !(
                              formikErrors['companyId'] &&
                              formikErrors['customer'] &&
                              formikErrors['date'] &&
                              formikErrors['jobNumber']
                            )) ||
                          Object.keys(formikErrors).length === 0
                        ) {
                          method(values);
                        } else {
                          setTouched(setNestedObjectValues(formikErrors, true));
                        }
                      });
                    }}
                    onSubmit={() => {
                      console.log('onSubmit');
                      console.log(touched);
                      console.log(errors);
                      validateForm().then(formikErrors => {
                        const keys = Object.keys(formikErrors);

                        if (keys.length === 0) {
                          submitForm(values);
                        } else {
                          setTouched(setNestedObjectValues(formikErrors, true));
                        }
                      });
                    }}
                    save
                    process={IS_ADMIN && values.formStatusId === FormStatuses.Submitted}
                    submit={values.formStatusId === FormStatuses.Draft}
                  />

                  <UnsavedChangesPrompt when={dirty} />
                </form>
              );
            }}
          </Formik>
        </CardContent>
      </Card>
    </Page>
  );
}
