import { useMsal } from '@azure/msal-react';
import * as api from 'api';
import { scopes } from 'app/App';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useDebouncedEffect } from 'utils';

export const AuthContext = createContext<{
  IS_ADMIN: boolean;
  IS_INVOICE_ADMIN: boolean;
  IS_USER: boolean;
  USER_ID: string;
} | null>(null);

export default function AuthProvider({ children }: React.PropsWithChildren) {
  const { accounts, instance, inProgress } = useMsal();

  const [IS_ADMIN, SET_IS_ADMIN] = useState<boolean>(false);
  const [IS_INVOICE_ADMIN, SET_IS_INVOICE_ADMIN] = useState<boolean>(false);
  const [IS_USER, SET_IS_USER] = useState<boolean>(false);
  const [USER_ID, SET_USER_ID] = useState<string>('');

  const state = useMemo(
    () => ({ IS_ADMIN, IS_INVOICE_ADMIN, IS_USER, USER_ID }),
    [IS_ADMIN, IS_INVOICE_ADMIN, IS_USER, USER_ID]
  );

  useDebouncedEffect(
    () => {
      api.getSession().then(response => {
        const isInvoiceAdmin = response.value.roles?.some(role => role === 'FIELD_REPORT_INVOICE_ADMIN') ?? false;
        const isAdmin = isInvoiceAdmin || (response.value.roles?.some(role => role === 'FIELD_REPORT_ADMIN') ?? false);
        const isUser = isAdmin || (response.value.roles?.some(role => role === 'FIELD_REPORT_USER') ?? false);

        SET_USER_ID(response.value.id);
        SET_IS_ADMIN(isAdmin);
        SET_IS_INVOICE_ADMIN(isInvoiceAdmin);
        SET_IS_USER(isUser);
      });
    },
    300,
    []
  );

  useEffect(() => {
    if (accounts.length === 0 && inProgress !== 'login') {
      instance.loginRedirect({
        scopes
      });
    }
  }, [accounts, inProgress, instance]);

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
}

export const useAuth = () => useContext(AuthContext)!;
