import { Button, Grid, Link, TableCell, TableRow, TextField, Typography } from '@mui/material';
import { getJobs } from 'api';
import { Page, Table } from 'components';
import { usePersistentListParameters } from 'context';
import { JobDto, JobsParameters } from 'dtos';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'utils';

export default function Customers() {
  const [isGettingJobs, setIsGettingJobs] = useState<boolean>(false);
  const [jobs, setJobs] = useState<JobDto[]>([]);
  const [totalJobs, setTotalJobs] = useState<number>(0);
  const navigate = useNavigate();
  const persistentListParameters = usePersistentListParameters();

  const onFilter = useDebounce((parameters: JobsParameters) => {
    setIsGettingJobs(true);
    getJobs(parameters)
      .then(({ totalCount, value }) => {
        setTotalJobs(totalCount!);
        setJobs(value);
      })
      .finally(() => {
        persistentListParameters.setState('jobs', parameters);
        setIsGettingJobs(false);
      });
  }, 100);

  useEffect(() => {
    onFilter(persistentListParameters.state['jobs']);
  }, [
    onFilter,
    persistentListParameters.state['jobs'].page,
    persistentListParameters.state['jobs'].pageSize,
    persistentListParameters.state['jobs'].sortBy,
    persistentListParameters.state['jobs'].sortDirection
  ]);

  const onReset = () => {
    onFilter(persistentListParameters.initialState['jobs']);
  };

  const onSortChange = (propertyName: string, sortDirection: 'asc' | 'desc') => {
    persistentListParameters.setState('jobs', {
      ...persistentListParameters.state['jobs'],
      sortBy: propertyName,
      sortDirection
    });
  };

  const onChangePage = (page: number) => {
    persistentListParameters.setState('jobs', {
      ...persistentListParameters.state['jobs'],
      page
    });
  };

  const onChangePageSize = (pageSize: number) => {
    persistentListParameters.setState('jobs', {
      ...persistentListParameters.state['jobs'],
      pageSize
    });
  };

  return (
    <Page title='Jobs' isLoading={isGettingJobs}>
      <Table
        actions={
          <Grid container justifyContent='space-between'>
            <Grid item xs={12} sm={6} container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label='Filter text'
                  onChange={e => {
                    persistentListParameters.setState('jobs', {
                      ...persistentListParameters.state['jobs'],
                      filterText: e.target.value
                    });
                  }}
                  size='small'
                  value={persistentListParameters.state['jobs'].filterText}
                />
              </Grid>

              <Grid item>
                <Button
                  color='inherit'
                  onClick={() => {
                    onFilter(persistentListParameters.state['jobs']);
                  }}
                  variant='contained'
                >
                  FILTER
                </Button>
              </Grid>

              <Grid item>
                <Button color='inherit' onClick={onReset} variant='contained'>
                  RESET
                </Button>
              </Grid>
            </Grid>

            <Grid item xs={12} sm='auto' container justifyContent='flex-end'>
              <Grid item>
                <Button
                  color='inherit'
                  onClick={() => {
                    navigate('/jobs/new');
                  }}
                  variant='contained'
                >
                  ADD NEW
                </Button>
              </Grid>
            </Grid>
          </Grid>
        }
        columns={[
          { label: 'Job #', propertyName: 'jobNumber' },
          { label: 'Customer', propertyName: 'customer.companyName' },
          { label: 'Description', propertyName: 'description' }
        ]}
        onChangePage={onChangePage}
        onChangePageSize={onChangePageSize}
        onSortChange={onSortChange}
        page={persistentListParameters.state['jobs'].page!}
        pageSize={persistentListParameters.state['jobs'].pageSize!}
        sortBy={persistentListParameters.state['jobs'].sortBy}
        sortDirection={persistentListParameters.state['jobs'].sortDirection}
        totalCount={totalJobs}
      >
        {jobs.map(job => (
          <TableRow key={job.id}>
            <TableCell>
              <Link
                onClick={() => {
                  navigate('/jobs/' + job.jobNumber);
                }}
                sx={{ cursor: 'pointer', textDecoration: 'none' }}
              >
                {job.jobNumber}
              </Link>
            </TableCell>

            <TableCell>
              {job.jobContractDetail.map(jobContractDetail => (
                <div>
                  {jobContractDetail.contractNumber} - {jobContractDetail.customer.companyName}
                </div>
              ))}
            </TableCell>

            <TableCell>
              {job.jobContractDetail.map(jobContractDetail => (
                <div>{jobContractDetail.description}</div>
              ))}
            </TableCell>

            {/* <TableCell>{job.customerCompanyName}</TableCell> */}

            {/* <TableCell>{job.description}</TableCell> */}
          </TableRow>
        ))}
      </Table>
    </Page>
  );
}
