import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup
} from '@mui/material';
import { batchExport } from 'api';
import {
  ResponsiveDialog,
  ResponsiveDialogActions,
  ResponsiveDialogContent,
  ResponsiveDialogTitle
} from 'components';
import { BatchExportTypes } from '../constants';
import { FormDto } from 'dtos';
import { useEffect, useState } from 'react';
import { api } from 'app/api';
import { base64ToArrayBuffer, pad2 } from 'utils';
import { enqueueSnackbar } from 'notistack';

export type BatchExportDialogProps = {
  forms: FormDto[];
  onClose: () => void;
  open: boolean;
};

const BatchExportDialog = ({ open, onClose, forms }: BatchExportDialogProps) => {
  const [isBatchExporting, setIsBatchExporting] = useState<boolean>(false);
  const [batchExportType, setBatchExportType] = useState<BatchExportTypes>(
    BatchExportTypes.SingleFileExport
  );

  useEffect(() => {
    setBatchExportType(BatchExportTypes.SingleFileExport);
  }, [forms]);

  const onSendExport = () => {
    setIsBatchExporting(true);
    const today = new Date();
    // yyyymmddhhmmss timestamp
    const timestamp =
      today.getFullYear() +
      pad2(today.getMonth() + 1) +
      pad2(today.getDate()) +
      pad2(today.getHours()) +
      pad2(today.getMinutes()) +
      pad2(today.getSeconds());

    if (batchExportType === BatchExportTypes.SingleFileExport) {
      const requestUrl = `telerik-reports/export?formIds=${forms
        .map(form => form.id?.toString())
        .join(',')}`;

      api
        .get(requestUrl)
        .then(({ data }) => {
          const buffer = base64ToArrayBuffer(data);
          const file = new Blob([buffer], { type: 'application/pdf' });

          const anchor = document.createElement('a');
          anchor.href = window.URL.createObjectURL(file);
          anchor.download = `BatchExport.${forms
            .map(form => form.formNumber?.toString())
            .join('.')}.${timestamp}.pdf`;
          document.body.appendChild(anchor);
          anchor.click();
          document.body.removeChild(anchor);
        })
        .finally(() => {
          setIsBatchExporting(false);
        });
    } else {
      forms.forEach(form => {
        const requestUrl = `telerik-reports/export?formIds=${form.id}`;

        api
          .get(requestUrl)
          .then(({ data }) => {
            const buffer = base64ToArrayBuffer(data);
            const file = new Blob([buffer], { type: 'application/pdf' });

            const anchor = document.createElement('a');
            anchor.href = window.URL.createObjectURL(file);
            anchor.download = `BatchExport.${form.formNumber}.${timestamp}.pdf`;
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
          })
          .catch(({ message }) => {
            enqueueSnackbar(`Error downloading Form #${form.formNumber}: ${message}`, {
              variant: 'error'
            });
          })
          .finally(() => {
            if (forms.indexOf(form) === forms.length - 1) {
              setIsBatchExporting(false);
            }
          });
      });
    }
  };

  return (
    <ResponsiveDialog open={open}>
      <ResponsiveDialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ResponsiveDialogTitle>Batch Export</ResponsiveDialogTitle>
          </Grid>

          <Grid item xs={12}>
            <FormControl>
              <RadioGroup
                value={batchExport}
                onChange={e => setBatchExportType(e.target.value as BatchExportTypes)}
              >
                <FormControlLabel
                  checked={batchExportType === BatchExportTypes.SingleFileExport}
                  value={BatchExportTypes.SingleFileExport}
                  control={<Radio />}
                  label='Single File Export'
                  disabled={isBatchExporting}
                />

                <FormControlLabel
                  checked={batchExportType === BatchExportTypes.MultiFileExport}
                  value={BatchExportTypes.MultiFileExport}
                  control={<Radio />}
                  label='Multi-File Export'
                  disabled={isBatchExporting || !(forms.length > 1)}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </ResponsiveDialogContent>

      <ResponsiveDialogActions>
        <Button color='error' onClick={onClose} disabled={isBatchExporting}>
          CANCEL
        </Button>

        <Button color='primary' onClick={onSendExport} disabled={isBatchExporting}>
          {isBatchExporting ? 'LOADING...' : 'EXPORT'}
        </Button>
      </ResponsiveDialogActions>
    </ResponsiveDialog>
  );
};

export default BatchExportDialog;
