import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  InputAdornment,
  TextField,
  Typography
} from '@mui/material';
import {
  createInvoice,
  deleteInvoice,
  getForms,
  getInvoiceByInvoiceNumber,
  getJobByJobNumber,
  getJobs,
  previewFieldReports,
  previewInvoice,
  previewReportSummary,
  updateInvoice
} from 'api';
import { BottomFormBar, Page, UnsavedChangesPrompt } from 'components';
import { FormDto, InvoiceDto, JobContractDto, JobDto, invoiceInit, invoiceValidationSchema } from 'dtos';
import { Formik, getIn } from 'formik';
import moment from 'moment';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDebounce } from 'utils';

export default function AddEditInvoice() {
  const [forms, setForms] = useState<FormDto[]>([]);
  const [initialValues, setInitialValues] = useState<InvoiceDto>(invoiceInit);
  const [isCreatingInvoice, setIsCreatingInvoice] = useState<boolean>(false);
  const [isFinalizingInvoice, setIsFinalizingInvoice] = useState<boolean>(false);
  const [isGettingInvoice, setIsGettingInvoice] = useState<boolean>(false);
  const [isGettingJobContracts, setIsGettingJobContracts] = useState<boolean>(false);
  const [isGettingJobs, setIsGettingJobs] = useState<boolean>(false);
  const [isUpdatingInvoice, setIsUpdatingInvoice] = useState<boolean>(false);
  const [jobContracts, setJobContracts] = useState<JobContractDto[]>([]);
  const [isGettingForms, setIsGettingForms] = useState<boolean>(false);
  const [isReversingInvoice, setIsReversingInvoice] = useState<boolean>(false);
  const [jobs, setJobs] = useState<JobDto[]>([]);
  const { invoiceNumber } = useParams();
  const [isPreviewingInvoice, setIsPreviewingInvoice] = useState<boolean>(false);
  const [isPreviewingReportSummary, setIsPreviewingReportSummary] = useState<boolean>(false);
  const [isPreviewingFieldReports, setIsPreviewingFieldReports] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location && location.state && location.state.invoiceForms && location.state.invoiceForms.length > 0) {
      let result = {
        ...initialValues,
        formIds: location.state.invoiceForms.map((form: FormDto) => form.id)
      };

      if (location.state.job && location.state.job.jobNumber) {
        getJobByJobNumber(location.state.job.jobNumber)
          .then(response => {
            result.job = response.value;
          })
          .finally(() => {
            setInitialValues(result);
          });
      } else {
        setInitialValues(result);
      }
    }
  }, [location]);

  useEffect(() => {
    if (invoiceNumber && invoiceNumber.toLowerCase() !== 'new' && invoiceNumber !== 'undefined') {
      setIsGettingInvoice(true);
      getInvoiceByInvoiceNumber(invoiceNumber)
        .then(({ value }) => {
          setInitialValues(value);
        })
        .finally(() => {
          setIsGettingInvoice(false);
        });
    }
  }, [invoiceNumber]);

  useEffect(() => {
    if (initialValues.job && initialValues.job.id) {
      // This will populate FIELD REPORTS when editing or viewing.
      onJobChange(initialValues.job.id);
    }
  }, [initialValues.job]);

  const onJobChange = (jobId?: number) => {
    if (jobId) {
      setIsGettingForms(true);
      getForms({
        invoiceable: true,
        invoiceId: initialValues.id,
        jobId,
        page: 0,
        pageSize: 1000,
        sortBy: 'formNumber',
        sortDirection: 'asc'
      })
        .then(response => {
          setForms(response.value);
        })
        .finally(() => {
          setIsGettingForms(false);
        });
    } else {
      setForms([]);
    }
  };

  const getJobsAutocomplete = useDebounce((filterText: string) => {
    setIsGettingJobs(true);
    getJobs({ page: 0, pageSize: 25, filterText, sortBy: 'jobNumber', sortDirection: 'asc' })
      .then(({ value }) => {
        setJobs(value);
      })
      .finally(() => {
        setIsGettingJobs(false);
      });
  }, 300);

  return (
    <Page
      title={initialValues.id ? (initialValues.isFinalized ? 'View Invoice' : 'Edit Invoice') : 'Add Invoice'}
      isLoading={
        isGettingInvoice ||
        isCreatingInvoice ||
        isUpdatingInvoice ||
        isFinalizingInvoice ||
        isPreviewingReportSummary ||
        isPreviewingFieldReports ||
        isPreviewingInvoice ||
        isReversingInvoice
      }
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values, formikHelpers) => {
          if (values.id) {
            setIsUpdatingInvoice(true);
            updateInvoice(values)
              .then(response => {
                setInitialValues(response.value);
                enqueueSnackbar(`Invoice #${values.invoiceNumber} updated successfully!`, { variant: 'success' });

                // This is also in update because users can change the invoiceNumber which is part of the route
                setTimeout(() => {
                  navigate('/invoicing/' + response.value.invoiceNumber, { replace: true });
                }, 250);
              })
              .finally(() => {
                setIsUpdatingInvoice(false);
              });
          } else {
            setIsCreatingInvoice(true);
            createInvoice(values)
              .then(response => {
                setInitialValues(response.value);
                enqueueSnackbar(`Invoice #${values.invoiceNumber} created successfully!`, { variant: 'success' });

                // Timeout is required to prevent the unsaved changes prompt from displaying
                setTimeout(() => {
                  navigate('/invoicing/' + response.value.invoiceNumber, { replace: true });
                }, 250);
              })
              .finally(() => {
                setIsCreatingInvoice(false);
              });
          }

          formikHelpers.setSubmitting(false);
        }}
        validateOnBlur
        validateOnChange
        validationSchema={invoiceValidationSchema}
      >
        {({ dirty, errors, handleBlur, handleChange, handleSubmit, setFieldValue, touched, values }) => {
          return (
            <>
              <UnsavedChangesPrompt when={dirty} />

              <form onSubmit={handleSubmit}>
                <Card>
                  <CardContent>
                    <Grid container spacing={2} alignItems='center'>
                      <Grid item xs={12} container spacing={2} alignItems='center' justifyContent='flex-end'>
                        <Grid item>
                          <Button
                            color='inherit'
                            disabled={!initialValues.id || dirty}
                            onClick={() => {
                              setIsPreviewingReportSummary(true);
                              previewReportSummary(initialValues.invoiceNumber).finally(() => {
                                setIsPreviewingReportSummary(false);
                              });
                            }}
                            variant='contained'
                          >
                            PREVIEW REPORT SUMMARY
                          </Button>
                        </Grid>

                        <Grid item>
                          <Button
                            color='inherit'
                            disabled={!initialValues.id || !initialValues.formIds || dirty}
                            onClick={() => {
                              setIsPreviewingFieldReports(true);
                              previewFieldReports(initialValues.invoiceNumber).finally(() => {
                                setIsPreviewingFieldReports(false);
                              });
                            }}
                            variant='contained'
                          >
                            PREVIEW FIELD REPORTS
                          </Button>
                        </Grid>

                        <Grid item>
                          <Button
                            color='inherit'
                            disabled={!initialValues.id || dirty}
                            onClick={() => {
                              setIsPreviewingInvoice(true);
                              previewInvoice(initialValues.invoiceNumber).finally(() => {
                                setIsPreviewingInvoice(false);
                              });
                            }}
                            variant='contained'
                          >
                            PREVIEW INVOICE
                          </Button>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Autocomplete
                          autoHighlight
                          autoSelect
                          disabled={initialValues.isFinalized}
                          getOptionLabel={job => job.jobNumber}
                          onBlur={handleBlur}
                          onChange={(_e, value) => {
                            setFieldValue('job', value);
                            setFieldValue('jobContractDetail', null);
                            setFieldValue('formIds', []);
                            onJobChange(value?.id!);
                          }}
                          onInputChange={(_, value: string) => {
                            getJobsAutocomplete(value);
                          }}
                          options={jobs}
                          noOptionsText='Type to search . . . '
                          value={values.job || null}
                          renderInput={params => (
                            <TextField
                              {...params}
                              error={Boolean(touched.job?.id && errors.job?.id)}
                              helperText={touched.job?.id && errors.job?.id}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <>
                                    {isGettingJobs && (
                                      <InputAdornment position='end'>
                                        <CircularProgress size={16} />
                                      </InputAdornment>
                                    )}

                                    {params.InputProps.endAdornment}
                                  </>
                                )
                              }}
                              label='Job #'
                              size='small'
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Autocomplete
                          autoHighlight
                          autoSelect
                          disabled={initialValues.isFinalized || !values.job?.id}
                          getOptionLabel={jobContractDetail =>
                            `${jobContractDetail.contractNumber} - ${jobContractDetail.customer.companyName}`
                          }
                          onBlur={handleBlur}
                          onChange={(_e, value) => {
                            setFieldValue('jobContractDetail', value);
                          }}
                          options={values.job?.jobContractDetail ?? []}
                          value={values.jobContractDetail?.id ? values.jobContractDetail : null}
                          renderInput={params => (
                            <TextField
                              {...params}
                              error={Boolean(touched.jobContractDetail?.id && errors.jobContractDetail?.id)}
                              helperText={touched.jobContractDetail?.id && errors.jobContractDetail?.id}
                              label='Contract #'
                              size='small'
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <TextField
                          disabled={initialValues.isFinalized}
                          error={Boolean(touched.invoiceNumber && errors.invoiceNumber)}
                          fullWidth
                          helperText={touched.invoiceNumber && errors.invoiceNumber}
                          label='Invoice #'
                          name='invoiceNumber'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          size='small'
                          value={values.invoiceNumber}
                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <TextField
                          disabled={initialValues.isFinalized}
                          error={Boolean(touched.poNumber && errors.poNumber)}
                          fullWidth
                          helperText={touched.poNumber && errors.poNumber}
                          label='PO #'
                          name='poNumber'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          size='small'
                          value={values.poNumber}
                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <TextField
                          disabled={initialValues.isFinalized}
                          error={Boolean(touched.invoiceDate && errors.invoiceDate)}
                          fullWidth
                          helperText={touched.invoiceDate && errors.invoiceDate}
                          InputLabelProps={{ shrink: true }}
                          label='Invoice Date'
                          name='invoiceDate'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          size='small'
                          type='date'
                          value={values.invoiceDate}
                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <TextField
                          disabled={initialValues.isFinalized}
                          error={Boolean(touched.datePerformed && errors.datePerformed)}
                          fullWidth
                          helperText={touched.datePerformed && errors.datePerformed}
                          InputLabelProps={{ shrink: true }}
                          label='Date Performed'
                          name='datePerformed'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          size='small'
                          type='date'
                          value={values.datePerformed}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Divider />
                      </Grid>

                      <Grid item xs={12}>
                        <Typography fontWeight='bold'>LINES</Typography>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <TextField
                          disabled={initialValues.isFinalized}
                          error={Boolean(touched.line1 && errors.line1)}
                          fullWidth
                          helperText={touched.line1 && errors.line1}
                          label='Line 1'
                          name='line1'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          size='small'
                          value={values.line1 || ''}
                        />
                      </Grid>

                      <Grid item xs={8} />

                      <Grid item xs={12} sm={4}>
                        <TextField
                          disabled={initialValues.isFinalized}
                          error={Boolean(touched.line2 && errors.line2)}
                          fullWidth
                          helperText={touched.line2 && errors.line2}
                          label='Line 2'
                          name='line2'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          size='small'
                          value={values.line2 || ''}
                        />
                      </Grid>

                      <Grid item xs={8} />

                      <Grid item xs={12} sm={4}>
                        <TextField
                          disabled={initialValues.isFinalized}
                          error={Boolean(touched.line3 && errors.line3)}
                          fullWidth
                          helperText={touched.line3 && errors.line3}
                          label='Line 3'
                          name='line3'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          size='small'
                          value={values.line3 || ''}
                        />
                      </Grid>

                      <Grid item xs={8} />

                      <Grid item xs={12} sm={4}>
                        <TextField
                          disabled={initialValues.isFinalized}
                          error={Boolean(touched.line4 && errors.line4)}
                          fullWidth
                          helperText={touched.line4 && errors.line4}
                          label='Line 4'
                          name='line4'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          size='small'
                          value={values.line4 || ''}
                        />
                      </Grid>

                      <Grid item xs={8} />

                      <Grid item xs={12} sm={4}>
                        <TextField
                          disabled={initialValues.isFinalized}
                          error={Boolean(touched.line5 && errors.line5)}
                          fullWidth
                          helperText={touched.line5 && errors.line5}
                          label='Line 5'
                          name='line5'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          size='small'
                          value={values.line5 || ''}
                        />
                      </Grid>

                      <Grid item xs={8} />

                      <Grid item xs={12}>
                        <Divider />
                      </Grid>

                      <Grid item xs={12}>
                        <Typography fontWeight='bold'>FIELD REPORTS</Typography>
                      </Grid>

                      {isGettingForms && (
                        <Grid item xs={12}>
                          <CircularProgress size={48} />
                        </Grid>
                      )}

                      {!isGettingForms && forms.length > 0 && (
                        <>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={values.formIds?.length === forms.length}
                                  disabled={initialValues.isFinalized}
                                  indeterminate={(values.formIds?.length ?? 0) > 0 && values.formIds?.length !== forms.length}
                                  onChange={() => {
                                    if (
                                      values.formIds?.length === forms.length ||
                                      ((values.formIds?.length ?? 0) > 0 && values.formIds?.length !== forms.length)
                                    ) {
                                      setFieldValue('formIds', []);
                                    } else {
                                      setFieldValue(
                                        'formIds',
                                        forms.map(form => form.id)
                                      );

                                      setFieldValue(
                                        'datePerformed',
                                        forms.sort((a, b) => moment(b.date).diff(a.date))[0]?.date
                                      );
                                    }
                                  }}
                                />
                              }
                              label='Select All'
                            />
                          </Grid>

                          {forms.map(form => (
                            <>
                              <Grid item xs={12} sm={2}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={values.formIds?.some(formId => formId === form.id)}
                                      disabled={initialValues.isFinalized}
                                      onChange={() => {
                                        if (values.formIds?.some(formId => formId === form.id)) {
                                          setFieldValue(
                                            'formIds',
                                            values.formIds?.filter(formId => formId !== form.id)
                                          );

                                          setFieldValue(
                                            'datePerformed',
                                            forms
                                              .filter(
                                                form2 =>
                                                  form2.id !== form.id && values.formIds?.some(formId => formId === form2.id)
                                              )
                                              .sort((a, b) => moment(b.date).diff(a.date))[0]?.date ?? undefined
                                          );
                                        } else {
                                          setFieldValue('formIds', [...values.formIds!, form.id]);

                                          if (!values.datePerformed || moment(form.date).isAfter(values.datePerformed)) {
                                            setFieldValue('datePerformed', form.date);
                                          }
                                        }
                                      }}
                                    />
                                  }
                                  label={form.formNumber}
                                />
                              </Grid>

                              <Grid item xs={12} sm={2}>
                                <Typography>{moment(form.date).format('MM/DD/yyyy')}</Typography>
                              </Grid>

                              <Grid item xs={12} sm={2}>
                                <Typography>{form.locationOfWork}</Typography>
                              </Grid>

                              <Grid item xs={6}></Grid>
                            </>
                          ))}
                        </>
                      )}
                    </Grid>
                  </CardContent>
                </Card>

                <BottomFormBar
                  create={!initialValues.isFinalized}
                  isEditable={!initialValues.isFinalized}
                  onBack={() => {
                    navigate(-1);
                  }}
                  reversable={initialValues.isFinalized}
                  onReverse={() => {
                    if (
                      window.confirm(
                        `Are you sure you want to reverse Invoice #${initialValues.invoiceNumber}? This action cannot be undone.`
                      )
                    ) {
                      setIsReversingInvoice(true);
                      deleteInvoice(values.invoiceNumber)
                        .then(() => {
                          enqueueSnackbar(`Invoice #${values.invoiceNumber} reversed successfully!`, { variant: 'success' });
                          navigate(-1);
                        })
                        .finally(() => {
                          setIsReversingInvoice(false);
                        });
                    }
                  }}
                  onCancel={() => {
                    navigate(-1);
                  }}
                  onCreate={() => {
                    setFieldValue('isFinalized', true);
                    handleSubmit();
                  }}
                  onSave={() => {
                    handleSubmit();
                  }}
                  save={!initialValues.isFinalized}
                />
              </form>
            </>
          );
        }}
      </Formik>
    </Page>
  );
}
