import { CircularProgress, Grid, Typography } from '@mui/material';
import { MCE_RED, QA_BLUE } from '../app/theme';
import React from 'react';
import { styled } from '@mui/material/styles';
import { AuthenticatedTemplate } from '@azure/msal-react';

const LoadingContainer = styled('div')(() => ({
  position: 'fixed',
  zIndex: 1,
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'rgba(255, 255, 255, 0.6)'
}));

export interface PageProps {
  title?: string;
  isLoading?: boolean;
}

const Page = ({
  title,
  children,
  isLoading = false
}: React.PropsWithChildren<PageProps>) => {
  return (
    <Grid container style={{ padding: 1, overflow: 'hidden' }}>
      {title && (
        <Grid
          item
          xs={12}
          style={{
            backgroundColor:
              window.location.href.indexOf('localhost') > -1 ||
              window.location.href.indexOf('azapp-field-report-qa') > -1 ||
              window.location.href.indexOf('fieldreportqa') > -1
                ? QA_BLUE
                : MCE_RED,
            padding: '8px'
          }}
        >
          <Typography variant='h5' style={{ color: '#fff' }}>
            {title}
          </Typography>
        </Grid>
      )}

      <Grid item xs={12} sx={{ mt: 1 }}>
        {children}
      </Grid>

      {isLoading && (
        <LoadingContainer>
          <CircularProgress color='inherit' size={70} />
        </LoadingContainer>
      )}
    </Grid>
  );
};

export default Page;
