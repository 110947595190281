import { Add } from '@mui/icons-material';
import { Autocomplete, Button, Card, CardContent, Divider, Grid, TextField, Typography } from '@mui/material';
import { createCustomer, getCustomerById, updateCustomer } from 'api';
import { BottomFormBar, Page, UnsavedChangesPrompt } from 'components';
import { countries, stateProvinceAbbreviations } from '../constants';
import { CustomerDto, customerAddressInit, customerInit, customerValidatonSchema } from 'dtos';
import { Formik, getIn, setNestedObjectValues } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export default function AddEditCustomer() {
  const navigate = useNavigate();
  const [isGettingCustomer, setIsGettingCustomer] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<CustomerDto>(customerInit);
  const { customerId } = useParams();
  const [isCreatingCustomer, setIsCreatingCustomer] = useState<boolean>(false);
  const [isUpdatingCustomer, setIsUpdatingCustomer] = useState<boolean>(false);

  useEffect(() => {
    if (customerId && customerId.toLowerCase() !== 'new' && customerId.toLowerCase() !== 'undefined' && !isNaN(+customerId)) {
      setIsGettingCustomer(true);
      getCustomerById(+customerId)
        .then(({ value }) => {
          setInitialValues({
            ...value,
            customerAddresses: value.customerAddresses?.length > 0 ? value.customerAddresses : [customerAddressInit]
          });
        })
        .finally(() => {
          setIsGettingCustomer(false);
        });
    }
  }, [customerId]);

  return (
    <Page
      title={initialValues.id ? 'Edit Customer' : 'Add Customer'}
      isLoading={isGettingCustomer || isCreatingCustomer || isUpdatingCustomer}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values, formikHelpers) => {
          if (values.id) {
            setIsUpdatingCustomer(true);
            updateCustomer(values)
              .then(response => {
                setInitialValues(response.value);
                enqueueSnackbar(`${values.companyName} updated successfully!`, {
                  variant: 'success'
                });
              })
              .finally(() => {
                setIsUpdatingCustomer(false);
              });
          } else {
            setIsCreatingCustomer(true);
            createCustomer(values)
              .then(({ value }) => {
                setInitialValues(value);
                enqueueSnackbar(`${values.companyName} created successfully!`, {
                  variant: 'success'
                });

                // Timeout is required to prevent the unsaved changes prompt from displaying
                setTimeout(() => {
                  navigate('/customers/' + value.id, { replace: true });
                }, 250);
              })
              .finally(() => {
                setIsCreatingCustomer(false);
              });
          }

          formikHelpers.setSubmitting(false);
        }}
        validateOnBlur
        validateOnChange
        validationSchema={customerValidatonSchema}
      >
        {({
          dirty,
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          touched,
          setTouched,
          validateForm,
          values
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Card>
                <CardContent>
                  <Grid container spacing={2} alignItems='center'>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        error={Boolean(touched.companyName && errors.companyName)}
                        fullWidth
                        helperText={touched.companyName && errors.companyName}
                        label='Company Name'
                        name='companyName'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        size='small'
                        value={values.companyName || ''}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        error={Boolean(touched.companyNumber && errors.companyNumber)}
                        fullWidth
                        helperText={touched.companyNumber && errors.companyNumber}
                        label='Company #'
                        name='companyNumber'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        size='small'
                        value={values.companyNumber || ''}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        error={Boolean(touched.notes && errors.notes)}
                        fullWidth
                        helperText={touched.notes && errors.notes}
                        label='Notes'
                        maxRows={4}
                        minRows={4}
                        multiline
                        name='notes'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        size='small'
                        value={values.notes || ''}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>

                    <Grid item xs={12}>
                      <Typography fontWeight='bold'>ADDRESSES</Typography>
                    </Grid>

                    {values.customerAddresses.map((_, index) => (
                      <Grid item xs={12} sm={4}>
                        <Card variant='outlined'>
                          <CardContent>
                            <Grid item container spacing={1} alignItems='center'>
                              <Grid item xs={12}>
                                <TextField
                                  error={Boolean(
                                    getIn(touched, `customerAddresses[${index}].billingContact`) &&
                                      getIn(errors, `customerAddresses[${index}].billingContact`)
                                  )}
                                  helperText={
                                    getIn(touched, `customerAddresses[${index}].billingContact`) &&
                                    getIn(errors, `customerAddresses[${index}].billingContact`)
                                  }
                                  fullWidth
                                  label='Billing Contact'
                                  name={`customerAddresses[${index}].billingContact`}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  size='small'
                                  value={values.customerAddresses[index].billingContact || ''}
                                />
                              </Grid>

                              <Grid item xs={12}>
                                <TextField
                                  error={Boolean(
                                    getIn(touched, `customerAddresses[${index}].address1`) &&
                                      getIn(errors, `customerAddresses[${index}].address1`)
                                  )}
                                  helperText={
                                    getIn(touched, `customerAddresses[${index}].address1`) &&
                                    getIn(errors, `customerAddresses[${index}].address1`)
                                  }
                                  fullWidth
                                  label='Address 1'
                                  name={`customerAddresses[${index}].address1`}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  size='small'
                                  value={values.customerAddresses[index].address1 || ''}
                                />
                              </Grid>

                              <Grid item xs={12}>
                                <TextField
                                  error={Boolean(
                                    getIn(touched, `customerAddresses[${index}].address2`) &&
                                      getIn(errors, `customerAddresses[${index}].address2`)
                                  )}
                                  helperText={
                                    getIn(touched, `customerAddresses[${index}].address2`) &&
                                    getIn(errors, `customerAddresses[${index}].address2`)
                                  }
                                  fullWidth
                                  label='Address 2'
                                  name={`customerAddresses[${index}].address2`}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  size='small'
                                  value={values.customerAddresses[index].address2 || ''}
                                />
                              </Grid>

                              <Grid item xs={12} sm={4}>
                                <TextField
                                  error={Boolean(
                                    getIn(touched, `customerAddresses[${index}].city`) &&
                                      getIn(errors, `customerAddresses[${index}].city`)
                                  )}
                                  helperText={
                                    getIn(touched, `customerAddresses[${index}].city`) &&
                                    getIn(errors, `customerAddresses[${index}].city`)
                                  }
                                  fullWidth
                                  label='City'
                                  name={`customerAddresses[${index}].city`}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  size='small'
                                  value={values.customerAddresses[index].city || ''}
                                />
                              </Grid>

                              <Grid item xs={12} sm={4}>
                                <Autocomplete
                                  autoHighlight
                                  autoSelect
                                  disablePortal
                                  // disableClearable
                                  fullWidth
                                  options={stateProvinceAbbreviations}
                                  onBlur={() => {
                                    setTouched({
                                      ...touched,
                                      customerAddresses: touched.customerAddresses?.map((customerAddress, idx) =>
                                        idx === index ? { ...customerAddress, stateProvince: true } : customerAddress
                                      )
                                    });
                                  }}
                                  onChange={(_e, value) => {
                                    setFieldValue(`customerAddresses[${index}].stateProvince`, value);
                                  }}
                                  renderInput={params => (
                                    <TextField
                                      {...params}
                                      error={Boolean(
                                        getIn(touched, `customerAddresses[${index}].stateProvince`) &&
                                          getIn(errors, `customerAddresses[${index}].stateProvince`)
                                      )}
                                      helperText={
                                        getIn(touched, `customerAddresses[${index}].stateProvince`) &&
                                        getIn(errors, `customerAddresses[${index}].stateProvince`)
                                      }
                                      InputLabelProps={{
                                        shrink: true
                                      }}
                                      label='State/Province'
                                    />
                                  )}
                                  size='small'
                                  value={values.customerAddresses[index].stateProvince || ''}
                                />
                              </Grid>

                              <Grid item xs={12} sm={4}>
                                <TextField
                                  error={Boolean(
                                    getIn(touched, `customerAddresses[${index}].postalCode`) &&
                                      getIn(errors, `customerAddresses[${index}].postalCode`)
                                  )}
                                  helperText={
                                    getIn(touched, `customerAddresses[${index}].postalCode`) &&
                                    getIn(errors, `customerAddresses[${index}].postalCode`)
                                  }
                                  fullWidth
                                  label='Postal Code'
                                  name={`customerAddresses[${index}].postalCode`}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  size='small'
                                  value={values.customerAddresses[index].postalCode || ''}
                                />
                              </Grid>

                              <Grid item xs={12}>
                                <Autocomplete
                                  autoHighlight
                                  autoSelect
                                  disablePortal
                                  // disableClearable
                                  fullWidth
                                  options={countries}
                                  onBlur={() => {
                                    setTouched({
                                      ...touched,
                                      customerAddresses: touched.customerAddresses?.map((customerAddress, idx) =>
                                        idx === index ? { ...customerAddress, country: true } : customerAddress
                                      )
                                    });
                                  }}
                                  onChange={(_e, value) => {
                                    setFieldValue(`customerAddresses[${index}].country`, value);
                                  }}
                                  renderInput={params => (
                                    <TextField
                                      {...params}
                                      error={Boolean(
                                        getIn(touched, `customerAddresses[${index}].country`) &&
                                          getIn(errors, `customerAddresses[${index}].country`)
                                      )}
                                      helperText={
                                        getIn(touched, `customerAddresses[${index}].country`) &&
                                        getIn(errors, `customerAddresses[${index}].country`)
                                      }
                                      label='Country'
                                    />
                                  )}
                                  size='small'
                                  value={values.customerAddresses[index].country || ''}
                                />
                              </Grid>

                              <Grid item xs={12} sx={{ my: 1 }}>
                                <Divider />
                              </Grid>

                              <Grid item xs={12}>
                                <TextField
                                  error={Boolean(
                                    getIn(touched, `customerAddresses[${index}].businessPhoneNumber`) &&
                                      getIn(errors, `customerAddresses[${index}].businessPhoneNumber`)
                                  )}
                                  helperText={
                                    getIn(touched, `customerAddresses[${index}].businessPhoneNumber`) &&
                                    getIn(errors, `customerAddresses[${index}].businessPhoneNumber`)
                                  }
                                  fullWidth
                                  label='Business Phone Number'
                                  name={`customerAddresses[${index}].businessPhoneNumber`}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  size='small'
                                  value={values.customerAddresses[index].businessPhoneNumber || ''}
                                />
                              </Grid>

                              <Grid item xs={12}>
                                <TextField
                                  error={Boolean(
                                    getIn(touched, `customerAddresses[${index}].businessFax`) &&
                                      getIn(errors, `customerAddresses[${index}].businessFax`)
                                  )}
                                  helperText={
                                    getIn(touched, `customerAddresses[${index}].businessFax`) &&
                                    getIn(errors, `customerAddresses[${index}].businessFax`)
                                  }
                                  fullWidth
                                  label='Business Fax'
                                  name={`customerAddresses[${index}].businessFax`}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  size='small'
                                  value={values.customerAddresses[index].businessFax || ''}
                                />
                              </Grid>

                              <Grid item xs={12}>
                                <TextField
                                  error={Boolean(
                                    getIn(touched, `customerAddresses[${index}].emailAddress`) &&
                                      getIn(errors, `customerAddresses[${index}].emailAddress`)
                                  )}
                                  helperText={
                                    getIn(touched, `customerAddresses[${index}].emailAddress`) &&
                                    getIn(errors, `customerAddresses[${index}].emailAddress`)
                                  }
                                  fullWidth
                                  label='Email Address'
                                  name={`customerAddresses[${index}].emailAddress`}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  size='small'
                                  value={values.customerAddresses[index].emailAddress || ''}
                                />
                              </Grid>

                              <Grid item xs={12}>
                                <TextField
                                  error={Boolean(
                                    getIn(touched, `customerAddresses[${index}].website`) &&
                                      getIn(errors, `customerAddresses[${index}].website`)
                                  )}
                                  helperText={
                                    getIn(touched, `customerAddresses[${index}].website`) &&
                                    getIn(errors, `customerAddresses[${index}].website`)
                                  }
                                  fullWidth
                                  label='Website'
                                  name={`customerAddresses[${index}].website`}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  size='small'
                                  value={values.customerAddresses[index].website || ''}
                                />
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}

                    <Grid item xs={12} sm={4}>
                      <Button
                        color='inherit'
                        onClick={() => {
                          setFieldValue('customerAddresses', [...values.customerAddresses, customerAddressInit]);
                        }}
                        startIcon={<Add />}
                        variant='contained'
                      >
                        ADD ADDITIONAL ADDRESS
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

              <BottomFormBar
                isEditable
                onCancel={() => {
                  navigate(-1);
                }}
                save
                onSave={() => {
                  // If there are multiple addresses, validation isn't running on all of them (?)
                  validateForm().then(formikErrors => {
                    setTouched(setNestedObjectValues(formikErrors, true));
                  });
                  handleSubmit();
                }}
              />

              <UnsavedChangesPrompt when={dirty} />
            </form>
          );
        }}
      </Formik>
    </Page>
  );
}
