import {
  Button,
  CircularProgress,
  Grid,
  Link,
  TableCell,
  TableRow,
  TextField
} from '@mui/material';
import { createEquipment, getEquipment, updateEquipment } from 'api';
import { Page, Table } from 'components';
import {
  EquipmentDto,
  equipmentInit,
  EquipmentParameters,
  equipmentParametersInit
} from 'dtos';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import AddEditEquipmentDialog from './AddEditEquipmentDialog';

const Equipment = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [totalEquipment, setTotalEquipment] = useState<number>(0);
  const [equipment, setEquipment] = useState<EquipmentDto[]>([]);
  const [isGettingEquipment, setIsGettingEquipment] = useState<boolean>(false);
  const [isCreatingEquipment, setIsCreatingEquipment] = useState<boolean>(false);
  const [isUpdatingEquipment, setIsUpdatingEquipment] = useState<boolean>(false);
  const [selectedEquipment, setSelectedEquipment] = useState<EquipmentDto>(equipmentInit);
  const [equipmentDialogOpen, setEquipmentDialogOpen] = useState<boolean>(false);

  const [parameters, setParameters] = useState<EquipmentParameters>(
    equipmentParametersInit
  );

  useEffect(() => {
    onFilter();
  }, [parameters]);

  const onFilter = () => {
    setIsGettingEquipment(true);
    getEquipment(parameters)
      .then(({ totalCount, value }) => {
        setTotalEquipment(totalCount!);
        setEquipment(value);
      })
      .finally(() => setIsGettingEquipment(false));
  };

  const onSortChange = (propertyName: string, sortDirection: 'asc' | 'desc') => {
    setParameters({
      ...parameters,
      sortBy: propertyName,
      sortDirection
    });
  };

  const onChangePage = (page: number) => {
    setParameters({
      ...parameters,
      page
    });
  };

  const onChangePageSize = (pageSize: number) => {
    setParameters({
      ...parameters,
      pageSize
    });
  };

  const onReset = () => setParameters({ ...parameters, filterText: '' });

  const onAddEquipment = () => {
    setSelectedEquipment(equipmentInit);
    setEquipmentDialogOpen(true);
  };

  const onEditEquipment = (equipmentDto: EquipmentDto) => {
    setSelectedEquipment(equipmentDto);
    setEquipmentDialogOpen(true);
  };

  const onCloseEquipmentDialog = () => {
    setEquipmentDialogOpen(false);
  };

  const onSaveEquipmentDialog = (values: EquipmentDto) => {
    if (values.id) {
      setIsUpdatingEquipment(true);
      updateEquipment(values)
        .then(response => {
          setEquipment(equipment.map(e => (e.id === response.id ? response : e)));
          enqueueSnackbar(`${values.name} updated successfully!`, { variant: 'success' });
        })
        .finally(() => setIsUpdatingEquipment(false));
    } else {
      setIsCreatingEquipment(true);
      createEquipment(values)
        .then(response => {
          setEquipment([...equipment, response]);
          setTotalEquipment(totalEquipment + 1);
          enqueueSnackbar(`${values.name} created successfully!`, { variant: 'success' });
        })
        .finally(() => setIsCreatingEquipment(false));
    }

    onCloseEquipmentDialog();
  };

  return (
    <>
      <AddEditEquipmentDialog
        equipment={selectedEquipment}
        onClose={onCloseEquipmentDialog}
        onSave={onSaveEquipmentDialog}
        open={equipmentDialogOpen}
      />

      <Page title='Equipment' isLoading={isGettingEquipment}>
        <Table
          actions={
            <Grid container justifyContent='space-between'>
              <Grid item xs={12} sm={6} container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label='Filter text'
                    onChange={e =>
                      setParameters({
                        ...parameters,
                        filterText: e.target.value
                      })
                    }
                    size='small'
                    value={parameters.filterText}
                  />
                </Grid>

                <Grid item>
                  <Button color='inherit' onClick={onFilter} variant='contained'>
                    FILTER
                  </Button>
                </Grid>

                <Grid item>
                  <Button color='inherit' onClick={onReset} variant='contained'>
                    RESET
                  </Button>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6} container justifyContent='flex-end'>
                <Grid item>
                  <Button
                    variant='contained'
                    color='inherit'
                    onClick={onAddEquipment}
                    disabled={isCreatingEquipment || isUpdatingEquipment}
                  >
                    {isCreatingEquipment && (
                      <>
                        <CircularProgress color='inherit' size={20} />
                        &nbsp;&nbsp;
                      </>
                    )}
                    ADD NEW
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          }
          columns={[{ label: 'Name', propertyName: 'name' }]}
          sortDirection={parameters.sortDirection}
          sortBy={parameters.sortBy}
          onSortChange={onSortChange}
          onChangePage={onChangePage}
          onChangePageSize={onChangePageSize}
          page={parameters.page!}
          pageSize={parameters.pageSize!}
          totalCount={totalEquipment}
        >
          {equipment.map(equipment => (
            <TableRow key={equipment.id}>
              <TableCell>
                <Link
                  onClick={() => onEditEquipment(equipment)}
                  sx={{
                    cursor: 'pointer',
                    textDecoration: 'none'
                  }}
                >
                  {equipment.name}
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </Page>
    </>
  );
};

export default Equipment;
