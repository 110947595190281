export enum Companies {
  MotorCityElectric = 1,
  StelkoElectric = 2,
  MotorCityElectricUtilities = 5,
  MidSouthContractors = 8,
  MotorCityElectricTechnologies = 9,
  HuronValleyElectric = 10,
  RotorElectric = 12,
  WilliamsElectricalAndTelecommunications = 60,
  MotorCityElectricService = 100,
  MidMichiganElectricalSolutions = 14
}
