import { AuthenticatedTemplate } from '@azure/msal-react';
import { useMediaQuery, useScrollTrigger } from '@mui/material';
import { styled } from '@mui/material/styles';
import PersistentListParametersProvider from 'context/PersistentListParametersContext';
import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Header from './Header';
import MenuDrawer from './MenuDrawer';
import theme from './theme';
import { useAuth } from 'context';

const Layout = styled('div')({
  display: 'flex',
  minHeight: '100%'
});

const Body = styled('main')({
  flexGrow: 1,
  overflow: 'hidden',
  padding: theme.spacing(2),
  marginTop: '64px',
  marginBottom: '64px'
});

const AppLayout = () => {
  // If the client's display is a mobile device, the MenuDrawer
  // must be toggled via the Header menu button. Otherwise, it
  // is persistent/static.
  const isMobile: boolean = useMediaQuery('(max-width: 768px)'); // 960px?
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(!isMobile);

  // `trigger` is used for collapsing the header when the user scrolls down.
  const trigger = useScrollTrigger({ target: window ?? undefined });

  const { IS_INVOICE_ADMIN } = useAuth();

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === '/') {
      navigate('/my-forms', { replace: true });
    }
  }, [location.pathname, navigate]);

  useEffect(() => {
    if (isMobile) {
      setIsDrawerOpen(false);
    }
  }, [isMobile]);

  const onToggleMenu = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const onCloseDrawer = () => setIsDrawerOpen(false);

  return (
    <AuthenticatedTemplate>
      <PersistentListParametersProvider>
        <Layout>
          <Header onToggleMenu={onToggleMenu} trigger={trigger} />

          <MenuDrawer
            isMobile={isMobile}
            isDrawerOpen={isDrawerOpen}
            onCloseDrawer={onCloseDrawer}
            trigger={trigger}
            isInvoiceAdmin={IS_INVOICE_ADMIN}
          />

          <Body>
            <Outlet />
          </Body>
        </Layout>
      </PersistentListParametersProvider>
    </AuthenticatedTemplate>
  );
};

export default AppLayout;
