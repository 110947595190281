import { api } from 'app/api';
import { ApiResponse, EquipmentDto, JobDto, JobsParameters } from 'dtos';
import { URIQuery } from 'utils';

export const getJobs = async (parameters: JobsParameters): Promise<ApiResponse<JobDto[]>> =>
  await api.get<ApiResponse<JobDto[]>>('jobs' + URIQuery({ ...parameters })).then(res => res.data);

export const getJobByJobNumber = async (jobNumber: string): Promise<ApiResponse<JobDto>> =>
  await api.get<ApiResponse<JobDto>>('jobs/' + jobNumber).then(res => res.data);

export const getEquipmentByJobNumber = async (jobNumber: string) =>
  await api.get<ApiResponse<EquipmentDto[]>>('jobs/' + encodeURIComponent(jobNumber) + '/equipment').then(res => res.data);

export const createJob = async (values: JobDto): Promise<ApiResponse<JobDto>> =>
  await api.post<ApiResponse<JobDto>>('jobs', values).then(res => res.data);

export const updateJob = async (values: JobDto): Promise<ApiResponse<JobDto>> =>
  await api.put<ApiResponse<JobDto>>('jobs', values).then(res => res.data);
