import * as yup from 'yup';
import { CustomerAddressDto, customerAddressInit, customerAddressValidationSchema } from './customerAddress';

export interface CustomersParameters {
  filterText?: string;
  page?: number;
  pageSize?: number;
  sortBy?: string;
  sortDirection?: 'asc' | 'desc';
}

export const customersParametersInit: CustomersParameters = {
  filterText: '',
  page: 0,
  pageSize: 10,
  sortBy: 'name',
  sortDirection: 'asc'
};

export interface CustomerDto {
  id?: number;
  companyName: string;
  companyNumber: string;
  notes?: string;
  customerAddresses: CustomerAddressDto[];
}

export const customerInit: CustomerDto = {
  id: undefined,
  companyName: '',
  companyNumber: '',
  notes: undefined,
  customerAddresses: [customerAddressInit]
};

export const customerValidatonSchema = yup.object<CustomerDto>({
  companyName: yup.string().max(200, 'Company Name cannot be more than 200 characters.').required('Company Name is required.'),
  companyNumber: yup.string().max(200, 'Company # cannot be more than 200 characters.').required('Company # is required.'),
  notes: yup.string().max(2_000, 'Notes cannot be more than 2,000 characters.').nullable(),
  customerAddresses: yup.array().of(customerAddressValidationSchema)
});
