import { api } from 'app/api';
import { ApiResponse, FormDto, FormsParameters } from 'dtos';
import { URIQuery, base64ToArrayBuffer } from 'utils';

export const getForms = async (
  parameters: FormsParameters
): Promise<ApiResponse<FormDto[]>> =>
  await api.get('forms' + URIQuery({ ...parameters })).then(res => res.data);

export const getFormByFormNumber = async (formNumber: string) =>
  await api.get<ApiResponse<FormDto>>('forms/' + formNumber).then(res => res.data);

export const createForm = async (values: FormData) =>
  await api({
    data: values,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'post',
    url: 'forms'
  }).then(res => res.data);

export const updateForm = async (values: FormData) =>
  await api({
    data: values,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'put',
    url: 'forms'
  }).then(res => res.data);

export const submitForm = async (values: FormData) =>
  await api({
    data: values,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'post',
    url: 'forms/submit'
  }).then(res => res.data);

export const processForm = async (values: FormData) =>
  await api({
    data: values,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'put',
    url: 'forms/process'
  }).then(res => res.data);

export const printForm = async (formDto: FormDto) => {
  const requestUrl = `telerik-reports/export?formIds=${formDto.id}`;

  await api
    .get(requestUrl)
    .then(({ data }) => {
      const buffer = base64ToArrayBuffer(data);
      const file = new Blob([buffer], { type: 'application/pdf' });
      const fileUrl = window.URL.createObjectURL(file);
      const _newTab = window.open(fileUrl);
    })
    .catch(() => {});
};
