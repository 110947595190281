import { AppBar, Button, Grid } from '@mui/material';
import React from 'react';

interface Props {
  create?: boolean;
  isEditable?: boolean;
  isSubmitting?: boolean;
  onBack?: () => void;
  onCancel?: () => void;
  onCreate?: () => void;
  onProcess?: () => void;
  onReverse?: () => void;
  onSave?: () => void;
  onSubmit?: () => void;
  process?: boolean;
  reversable?: boolean;
  save?: boolean;
  submit?: boolean;
}

export default function BottomFormBar({
  create,
  isEditable = true,
  isSubmitting,
  onBack,
  onCancel,
  onCreate,
  onProcess,
  onReverse,
  onSave,
  onSubmit,
  process,
  reversable,
  save,
  submit
}: React.PropsWithChildren<Props>) {
  return (
    <AppBar position='fixed' style={{ bottom: 0, top: 'auto', background: '#fff' }}>
      <Grid container spacing={2} alignItems='center' justifyContent='flex-end' style={{ padding: 10 }}>
        {isEditable ? (
          <>
            {onCancel && (
              <Grid item>
                <Button color='error' disabled={isSubmitting} onClick={onCancel} sx={{ fontWeight: 'bold;' }} variant='text'>
                  CANCEL
                </Button>
              </Grid>
            )}

            {save && onSave && (
              <Grid item>
                <Button
                  color='primary'
                  disabled={isSubmitting}
                  onClick={onSave}
                  sx={{ fontWeight: 'bold;' }}
                  // type='submit'
                  variant='text'
                >
                  SAVE
                </Button>
              </Grid>
            )}

            {submit && onSubmit && (
              <Grid item>
                <Button
                  color='success'
                  disabled={isSubmitting}
                  onClick={onSubmit}
                  sx={{ fontWeight: 'bold;' }}
                  // type='submit'
                  variant='text'
                >
                  SUBMIT
                </Button>
              </Grid>
            )}

            {process && onProcess && (
              <Grid item>
                <Button
                  color='success'
                  disabled={isSubmitting}
                  onClick={onProcess}
                  sx={{ fontWeight: 'bold;' }}
                  // type='submit'
                  variant='text'
                >
                  PROCESS
                </Button>
              </Grid>
            )}
          </>
        ) : (
          <>
            {onBack && (
              <Grid item>
                <Button color='error' onClick={onBack} sx={{ fontWeight: 'bold;' }} variant='text'>
                  BACK
                </Button>
              </Grid>
            )}
          </>
        )}
        {create && onCreate && (
          <Grid item>
            <Button color='success' onClick={onCreate} sx={{ fontWeight: 'bold' }} variant='text'>
              CREATE
            </Button>
          </Grid>
        )}

        {reversable && onReverse && (
          <Grid item>
            <Button color='error' onClick={onReverse} sx={{ fontWeight: 'bold' }} variant='text'>
              REVERSE
            </Button>
          </Grid>
        )}
      </Grid>
    </AppBar>
  );
}
