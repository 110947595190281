import {
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export interface TableProps {
  sortBy?: string;
  sortDirection?: 'asc' | 'desc';
  columns: { propertyName: string; label: string; canSort?: boolean }[];
  onSortChange: (propertyName: string, sortDirection: 'asc' | 'desc') => void;
  page: number;
  pageSize: number;
  totalCount: number;
  actions?: React.ReactNode;
  onChangePage: (page: number) => void;
  onChangePageSize: (pageSize: number) => void;
}

const DefaultActions = () => {
  const navigate = useNavigate();

  return (
    <Grid container justifyContent='space-between'>
      <Grid
        item
        xs={12}
        sm={7}
        container
        spacing={2}
        alignItems='center'
        justifyContent='flex-start'
      >
        <Grid item xs={12} sm={2}>
          <FormControl size='small' fullWidth>
            <InputLabel>Filter field</InputLabel>

            <Select>
              <MenuItem></MenuItem>
              <MenuItem>Form #</MenuItem>
              <MenuItem>Customer</MenuItem>
              <MenuItem>Last Modified</MenuItem>
              <MenuItem>Job Date</MenuItem>
              <MenuItem>Status</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField label='Filter text' fullWidth size='small' />
        </Grid>

        <Grid item>
          <Button variant='contained' color='inherit'>
            FILTER
          </Button>
        </Grid>

        <Grid item>
          <Button variant='contained' color='inherit'>
            RESET
          </Button>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        sm={5}
        container
        spacing={2}
        alignItems='center'
        justifyContent='flex-end'
      >
        <Grid item>
          <Button
            variant='contained'
            color='inherit'
            onClick={() => navigate('/new')}
            disabled
          >
            EXPORT
          </Button>
        </Grid>

        <Grid item>
          <Button variant='contained' color='inherit' onClick={() => navigate('/new')}>
            ADD NEW
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

// TODO: Rename to BaseTable...
const Table = ({
  actions = <DefaultActions />,
  sortDirection = 'asc',
  sortBy = '',
  onSortChange,
  children,
  onChangePage,
  onChangePageSize,
  totalCount,
  page,
  pageSize,
  columns
}: React.PropsWithChildren<TableProps>) => {
  return (
    <Card variant='outlined'>
      <CardActions>{actions}</CardActions>

      <CardContent style={{ padding: 0 }}>
        <MuiTable>
          <TableHead>
            <TableRow>
              {columns.map(({ propertyName, label, canSort }, index) => (
                <TableCell
                  key={propertyName}
                  sortDirection={sortDirection}
                  align='left'
                  size='medium'
                >
                  <TableSortLabel
                    active={sortBy === propertyName}
                    direction={sortDirection}
                    disabled={canSort === false}
                    onClick={() => {
                      if (sortBy === propertyName) {
                        onSortChange(
                          propertyName,
                          sortDirection === 'asc' ? 'desc' : 'asc'
                        );
                      } else {
                        onSortChange(propertyName, 'asc');
                      }
                    }}
                  >
                    {label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>{children}</TableBody>
        </MuiTable>
        <TablePagination
          component='div'
          count={totalCount}
          rowsPerPage={pageSize}
          page={page}
          onPageChange={(_, page: number) => onChangePage(page)}
          onRowsPerPageChange={e => onChangePageSize(e.target.value as unknown as number)}
        />
      </CardContent>
    </Card>
  );
};

export default Table;
