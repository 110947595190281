import React from 'react';
import { styled } from '@mui/material/styles';
import { Dialog as MuiDialog, DialogProps } from '@mui/material';

const Dialog = styled(MuiDialog)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    '.MuiDialog-paper': {
      margin: '0px',
      width: '100%',
      height: '100%'
    },

    '.MuiDialog-paperScrollPaper': {
      maxHeight: '100%'
    },

    '.MuiDialog-paperWidthSm': {
      maxWidth: '100%'
    }
  }
}));

export interface ResponsiveDialogProps extends DialogProps {
  // onClose is not used by this component, but is expected to be used
  // to manage whether or not the dialog is open in parent components.
  onClose?: () => void;
  open: boolean;
  'data-cy'?: string;
  fullWidth?: boolean;
  maxWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false;
}

const ResponsiveDialog: React.FC<ResponsiveDialogProps> = ({
  children,
  open,
  fullWidth,
  maxWidth,
  ...rest
}: React.PropsWithChildren<ResponsiveDialogProps>) => {
  return (
    <Dialog fullWidth={fullWidth} maxWidth={maxWidth} open={open}>
      {children}
    </Dialog>
  );
};

export default ResponsiveDialog;
