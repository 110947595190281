import { LoadingButton } from '@mui/lab';
import { Button, Grid, TextField } from '@mui/material';
import {
  ResponsiveDialog,
  ResponsiveDialogActions,
  ResponsiveDialogContent,
  ResponsiveDialogTitle
} from 'components';
import { CustomerDto, customerValidatonSchema } from 'dtos';
import { Formik } from 'formik';

interface AddCustomerDialogProps {
  initialValues: CustomerDto;
  isLoading: boolean;
  onClose: () => void;
  onSave: (values: CustomerDto) => void;
  open: boolean;
}

export default function AddCustomerDialog({
  initialValues,
  isLoading,
  onClose,
  onSave,
  open
}: AddCustomerDialogProps) {
  return (
    <ResponsiveDialog open={open}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values, formikHelpers) => {
          onSave(values);
          formikHelpers.setSubmitting(false);
        }}
        validateOnBlur
        validateOnChange
        validationSchema={customerValidatonSchema}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <ResponsiveDialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <ResponsiveDialogTitle>Add Customer</ResponsiveDialogTitle>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      disabled={isLoading || isSubmitting}
                      error={Boolean(touched.companyName && errors.companyName)}
                      fullWidth
                      helperText={touched.companyName && errors.companyName}
                      label='Company Name'
                      name='companyName'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      size='small'
                      value={values.companyName}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      disabled={isLoading || isSubmitting}
                      error={Boolean(touched.companyNumber && errors.companyNumber)}
                      fullWidth
                      helperText={touched.companyNumber && errors.companyNumber}
                      label='Company #'
                      name='companyNumber'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      size='small'
                      value={values.companyNumber}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      disabled={isLoading || isSubmitting}
                      error={Boolean(touched.notes && errors.notes)}
                      fullWidth
                      helperText={touched.notes && errors.notes}
                      label='Notes'
                      maxRows={4}
                      minRows={4}
                      multiline
                      name='notes'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      size='small'
                      value={values.notes}
                    />
                  </Grid>
                </Grid>
              </ResponsiveDialogContent>

              <ResponsiveDialogActions>
                <Button
                  color='error'
                  disabled={isLoading || isSubmitting}
                  onClick={onClose}
                >
                  CANCEL
                </Button>

                <LoadingButton
                  color='primary'
                  loading={isLoading || isSubmitting}
                  type='submit'
                >
                  SAVE
                </LoadingButton>
              </ResponsiveDialogActions>
            </form>
          );
        }}
      </Formik>
    </ResponsiveDialog>
  );
}
