import { api } from 'app/api';
import { base64ToArrayBuffer } from 'utils';

export const viewAnalytics = async () =>
  await api
    .get('telerik-reports/view-analytics')
    .then(({ data }) => {
      const buffer = base64ToArrayBuffer(data);
      const file = new Blob([buffer], { type: 'application/pdf' });
      const fileUrl = window.URL.createObjectURL(file);
      const _newTab = window.open(fileUrl);
    })
    .catch(() => {});
