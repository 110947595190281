import { Add, Clear } from '@mui/icons-material';
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  createFilterOptions
} from '@mui/material';
import {
  createCustomer,
  createCustomerAddress,
  createJob,
  getCustomers,
  getEquipment,
  getJobByJobNumber,
  getLaborClassifications,
  updateJob
} from 'api';
import { BottomFormBar, Page, UnsavedChangesPrompt } from 'components';
import {
  CustomerAddressDto,
  CustomerDto,
  EquipmentDto,
  JobDto,
  LaborClassificationDto,
  customerAddressInit,
  customerInit,
  jobContractDetailInit,
  jobEquipmentDetailInit,
  jobInit,
  jobLaborDetailInit,
  jobValidationSchema
} from 'dtos';
import { Formik, getIn } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDebounce } from 'utils';
import AddCustomerDialog from './AddCustomerDialog';
import AddCustomerAddressDialog from './AddCustomerAddressDialog';

const customerFilter = createFilterOptions<CustomerDto>();
const customerAddressFilter = createFilterOptions<CustomerAddressDto>();

export default function AddEditCustomer() {
  const navigate = useNavigate();
  const { jobNumber } = useParams();
  const [customers, setCustomers] = useState<CustomerDto[]>([]);
  const [equipment, setEquipment] = useState<EquipmentDto[]>([]);
  const [initialValues, setInitialValues] = useState<JobDto>(jobInit);
  const [isCreatingJob, setIsCreatingJob] = useState<boolean>(false);
  const [isGettingCustomers, setIsGettingCustomers] = useState<boolean>(false);
  const [isGettingEquipment, setIsGettingEquipment] = useState<boolean>(false);
  const [isGettingJob, setIsGettingJob] = useState<boolean>(false);
  const [isGettingLaborClassifications, setIsGettingLaborClassifications] = useState<boolean>(false);
  const [isUpdatingJob, setIsUpdatingJob] = useState<boolean>(false);
  const [laborClassifications, setLaborClassifications] = useState<LaborClassificationDto[]>([]);

  // Add Customer Dialog
  const [isCreatingCustomer, setIsCreatingCustomer] = useState<boolean>(false);
  const [addEditCustomerDialogOpen, setAddEditCustomerDialogOpen] = useState<boolean>(false);
  const [customer, setCustomer] = useState<CustomerDto>(customerInit);

  // Add Customer Address Dialog
  const [isCreatingCustomerAddress, setIsCreatingCustomerAddress] = useState<boolean>(false);
  const [addCustomerAddressDialogOpen, setAddCustomerAddressDialogOpen] = useState<boolean>(false);
  const [customerAddress, setCustomerAddress] = useState<CustomerAddressDto>(customerAddressInit);

  const [jobContractDetailIndex, setJobContractDetailIndex] = useState<number>(-1);

  useEffect(() => {
    setIsGettingEquipment(true);
    getEquipment({ page: 0, pageSize: 2000, filterText: '' }) // TODO: Why is this required?
      .then(({ value }) => {
        setEquipment(value);
      })
      .finally(() => {
        setIsGettingEquipment(false);
      });

    // setIsGettingCustomers(true);
    // getCustomers({
    //   page: 0,
    //   pageSize: 2000,
    //   sortBy: 'companyName',
    //   sortDirection: 'asc'
    // })
    //   .then(({ value }) => {
    //     setCustomers(value);
    //   })
    //   .finally(() => {
    //     setIsGettingCustomers(false);
    //   });

    setIsGettingLaborClassifications(true);
    getLaborClassifications()
      .then(({ value }) => {
        setLaborClassifications(value);
      })
      .finally(() => {
        setIsGettingLaborClassifications(false);
      });
  }, []);

  useEffect(() => {
    if (jobNumber && jobNumber.toLowerCase() !== 'new' && jobNumber.toLowerCase() !== 'undefined') {
      setIsGettingJob(true);
      getJobByJobNumber(jobNumber)
        .then(({ value }) => {
          setInitialValues(value);
        })
        .finally(() => {
          setIsGettingJob(false);
        });
    }
  }, [jobNumber]);

  // This useEffect makes sure there is always at least 1 row for CONTRACTS, JOB DETAIL, and EQUIPMENT DETAIL.
  useEffect(() => {
    if (initialValues) {
      if (!initialValues.jobLaborDetail || initialValues.jobLaborDetail.length === 0) {
        setInitialValues({ ...initialValues, jobLaborDetail: [jobLaborDetailInit] });
      }

      // Equipment Detail is optional. If a user deletes the equipment detail on a saved Job, don't initialize the
      // jobEquipmentDetail because the deleted row re-appears.
      // Maxwell Sommer 7/19/2024
      if ((!initialValues.jobEquipmentDetail || initialValues.jobEquipmentDetail.length === 0) && !initialValues.id) {
        setInitialValues({ ...initialValues, jobEquipmentDetail: [jobEquipmentDetailInit] });
      }
    }
  }, [initialValues]);

  const getCustomersAutocomplete = useDebounce((filterText: string) => {
    setIsGettingCustomers(true);
    getCustomers({ page: 0, pageSize: 25, filterText })
      .then(({ value }) => {
        setCustomers(value);
      })
      .finally(() => {
        setIsGettingCustomers(false);
      });
  }, 300);

  return (
    <Page title={initialValues.id ? 'Edit Job' : 'Add Job'} isLoading={isGettingJob || isCreatingJob || isUpdatingJob}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values, formikHelpers) => {
          if (values.id) {
            setIsUpdatingJob(true);
            updateJob(values)
              .then(response => {
                setInitialValues(response.value);
                enqueueSnackbar(`Job #${values.jobNumber} updated successfully!`, {
                  variant: 'success'
                });
              })
              .finally(() => {
                setIsUpdatingJob(false);
              });
          } else {
            setIsCreatingJob(true);
            createJob(values)
              .then(response => {
                setInitialValues(response.value);
                enqueueSnackbar(`Job #${values.jobNumber} created successfully!`, {
                  variant: 'success'
                });

                // Timeout is required to prevent the unsaved changes prompt from displaying
                setTimeout(() => {
                  navigate('/jobs/' + response.value.jobNumber, { replace: true });
                }, 250);
              })
              .finally(() => {
                setIsCreatingJob(false);
              });
          }

          formikHelpers.setSubmitting(false);
        }}
        validateOnBlur
        validateOnChange
        validationSchema={jobValidationSchema}
      >
        {({
          dirty,
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          touched,
          setTouched,
          validateForm,
          values
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <AddCustomerDialog
                initialValues={customer}
                isLoading={isCreatingCustomer}
                onClose={() => {
                  setAddEditCustomerDialogOpen(false);
                  setFieldValue('customerId', 0);
                }}
                onSave={values => {
                  setIsCreatingCustomer(true);
                  createCustomer(values)
                    .then(({ value }) => {
                      setFieldValue(`jobContractDetail[${jobContractDetailIndex}].customer`, value);
                      setCustomers([...customers, value]);
                      enqueueSnackbar(`${value.companyName} created successfully!`, {
                        variant: 'success'
                      });
                      setAddEditCustomerDialogOpen(false);
                      setJobContractDetailIndex(-1);
                    })
                    .finally(() => {
                      setIsCreatingCustomer(false);
                    });
                }}
                open={addEditCustomerDialogOpen}
              />

              <AddCustomerAddressDialog
                initialValues={customerAddress}
                isLoading={isCreatingCustomerAddress}
                onClose={() => {
                  setAddCustomerAddressDialogOpen(false);
                  setFieldValue(`jobContractDetail[${jobContractDetailIndex}].customerAddress`, null);
                }}
                onSave={values2 => {
                  setIsCreatingCustomerAddress(true);
                  createCustomerAddress(values.jobContractDetail[jobContractDetailIndex].customer.id!, values2)
                    .then(({ value }) => {
                      setFieldValue(`jobContractDetail[${jobContractDetailIndex}].customerAddress`, value);
                      setFieldValue(`jobContractDetail[${jobContractDetailIndex}].customer`, {
                        ...values.jobContractDetail[jobContractDetailIndex].customer,
                        customerAddresses: [
                          ...values.jobContractDetail[jobContractDetailIndex].customer.customerAddresses,
                          value
                        ]
                      });
                      setCustomers(
                        customers.map(customer =>
                          customer.id === values.jobContractDetail[jobContractDetailIndex].customer.id
                            ? { ...customer, customerAddresses: [...customer.customerAddresses, value] }
                            : customer
                        )
                      );
                      enqueueSnackbar(
                        `${value.address1}, ${value.address2 ? value.address2 + ',' : ''} ${value.city}, ${
                          value.stateProvince
                        } ${value.postalCode} ${value.country} created successfully!`,
                        {
                          variant: 'success'
                        }
                      );
                      setAddCustomerAddressDialogOpen(false);
                      setJobContractDetailIndex(-1);
                    })
                    .finally(() => {
                      setIsCreatingCustomerAddress(false);
                    });
                }}
                open={addCustomerAddressDialogOpen}
              />

              <Card>
                <CardContent>
                  <Grid container spacing={2} alignItems='center'>
                    <Grid item xs={12}>
                      <Typography fontWeight='bold'>JOB NUMBER</Typography>
                    </Grid>

                    <Grid item xs={12} sm={2}>
                      <TextField
                        error={Boolean(touched.jobNumber && errors.jobNumber)}
                        fullWidth
                        helperText={touched.jobNumber && errors.jobNumber}
                        label='Job #'
                        name='jobNumber'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        size='small'
                        value={values.jobNumber}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>

                    {
                      // #region Contracts
                    }
                    <Grid item xs={12}>
                      <Typography fontWeight='bold'>CONTRACT DETAIL</Typography>
                    </Grid>

                    <Grid item xs={12} container spacing={4}>
                      {values.jobContractDetail.map((_, index) => (
                        <Grid item xs={12} container spacing={1} key={index}>
                          <Grid item xs={12} sm={4}>
                            <Typography variant='body2' sx={{ pl: 2 }}>
                              Contract #
                            </Typography>

                            <Typography variant='body1' sx={{ pl: 2 }}>
                              {values.jobContractDetail[index].contractNumber}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={4}>
                            <Autocomplete
                              autoHighlight
                              autoSelect
                              clearOnBlur
                              disableListWrap
                              filterOptions={(options, params) => {
                                const filtered = customerFilter(options, params);

                                const { inputValue } = params;

                                const isExisting = options.some(option => inputValue === option.companyName);

                                if (inputValue !== '' && !isExisting) {
                                  filtered.push({
                                    id: -1,
                                    companyName: `Add "${inputValue}"`,
                                    companyNumber: '',
                                    customerAddresses: []
                                  });
                                }

                                return filtered;
                              }}
                              getOptionLabel={option => {
                                if (typeof option === 'string') {
                                  return option;
                                }

                                return option.companyName;
                              }}
                              handleHomeEndKeys
                              noOptionsText='Type to search . . . '
                              onBlur={handleBlur}
                              onChange={(_, newValue) => {
                                if (typeof newValue === 'string') {
                                  // Do nothing . . .
                                } else if (newValue && newValue.id === -1) {
                                  setCustomer({
                                    ...customerInit,
                                    ...newValue,

                                    // Remove 'Add "..."'.
                                    companyName: newValue.companyName.substring(5, newValue.companyName.length - 1)
                                  });
                                  setJobContractDetailIndex(index);
                                  setAddEditCustomerDialogOpen(true);
                                } else {
                                  setFieldValue(`jobContractDetail[${index}].customer`, newValue);
                                }
                              }}
                              onInputChange={(_, value: string, reason) => {
                                if (reason !== 'reset') {
                                  getCustomersAutocomplete(value);
                                  setFieldValue(`jobContractDetail[${index}].customerAddress`, null);
                                }
                              }}
                              options={customers.sort((a, b) => a.companyName.localeCompare(b.companyName))}
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  error={Boolean(
                                    getIn(touched, `jobContractDetail[${index}].customer.id`) &&
                                      getIn(errors, `jobContractDetail[${index}].customer.id`)
                                  )}
                                  fullWidth
                                  helperText={
                                    getIn(touched, `jobContractDetail[${index}].customer.id`) &&
                                    getIn(errors, `jobContractDetail[${index}].customer.id`)
                                  }
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <>
                                        {isGettingCustomers && (
                                          <InputAdornment position='end'>
                                            <CircularProgress size={16} />
                                          </InputAdornment>
                                        )}

                                        {params.InputProps.endAdornment}
                                      </>
                                    )
                                  }}
                                  label='Customer'
                                  name={`jobContractDetail[${index}].customer`}
                                  size='small'
                                />
                              )}
                              selectOnFocus
                              value={values.jobContractDetail[index].customer ?? null}
                            />
                          </Grid>

                          <Grid item xs={12} sm={4}>
                            <Autocomplete
                              autoHighlight
                              autoSelect
                              clearOnBlur
                              disabled={!values.jobContractDetail[index].customer?.id} // Disable if row doesn't have a Customer selected.
                              disableListWrap
                              filterOptions={(options, params) => {
                                const filtered = customerAddressFilter(options, params);

                                const { inputValue } = params;

                                const isExisting = options.some(option =>
                                  `${option.address1}, ${option.address2 ? option.address2 + ',' : ''} ${option.city}, ${
                                    option.stateProvince
                                  } ${option.postalCode} ${option.country}`.includes(inputValue)
                                );

                                if (inputValue !== '' && !isExisting) {
                                  filtered.push({
                                    id: -1,
                                    billingContact: '',
                                    address1: `Add "${inputValue}"`,
                                    city: '',
                                    stateProvince: '',
                                    postalCode: '',
                                    country: ''
                                  });
                                }

                                return filtered;
                              }}
                              // freeSolo
                              getOptionLabel={option => {
                                if (typeof option === 'string') {
                                  return option;
                                }

                                // Don't display 'Add "{userInput}", ,'
                                if (option.id === -1) {
                                  return option.address1;
                                }

                                return `${option.address1}, ${option.address2 ? option.address2 + ',' : ''} ${option.city}, ${
                                  option.stateProvince
                                } ${option.postalCode} ${option.country}`;
                              }}
                              handleHomeEndKeys
                              onBlur={handleBlur}
                              onChange={(_, newValue) => {
                                if (typeof newValue === 'string') {
                                  // Do nothing . . .
                                } else if (newValue && newValue.id === -1) {
                                  setCustomerAddress({
                                    ...customerAddressInit,
                                    ...newValue,
                                    // Remove 'Add "..."'.
                                    address1: newValue.address1.substring(5, newValue.address1.length - 1)
                                  });
                                  setJobContractDetailIndex(index);
                                  setAddCustomerAddressDialogOpen(true);
                                } else {
                                  setFieldValue(`jobContractDetail[${index}].customerAddress`, newValue);
                                }
                              }}
                              options={
                                values.jobContractDetail[index].customer
                                  ? values.jobContractDetail[index].customer.customerAddresses
                                  : []
                              }
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  error={Boolean(
                                    getIn(touched, `jobContractDetail[${index}].customerAddress.id`) &&
                                      getIn(errors, `jobContractDetail[${index}].customerAddress.id`)
                                  )}
                                  fullWidth
                                  helperText={
                                    getIn(touched, `jobContractDetail[${index}].customerAddress.id`) &&
                                    getIn(errors, `jobContractDetail[${index}].customerAddress.id`)
                                  }
                                  label='Customer Address'
                                  name={`jobContractDetail[${index}].customerAddress`}
                                  size='small'
                                />
                              )}
                              selectOnFocus
                              value={
                                values.jobContractDetail[index].customerAddress?.id
                                  ? values.jobContractDetail[index].customerAddress
                                  : null
                              }
                            />
                          </Grid>

                          <Grid item xs={12} sm={4}>
                            <TextField
                              error={Boolean(
                                getIn(touched, `jobContractDetail[${index}].description`) &&
                                  getIn(errors, `jobContractDetail[${index}].description`)
                              )}
                              fullWidth
                              helperText={
                                getIn(touched, `jobContractDetail[${index}].description`) &&
                                getIn(errors, `jobContractDetail[${index}].description`)
                              }
                              label='Job Description'
                              name={`jobContractDetail[${index}].description`}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              size='small'
                              value={values.jobContractDetail[index].description || ''}
                            />
                          </Grid>

                          <Grid item xs={12} sm={4}>
                            <TextField
                              error={Boolean(
                                getIn(touched, `jobContractDetail[${index}].poNumber`) &&
                                  getIn(errors, `jobContractDetail[${index}].poNumber`)
                              )}
                              fullWidth
                              helperText={
                                getIn(touched, `jobContractDetail[${index}].poNumber`) &&
                                getIn(errors, `jobContractDetail[${index}].poNumber`)
                              }
                              label='PO #'
                              name={`jobContractDetail[${index}].poNumber`}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              size='small'
                              value={values.jobContractDetail[index].poNumber || ''}
                            />
                          </Grid>

                          {index > 0 && (
                            <Grid item xs={12} sm='auto'>
                              <Button
                                color='inherit'
                                onClick={() => {
                                  setFieldValue(
                                    'jobContractDetail',
                                    values.jobContractDetail.filter((__, i) => i !== index)
                                  );
                                }}
                                startIcon={<Clear />}
                                variant='contained'
                              >
                                DELETE
                              </Button>
                            </Grid>
                          )}
                        </Grid>
                      ))}
                    </Grid>

                    <Grid item xs={12}>
                      <Button
                        color='inherit'
                        onClick={() => {
                          setFieldValue('jobContractDetail', [
                            ...values.jobContractDetail,
                            { ...jobContractDetailInit, contractNumber: values.jobContractDetail.length }
                          ]);
                        }}
                        startIcon={<Add fontSize='small' />}
                        variant='contained'
                      >
                        ADD MORE CONTRACT DETAIL
                      </Button>
                    </Grid>

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    {
                      // #endregion
                    }

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>

                    {
                      // #region Labor Detail
                    }
                    <Grid item xs={12}>
                      <Typography fontWeight='bold'>LABOR DETAIL</Typography>
                    </Grid>

                    {values.jobLaborDetail.map((_, index) => (
                      <Grid item xs={12} container spacing={1}>
                        <Grid item xs={12} sm={3}>
                          <Autocomplete
                            autoHighlight
                            autoSelect
                            clearOnBlur
                            disabled={false}
                            disableListWrap
                            getOptionLabel={option => option.name}
                            handleHomeEndKeys
                            onBlur={handleBlur}
                            onChange={(_, newValue) => {
                              setFieldValue(`jobLaborDetail[${index}].laborClassificationId`, newValue?.id ?? null);
                            }}
                            options={laborClassifications.sort((a, b) => a.name.localeCompare(b.name))}
                            renderInput={params => (
                              <TextField
                                {...params}
                                error={Boolean(
                                  getIn(touched, `jobLaborDetail[${index}].laborClassificationId`) &&
                                    getIn(errors, `jobLaborDetail[${index}].laborClassificationId`)
                                )}
                                fullWidth
                                helperText={
                                  getIn(touched, `jobLaborDetail[${index}].laborClassificationId`) &&
                                  getIn(errors, `jobLaborDetail[${index}].laborClassificationId`)
                                }
                                label='Labor Classification'
                                name={`jobLaborDetail[${index}].laborClassificationId`}
                                size='small'
                              />
                            )}
                            selectOnFocus
                            value={
                              values.jobLaborDetail[index].laborClassificationId
                                ? laborClassifications.filter(
                                    laborClassification =>
                                      laborClassification.id === values.jobLaborDetail[index].laborClassificationId
                                  )[0]
                                : null
                            }
                          />
                        </Grid>

                        <Grid item xs={12} sm={2}>
                          <TextField
                            error={Boolean(
                              getIn(touched, `jobLaborDetail[${index}].straightTimeRate`) &&
                                getIn(errors, `jobLaborDetail[${index}].straightTimeRate`)
                            )}
                            fullWidth
                            helperText={
                              getIn(touched, `jobLaborDetail[${index}].straightTimeRate`) &&
                              getIn(errors, `jobLaborDetail[${index}].straightTimeRate`)
                            }
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ startAdornment: <InputAdornment position='start'>$</InputAdornment> }}
                            label='Straight Time'
                            name={`jobLaborDetail[${index}].straightTimeRate`}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            size='small'
                            type='number'
                            value={values.jobLaborDetail[index].straightTimeRate}
                          />
                        </Grid>

                        <Grid item xs={12} sm={2}>
                          <TextField
                            error={Boolean(
                              getIn(touched, `jobLaborDetail[${index}].timeAndAHalfRate`) &&
                                getIn(errors, `jobLaborDetail[${index}].timeAndAHalfRate`)
                            )}
                            fullWidth
                            helperText={
                              getIn(touched, `jobLaborDetail[${index}].timeAndAHalfRate`) &&
                              getIn(errors, `jobLaborDetail[${index}].timeAndAHalfRate`)
                            }
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ startAdornment: <InputAdornment position='start'>$</InputAdornment> }}
                            label='Time ½'
                            name={`jobLaborDetail[${index}].timeAndAHalfRate`}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            size='small'
                            type='number'
                            value={values.jobLaborDetail[index].timeAndAHalfRate}
                          />
                        </Grid>

                        <Grid item xs={12} sm={2}>
                          <TextField
                            error={Boolean(
                              getIn(touched, `jobLaborDetail[${index}].doubleTimeRate`) &&
                                getIn(errors, `jobLaborDetail[${index}].doubleTimeRate`)
                            )}
                            fullWidth
                            helperText={
                              getIn(touched, `jobLaborDetail[${index}].doubleTimeRate`) &&
                              getIn(errors, `jobLaborDetail[${index}].doubleTimeRate`)
                            }
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ startAdornment: <InputAdornment position='start'>$</InputAdornment> }}
                            label='Double Time'
                            name={`jobLaborDetail[${index}].doubleTimeRate`}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            size='small'
                            type='number'
                            value={values.jobLaborDetail[index].doubleTimeRate}
                          />
                        </Grid>

                        {index > 0 && (
                          <Grid item>
                            <Button
                              color='inherit'
                              onClick={() => {
                                setFieldValue(
                                  'jobLaborDetail',
                                  values.jobLaborDetail.filter((__, i) => i !== index)
                                );
                              }}
                              startIcon={<Clear />}
                              variant='contained'
                            >
                              DELETE
                            </Button>
                          </Grid>
                        )}
                      </Grid>
                    ))}

                    <Grid item xs={12}>
                      <Button
                        color='inherit'
                        onClick={() => {
                          setFieldValue('jobLaborDetail', [...values.jobLaborDetail, jobLaborDetailInit]);
                        }}
                        startIcon={<Add />}
                        variant='contained'
                      >
                        ADD MORE LABOR DETAIL
                      </Button>
                    </Grid>

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    {
                      // #endregion
                    }

                    {
                      // #region Equipment Detail
                    }
                    <Grid item xs={12}>
                      <Typography fontWeight='bold'>EQUIPMENT DETAIL</Typography>
                    </Grid>

                    {values.jobEquipmentDetail.map((_, index) => (
                      <Grid item xs={12} container spacing={1}>
                        <Grid item xs={12} sm={3}>
                          <Autocomplete
                            autoHighlight
                            // autoSelect
                            // clearOnBlur
                            disabled={false}
                            disableListWrap
                            freeSolo
                            handleHomeEndKeys
                            onBlur={handleBlur}
                            onChange={(_, value, __) => {
                              setFieldValue(`jobEquipmentDetail[${index}].description`, value ?? null);
                            }}
                            onInputChange={(_, value, __) => {
                              setFieldValue(`jobEquipmentDetail[${index}].description`, value);
                            }}
                            options={equipment.map(equipment => equipment.name).sort((a, b) => a.localeCompare(b))}
                            renderInput={params => (
                              <TextField
                                {...params}
                                error={Boolean(
                                  getIn(touched, `jobEquipmentDetail[${index}].description`) &&
                                    getIn(errors, `jobEquipmentDetail[${index}].description`)
                                )}
                                fullWidth
                                helperText={
                                  getIn(touched, `jobEquipmentDetail[${index}].description`) &&
                                  getIn(errors, `jobEquipmentDetail[${index}].description`)
                                }
                                label='Description'
                                name={`jobEquipmentDetail[${index}].description`}
                                size='small'
                              />
                            )}
                            selectOnFocus
                            value={values.jobEquipmentDetail[index].description}
                          />
                        </Grid>

                        <Grid item xs={12} sm={2}>
                          <Autocomplete
                            autoHighlight
                            autoSelect
                            clearOnBlur
                            disabled={false}
                            disableListWrap
                            handleHomeEndKeys
                            onBlur={handleBlur}
                            onChange={(_, value, __) => {
                              setFieldValue(`jobEquipmentDetail[${index}].um`, value ?? null);
                            }}
                            options={['Hour', 'Day', 'Month']}
                            renderInput={params => (
                              <TextField
                                {...params}
                                error={Boolean(
                                  getIn(touched, `jobEquipmentDetail[${index}].um`) &&
                                    getIn(errors, `jobEquipmentDetail[${index}].um`)
                                )}
                                fullWidth
                                helperText={
                                  getIn(touched, `jobEquipmentDetail[${index}].um`) &&
                                  getIn(errors, `jobEquipmentDetail[${index}].um`)
                                }
                                label='U/M'
                                name={`jobEquipmentDetail[${index}].um`}
                                size='small'
                              />
                            )}
                            selectOnFocus
                            value={values.jobEquipmentDetail[index].um}
                          />
                        </Grid>

                        <Grid item xs={12} sm={2}>
                          <TextField
                            error={Boolean(
                              getIn(touched, `jobEquipmentDetail[${index}].rate`) &&
                                getIn(errors, `jobEquipmentDetail[${index}].rate`)
                            )}
                            fullWidth
                            helperText={
                              getIn(touched, `jobEquipmentDetail[${index}].rate`) &&
                              getIn(errors, `jobEquipmentDetail[${index}].rate`)
                            }
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ startAdornment: <InputAdornment position='start'>$</InputAdornment> }}
                            label='Rate'
                            name={`jobEquipmentDetail[${index}].rate`}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            size='small'
                            type='number'
                            value={values.jobEquipmentDetail[index].rate}
                          />
                        </Grid>

                        {/* {index > 0 && ( */}
                        <Grid item>
                          <Button
                            color='inherit'
                            onClick={() => {
                              setFieldValue(
                                'jobEquipmentDetail',
                                values.jobEquipmentDetail.filter((__, i) => i !== index)
                              );
                            }}
                            startIcon={<Clear />}
                            variant='contained'
                          >
                            DELETE
                          </Button>
                        </Grid>
                        {/* )} */}
                      </Grid>
                    ))}

                    <Grid item xs={12}>
                      <Button
                        color='inherit'
                        onClick={() => {
                          setFieldValue('jobEquipmentDetail', [...values.jobEquipmentDetail, jobEquipmentDetailInit]);
                        }}
                        startIcon={<Add />}
                        variant='contained'
                      >
                        ADD MORE EQUIPMENT DETAIL
                      </Button>
                    </Grid>

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    {
                      // #endregion
                    }

                    {
                      // #region Markup Detail
                    }
                    <Grid item xs={12}>
                      <Typography fontWeight='bold'>MARKUP DETAIL</Typography>
                    </Grid>

                    <Grid item xs={12} sm={2}>
                      <TextField
                        error={Boolean(touched.laborMarkupPercentage && errors.laborMarkupPercentage)}
                        fullWidth
                        helperText={touched.laborMarkupPercentage && errors.laborMarkupPercentage}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ endAdornment: <InputAdornment position='end'>%</InputAdornment> }}
                        label='Labor Markup'
                        name='laborMarkupPercentage'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        size='small'
                        type='number'
                        value={values.laborMarkupPercentage}
                      />
                    </Grid>

                    <Grid item xs={12} sm={2}>
                      <TextField
                        error={Boolean(touched.materialMarkupPercentage && errors.materialMarkupPercentage)}
                        fullWidth
                        helperText={touched.materialMarkupPercentage && errors.materialMarkupPercentage}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ endAdornment: <InputAdornment position='end'>%</InputAdornment> }}
                        label='Material Markup'
                        name='materialMarkupPercentage'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        size='small'
                        type='number'
                        value={values.materialMarkupPercentage}
                      />
                    </Grid>

                    <Grid item xs={12} sm={2}>
                      <TextField
                        error={Boolean(touched.equipmentMarkupPercentage && errors.equipmentMarkupPercentage)}
                        fullWidth
                        helperText={touched.equipmentMarkupPercentage && errors.equipmentMarkupPercentage}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ endAdornment: <InputAdornment position='end'>%</InputAdornment> }}
                        label='Equipment Markup'
                        name='equipmentMarkupPercentage'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        size='small'
                        type='number'
                        value={values.equipmentMarkupPercentage}
                      />
                    </Grid>

                    <Grid item xs={12} sm={2}>
                      <TextField
                        error={Boolean(touched.subcontractsMarkupPercentage && errors.subcontractsMarkupPercentage)}
                        fullWidth
                        helperText={touched.subcontractsMarkupPercentage && errors.subcontractsMarkupPercentage}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ endAdornment: <InputAdornment position='end'>%</InputAdornment> }}
                        label='Subcontracts Markup'
                        name='subcontractsMarkupPercentage'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        size='small'
                        type='number'
                        value={values.subcontractsMarkupPercentage}
                      />
                    </Grid>

                    <Grid item xs={4} />

                    <Grid item xs={12} sm={2}>
                      <TextField
                        error={Boolean(touched.laborOverheadProfitPercentage && errors.laborOverheadProfitPercentage)}
                        fullWidth
                        helperText={touched.laborOverheadProfitPercentage && errors.laborOverheadProfitPercentage}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ endAdornment: <InputAdornment position='end'>%</InputAdornment> }}
                        label='Labor Overhead & Profit'
                        name='laborOverheadProfitPercentage'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        size='small'
                        type='number'
                        value={values.laborOverheadProfitPercentage}
                      />
                    </Grid>

                    <Grid item xs={12} sm={2}>
                      <TextField
                        error={Boolean(touched.materialOverheadProfitPercentage && errors.materialOverheadProfitPercentage)}
                        fullWidth
                        helperText={touched.materialOverheadProfitPercentage && errors.materialOverheadProfitPercentage}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ endAdornment: <InputAdornment position='end'>%</InputAdornment> }}
                        label='Material Overhead & Profit'
                        name='materialOverheadProfitPercentage'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        size='small'
                        type='number'
                        value={values.materialOverheadProfitPercentage}
                      />
                    </Grid>

                    <Grid item xs={12} sm={2}>
                      <TextField
                        error={Boolean(touched.equipmentOverheadProfitPercentage && errors.equipmentOverheadProfitPercentage)}
                        fullWidth
                        helperText={touched.equipmentOverheadProfitPercentage && errors.equipmentOverheadProfitPercentage}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ endAdornment: <InputAdornment position='end'>%</InputAdornment> }}
                        label='Equipment Overhead & Profit'
                        name='equipmentOverheadProfitPercentage'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        size='small'
                        type='number'
                        value={values.equipmentOverheadProfitPercentage}
                      />
                    </Grid>

                    <Grid item xs={12} sm={2}>
                      <TextField
                        error={Boolean(
                          touched.subcontractsOverheadProfitPercentage && errors.subcontractsOverheadProfitPercentage
                        )}
                        fullWidth
                        helperText={touched.subcontractsOverheadProfitPercentage && errors.subcontractsOverheadProfitPercentage}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ endAdornment: <InputAdornment position='end'>%</InputAdornment> }}
                        label='Subcontracts Overhead & Profit'
                        name='subcontractsOverheadProfitPercentage'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        size='small'
                        type='number'
                        value={values.subcontractsOverheadProfitPercentage}
                      />
                    </Grid>

                    <Grid item xs={4}></Grid>

                    <Grid item xs={12} sm={2} container justifyContent='center' alignItems='center'>
                      <Grid item>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.isTaxable}
                              name='isTaxable'
                              onBlur={handleBlur}
                              onChange={e => {
                                setFieldValue('isTaxable', e.target.checked);

                                if (e.target.checked) {
                                  setFieldValue('materialTaxPercentage', 6);
                                } else {
                                  setFieldValue('materialTaxPercentage', undefined);
                                }
                              }}
                              value={values.isTaxable}
                            />
                          }
                          label='Taxable?'
                        />
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={2}>
                      <TextField
                        disabled={!values.isTaxable}
                        error={Boolean(touched.materialTaxPercentage && errors.materialTaxPercentage)}
                        fullWidth
                        helperText={touched.materialTaxPercentage && errors.materialTaxPercentage}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ endAdornment: <InputAdornment position='end'>%</InputAdornment> }}
                        label='Material Tax'
                        name='materialTaxPercentage'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        size='small'
                        type='number'
                        value={values.materialTaxPercentage || ''}
                      />
                    </Grid>
                    {
                      // #endregion
                    }
                  </Grid>
                </CardContent>
              </Card>

              <BottomFormBar
                isEditable
                onCancel={() => {
                  navigate(-1);
                }}
                onSave={() => {
                  handleSubmit();
                }}
                save
              />

              <UnsavedChangesPrompt when={dirty} />
            </form>
          );
        }}
      </Formik>
    </Page>
  );
}
