import * as yup from 'yup';

export interface CustomerAddressDto {
  id?: number;
  billingContact: string;
  address1: string;
  address2?: string;
  city: string;
  stateProvince: string;
  postalCode: string;
  country: string;
  businessPhoneNumber?: string;
  businessFax?: string;
  emailAddress?: string;
  website?: string;
}

export const customerAddressInit: CustomerAddressDto = {
  id: undefined,
  billingContact: '',
  address1: '',
  address2: undefined,
  city: '',
  stateProvince: '',
  postalCode: '',
  country: '',
  businessPhoneNumber: undefined,
  businessFax: undefined,
  emailAddress: undefined,
  website: undefined
};

export const customerAddressValidationSchema = yup.object<CustomerAddressDto>({
  billingContact: yup
    .string()
    .max(200, 'Billing Contact cannot be more than 200 characters.')
    .required('Billing Contact is required.'),
  address1: yup.string().max(200, 'Address 1 cannot be more than 200 characters.').required('Address 1 is required.'),
  address2: yup.string().max(200, 'Address 2 cannot be more than 200 characters.').nullable(),
  city: yup.string().max(200, 'City cannot be more than 200 characters.').required('City is required.'),
  stateProvince: yup
    .string()
    .max(200, 'State/Province cannot be more than 200 characters.')
    .required('State/Province is required.'),
  postalCode: yup.string().max(50, 'Postal Code cannot be more than 50 characters.').required('Postal Code is required.'),
  country: yup.string().max(200, 'Country cannot be more than 200 characters.').required('Country is required.'),
  businessPhoneNumber: yup.string().max(50, 'Business Phone Number cannot be more than 50 characters.').nullable(),
  businessFax: yup.string().max(50, 'Business Fax cannot be more than 50 characters.').nullable(),
  emailAddress: yup
    .string()
    .email('Email Address is invalid.')
    .max(320, 'Email Address cannot be more than 320 characters.')
    .nullable(),
  website: yup.string().max(2_083, 'Website cannot be more than 2,083 characters.').nullable()
});
