import { createTheme, Theme } from '@mui/material/styles';

const theme: Theme = {
  ...createTheme({
    palette: {
      primary: {
        light: '#4dabf5',
        main: '#2196f3',
        dark: '#1769aa'
      },
      secondary: {
        light: '#f73378',
        main: '#f50057',
        dark: '#ab003c'
      }
    }
  })
};

const MCE_GREY = '#444549';

const MCE_RED = '#ee2d24';
const QA_BLUE = '#0081ff';

export default theme;

export { MCE_GREY, MCE_RED, QA_BLUE };
