import * as icons from '@mui/icons-material';
import {
  ListItem,
  ListItemIcon as MuiListItemIcon,
  ListItemText as MuiListItemText,
  SvgIcon
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { NavLink } from 'react-router-dom';

const ListItemText = styled(MuiListItemText)({
  fontSize: '18px',
  whiteSpace: 'nowrap'
});

const ListItemIcon = styled(MuiListItemIcon)({
  color: '#fff',
  marginLeft: '12px'
});

interface Props {
  /**
   * If greater than 0, the menu item will display the `badgeCount`.
   */
  badgeCount?: number;
  /**
   * The menu item's destination route.
   */
  to: string;
  /**
   * The name of the Material-UI icon.
   *
   * i.e. `"Edit"`.
   *
   * **NOTE:** Case sensitive.
   *
   * [List of icon names](https://material-ui.com/components/material-icons/)
   */
  icon: string;
}

const MenuItem = ({ badgeCount, children, to, icon }: React.PropsWithChildren<Props>) => {
  return (
    <ListItem button component={NavLink} to={to}>
      <ListItemIcon>
        {/* @ts-ignore */}
        <SvgIcon component={icons[icon]} />
      </ListItemIcon>
      <ListItemText
        primary={children}
        disableTypography={false}
        primaryTypographyProps={{
          fontSize: 15
        }}
      />
    </ListItem>
  );
};

export default MenuItem;
