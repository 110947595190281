import { JobDto, jobInit } from './job';
import * as yup from 'yup';
import { JobContractDetailDto, jobContractDetailInit, jobContractDetailValidationSchema } from './jobContractDetail';

export enum InvoiceStatuses {
  PreProcessed = 1,
  Invoiced
}

export interface InvoicesParameters {
  filterText?: string;
  page?: number;
  pageSize?: number;
  sortBy?: string;
  sortDirection?: 'asc' | 'desc';
}

export const invoicesParametersInit: InvoicesParameters = {
  filterText: '',
  page: 0,
  pageSize: 10,
  sortBy: 'invoiceNumber',
  sortDirection: 'desc'
};

export interface InvoiceDto {
  id?: number;
  job: JobDto;
  jobContractDetail: JobContractDetailDto;
  invoiceNumber: string;
  poNumber: string;
  invoiceDate: string;
  datePerformed: string;
  line1?: string;
  line2?: string;
  line3?: string;
  line4?: string;
  line5?: string;
  formIds?: number[];
  isFinalized?: boolean;
}

export const invoiceInit: InvoiceDto = {
  id: undefined,
  job: jobInit,
  jobContractDetail: jobContractDetailInit,
  invoiceNumber: '',
  poNumber: '',
  invoiceDate: '',
  datePerformed: '',
  line1: 'AS NEEDED ELECTRICAL AND ENGINEERING SUPPORT',
  line2: 'SEE ATTACHED INVOICE SUMMARY AND FIELD REPORT',
  line3: undefined,
  line4: undefined,
  line5: undefined,
  formIds: [],
  isFinalized: false
};

export const invoiceValidationSchema = yup.object<InvoiceDto>({
  job: yup.object({ id: yup.number().min(1).required('Job # is required.') }),
  jobContractDetail: yup.object({ id: yup.number().min(1).required('Contract # is required.') }),
  invoiceNumber: yup.string().max(50, 'Invoice # cannot be more than 50 characters.').required('Invoice # is required.'),
  poNumber: yup.string().max(200, 'PO # cannot be more than 200 characters.').required('PO # is required.'),
  invoiceDate: yup.string().required('Invoice Date is required.'),
  datePerformed: yup.string().required('Date Performed is required.'),
  line1: yup.string().max(200, 'Line 1 cannot be more than 200 characters.').nullable(),
  line2: yup.string().max(200, 'Line 2 cannot be more than 200 characters.').nullable(),
  line3: yup.string().max(200, 'Line 3 cannot be more than 200 characters.').nullable(),
  line4: yup.string().max(200, 'Line 4 cannot be more than 200 characters.').nullable(),
  line5: yup.string().max(200, 'Line 5 cannot be more than 200 characters.').nullable()
});
