import { api } from 'app/api';
import { ApiResponse, InvoiceDto, InvoicesParameters } from 'dtos';
import { URIQuery, base64ToArrayBuffer } from 'utils';

export const getInvoices = async (parameters: InvoicesParameters): Promise<ApiResponse<InvoiceDto[]>> =>
  await api.get<ApiResponse<InvoiceDto[]>>('invoices' + URIQuery({ ...parameters })).then(res => res.data);

export const getInvoiceByInvoiceNumber = async (invoiceNumber: string): Promise<ApiResponse<InvoiceDto>> =>
  await api.get<ApiResponse<InvoiceDto>>('invoices/' + invoiceNumber).then(res => res.data);

export const createInvoice = async (values: InvoiceDto): Promise<ApiResponse<InvoiceDto>> =>
  await api.post<ApiResponse<InvoiceDto>>('invoices', values).then(res => res.data);

export const updateInvoice = async (values: InvoiceDto): Promise<ApiResponse<InvoiceDto>> =>
  await api.put<ApiResponse<InvoiceDto>>('invoices', values).then(res => res.data);

export const deleteInvoice = async (invoiceNumber: string): Promise<null> =>
  await api.delete('invoices/' + encodeURIComponent(invoiceNumber)).then(res => res.data);

export const previewInvoice = async (invoiceNumber: string): Promise<void> => {
  let requestUrl = 'invoices/' + invoiceNumber + '/preview-invoice';

  await api.get(requestUrl).then(({ data }) => {
    const buffer = base64ToArrayBuffer(data);
    const file = new Blob([buffer], { type: 'application/pdf' });
    const fileUrl = window.URL.createObjectURL(file);
    const _newTab = window.open(fileUrl);
  });
};

export const previewReportSummary = async (invoiceNumber: string): Promise<void> => {
  let requestUrl = 'invoices/' + invoiceNumber + '/preview-report-summary';

  await api.get(requestUrl).then(({ data }) => {
    const buffer = base64ToArrayBuffer(data);
    const file = new Blob([buffer], { type: 'application/pdf' });
    const fileUrl = window.URL.createObjectURL(file);
    const _newTab = window.open(fileUrl);
  });
};

export const previewFieldReports = async (invoiceNumber: string) => {
  let requestUrl = 'invoices/' + encodeURIComponent(invoiceNumber) + '/preview-field-reports';

  await api.get(requestUrl).then(({ data }) => {
    const buffer = base64ToArrayBuffer(data);
    const file = new Blob([buffer], { type: 'application/pdf' });
    const fileUrl = window.URL.createObjectURL(file);
    const _newTab = window.open(fileUrl);
  });
};
