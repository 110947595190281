import { api } from 'app/api';
import { EquipmentDto, EquipmentParameters } from 'dtos';
import { URIQuery } from 'utils';
import { ApiResponse } from 'dtos';

export const getEquipment = async (parameters: EquipmentParameters): Promise<ApiResponse<EquipmentDto[]>> =>
  await api.get('equipment' + URIQuery({ ...parameters })).then(res => res.data);

export const createEquipment = async (equipmentDto: EquipmentDto) =>
  await api.post('equipment', equipmentDto).then(res => res.data);

export const updateEquipment = async (equipmentDto: EquipmentDto) =>
  await api.put('equipment', equipmentDto).then(res => res.data);
